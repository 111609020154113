<template>
    <p class="text-xl">{{ content }}</p>
</template>


<script>
    export default {
        name: "ModalWindowLabel",
        props: {
            content: {
                type: String,
                required: true,
            }
        }
    }
</script>