<template>
    <ConfirmationModalWindow v-if="deleteConfirmationWindowIsOpened" @confirm="deleteTransaction" v-model:isOpened="deleteConfirmationWindowIsOpened" title="Вы уверены, что хотите удалить эту транзакцию?" :buttonIsDisabled="buttonIsDisabled"/>
    
    <div v-if="currentTransaction.isTransfer">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[40vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeTransactionDetailWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Детали перевода</p>
                    <div class="grid grid-cols-4 gap-4 mb-8">
                        <div class="relative col-span-2">
                            <span v-if="currentTransaction.total < 0" class="mb-1 block text-sm text-gray-500">Перевод в кассу</span>
                            <span v-else class="mb-1 block text-sm text-gray-500">Перевод из кассы</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ currentTransaction.transferTo.name }}</span>  
                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Кассир</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ currentTransaction.cashier.name }}</span>  
                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Метод</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ labels.transactionMethods[currentTransaction.payment] }}</span>
                            </p>
                        </div>
                        <div class="col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Сумма</span>
                            <p class="w-full relative text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ $globalMethods.formatNumberWithSpaces(Math.abs(currentTransaction.total)) }}</span>
                                <svg v-if="currentTransaction.currency == 'RUB'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M274.5-111.87v-119.04h-80v-91h80v-75.22h-80v-91h80v-360H540q94.15 0 159.83 65.67 65.67 65.68 65.67 159.83T699.83-462.8Q634.15-397.13 540-397.13H365.5v75.22h160v91h-160v119.04h-91Zm91-376.26H540q55.85 0 95.17-39.33 39.33-39.32 39.33-95.17t-39.33-95.17q-39.32-39.33-95.17-39.33H365.5v269Z"/></svg>
                                <svg v-else-if="currentTransaction.currency == 'USD'" class="w-5 h-5 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M439.57-116.41v-86.72q-54.92-12.48-93.9-47.79-38.97-35.32-56.74-99.23l80.94-32.15q14.76 48.95 43.9 73.47 29.14 24.53 75.71 24.53 40.28 0 67.23-17.43 26.94-17.42 26.94-54.27 0-33.33-22.24-53.71-22.24-20.38-101.76-45.66-85.04-26.52-117.52-65.1-32.48-38.57-32.48-93.53 0-64.04 41.4-101.24 41.41-37.19 88.52-43.63v-84.72h83.58v84.72q50.96 8.96 84.18 38.41 33.21 29.46 48.69 72.37l-80.45 34.39q-11.77-31.52-33.89-47.76-22.11-16.24-59.4-16.24-42.32 0-63.77 18.07-21.44 18.06-21.44 44.91 0 31.33 28.2 49.61 28.21 18.28 103.16 39.76 69.96 20.48 106.3 64.58 36.34 44.1 36.34 102.77 0 71.96-42.48 109.67-42.48 37.72-105.44 47.2v84.72h-83.58Z"/></svg>
                                <svg v-else-if="currentTransaction.currency == 'EUR'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M597.37-116.41q-119.44 0-211.91-67.84-92.48-67.84-131.44-173.84H117.37v-83.82h117.7q-2-10.29-2-19.17v-37.49q0-8.52 2-19.52h-117.7v-83.82h136.41q38.96-106.24 131.56-173.96 92.59-67.72 212.03-67.72 69.96 0 132.77 24.96 62.82 24.96 112.49 70.63l-74.78 75.02q-35.72-29.72-79.34-46.57-43.62-16.86-91.14-16.86-73.57 0-133.03 37.3-59.47 37.31-91.47 97.2h224.5v83.82H345.43q-2 10.29-2.76 19.05-.76 8.76-.76 19.04t.76 19.04q.76 8.76 2.76 19.05h251.94v83.82h-224.5q32 59.89 91.47 97.2 59.46 37.3 133.03 37.3 47.52 0 92.02-16.86 44.5-16.85 77.98-46.57L842.63-212q-49.67 45.67-112.49 70.63-62.81 24.96-132.77 24.96Z"/></svg>
                                <svg v-else-if="currentTransaction.currency == 'CNY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M434.5-111.87V-394.5h-200v-91h184.46L188.52-848.13h108.15L480-558.28l183.33-289.85h108.15L541.04-485.5H725.5v91h-200v282.63h-91Z"/></svg>
                                <svg v-else-if="currentTransaction.currency == 'TRY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M354.5-111.87v-202.89l-120 75v-108.15l120-75v-82.29l-120 75v-106.91l120-76v-235.02h91v178.33l160-100v106.91l-160 101v82.28l160-100v106.91l-160 101v168.11q78.93-2.15 133.97-60.05 55.03-57.9 55.03-139.23h91q0 121.54-83.05 206.27-83.06 84.73-202.45 84.73h-85.5Z"/></svg>
                                <span v-else-if="currentTransaction.currency == 'BYN'" class="absolute translate-y-[-50%] top-1/2 right-4">Br</span>
                                <span v-else-if="currentTransaction.currency == 'KZT'" class="absolute translate-y-[-50%] top-1/2 right-4">₸</span>
                                <span v-else-if="currentTransaction.currency == 'UZS'" class="absolute translate-y-[-50%] top-1/2 right-4">S</span>
                                <span v-else-if="currentTransaction.currency == 'AZN'" class="absolute translate-y-[-50%] top-1/2 right-4">₼</span>
                                <span v-else-if="currentTransaction.currency == 'GEL'" class="absolute translate-y-[-50%] top-1/2 right-4">₾</span>
                                <span v-else-if="currentTransaction.currency == 'THB'" class="absolute translate-y-[-50%] top-1/2 right-4">฿</span>
                            </p>
                        </div>
                    </div>
                    <div class="w-full mb-8">
                        <label for="add-sale-note" class="mb-1 block text-sm text-gray-500">Комментарий</label>
                        <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                            <span>{{ currentTransaction.note || "-" }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeTransactionDetailWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">
                        <span>Детали транзакции</span>
                        <span v-if="!currentTransaction.isActive" class="text-xl text-red-500 ml-4">(удалена)</span>
                    </p>
                    <div class="grid grid-cols-6 gap-4 mb-8">
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Кассир</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ currentTransaction.cashier.name }}</span>  
                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Метод</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ labels.transactionMethods[currentTransaction.payment] }}</span>

                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Скидка</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span>{{ currentTransaction.discount }}</span>
                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Имя клиента</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4 overflow-x-scroll no-scrollbar" type="button">
                                <span v-if="currentTransaction.client.name">{{ currentTransaction.client.name || "-" }}</span>  
                                <span v-else>-</span>  
                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Номер клиента</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                <span v-if="currentTransaction.client.name">{{ currentTransaction.client.phone || "-" }}</span>  
                                <span v-else>-</span>  
                            </p>
                        </div>
                        <div class="relative col-span-2">
                            <span class="mb-1 block text-sm text-gray-500">Email клиента</span>
                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4 overflow-x-scroll no-scrollbar" type="button">
                                <span v-if="currentTransaction.client.name">{{ currentTransaction.client.email || "-" }}</span>  
                                <span v-else>-</span>  
                            </p>
                        </div>
                    </div>
                    <div class="w-full mb-8">
                        <div class="relative mb-2">
                            <div class="flex items-center justify-between gap-4 flex-shrink">
                                <div class="relative w-[40%]">
                                    <label class="mb-1 block text-sm text-gray-500">Товар / Услуга</label>
                                </div>
                                <div class="relative w-32">
                                    <label class="mb-1 block text-sm text-gray-500">Количество</label>
                                </div>
                                <div class="relative w-40">
                                    <label class="mb-1 block text-sm text-gray-500">Цена</label>
                                </div>
                                <div class="relative w-40">
                                    <label class="mb-1 block text-sm text-gray-500">Сумма</label>
                                </div>
                            </div>
                            <div v-for="(operation, index) in currentTransaction.operations" :key="index" class="mb-4">
                                <div class="flex items-center justify-between gap-4 flex-shrink mb-2">
                                    <div class="relative w-[40%] add-sale-operation-selection-block">
                                        <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                            <span>{{ operation.good.name }}</span>
                                        </p>
                                    </div>
                                    <div class="relative w-32">
                                        <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                            <span>{{ operation.amount }}</span>
                                        </p>
                                    </div>
                                    <div class="relative w-40">
                                        <div class="relative">
                                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                                <span>{{ $globalMethods.formatNumberWithSpaces(operation.priceWithoutDiscount) }}</span>
                                            </p>
                                            <svg v-if="currentTransaction.currency == 'RUB'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M274.5-111.87v-119.04h-80v-91h80v-75.22h-80v-91h80v-360H540q94.15 0 159.83 65.67 65.67 65.68 65.67 159.83T699.83-462.8Q634.15-397.13 540-397.13H365.5v75.22h160v91h-160v119.04h-91Zm91-376.26H540q55.85 0 95.17-39.33 39.33-39.32 39.33-95.17t-39.33-95.17q-39.32-39.33-95.17-39.33H365.5v269Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'USD'" class="w-5 h-5 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M439.57-116.41v-86.72q-54.92-12.48-93.9-47.79-38.97-35.32-56.74-99.23l80.94-32.15q14.76 48.95 43.9 73.47 29.14 24.53 75.71 24.53 40.28 0 67.23-17.43 26.94-17.42 26.94-54.27 0-33.33-22.24-53.71-22.24-20.38-101.76-45.66-85.04-26.52-117.52-65.1-32.48-38.57-32.48-93.53 0-64.04 41.4-101.24 41.41-37.19 88.52-43.63v-84.72h83.58v84.72q50.96 8.96 84.18 38.41 33.21 29.46 48.69 72.37l-80.45 34.39q-11.77-31.52-33.89-47.76-22.11-16.24-59.4-16.24-42.32 0-63.77 18.07-21.44 18.06-21.44 44.91 0 31.33 28.2 49.61 28.21 18.28 103.16 39.76 69.96 20.48 106.3 64.58 36.34 44.1 36.34 102.77 0 71.96-42.48 109.67-42.48 37.72-105.44 47.2v84.72h-83.58Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'EUR'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M597.37-116.41q-119.44 0-211.91-67.84-92.48-67.84-131.44-173.84H117.37v-83.82h117.7q-2-10.29-2-19.17v-37.49q0-8.52 2-19.52h-117.7v-83.82h136.41q38.96-106.24 131.56-173.96 92.59-67.72 212.03-67.72 69.96 0 132.77 24.96 62.82 24.96 112.49 70.63l-74.78 75.02q-35.72-29.72-79.34-46.57-43.62-16.86-91.14-16.86-73.57 0-133.03 37.3-59.47 37.31-91.47 97.2h224.5v83.82H345.43q-2 10.29-2.76 19.05-.76 8.76-.76 19.04t.76 19.04q.76 8.76 2.76 19.05h251.94v83.82h-224.5q32 59.89 91.47 97.2 59.46 37.3 133.03 37.3 47.52 0 92.02-16.86 44.5-16.85 77.98-46.57L842.63-212q-49.67 45.67-112.49 70.63-62.81 24.96-132.77 24.96Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'CNY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M434.5-111.87V-394.5h-200v-91h184.46L188.52-848.13h108.15L480-558.28l183.33-289.85h108.15L541.04-485.5H725.5v91h-200v282.63h-91Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'TRY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M354.5-111.87v-202.89l-120 75v-108.15l120-75v-82.29l-120 75v-106.91l120-76v-235.02h91v178.33l160-100v106.91l-160 101v82.28l160-100v106.91l-160 101v168.11q78.93-2.15 133.97-60.05 55.03-57.9 55.03-139.23h91q0 121.54-83.05 206.27-83.06 84.73-202.45 84.73h-85.5Z"/></svg>
                                            <p v-else-if="currentTransaction.currency == 'BYN'" class="absolute translate-y-[-50%] top-1/2 right-4">Br</p>
                                            <p v-else-if="currentTransaction.currency == 'KZT'" class="absolute translate-y-[-50%] top-1/2 right-4">₸</p>
                                            <p v-else-if="currentTransaction.currency == 'UZS'" class="absolute translate-y-[-50%] top-1/2 right-4">S</p>
                                            <p v-else-if="currentTransaction.currency == 'AZN'" class="absolute translate-y-[-50%] top-1/2 right-4">₼</p>
                                            <p v-else-if="currentTransaction.currency == 'GEL'" class="absolute translate-y-[-50%] top-1/2 right-4">₾</p>
                                            <p v-else-if="currentTransaction.currency == 'THB'" class="absolute translate-y-[-50%] top-1/2 right-4">฿</p>
                                        </div>
                                    </div>
                                    <div class="relative w-40">
                                        <div class="relative">
                                            <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                                <span>{{ $globalMethods.formatNumberWithSpaces(operation.totalPrice) }}</span>
                                            </p>
                                            <svg v-if="currentTransaction.currency == 'RUB'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M274.5-111.87v-119.04h-80v-91h80v-75.22h-80v-91h80v-360H540q94.15 0 159.83 65.67 65.67 65.68 65.67 159.83T699.83-462.8Q634.15-397.13 540-397.13H365.5v75.22h160v91h-160v119.04h-91Zm91-376.26H540q55.85 0 95.17-39.33 39.33-39.32 39.33-95.17t-39.33-95.17q-39.32-39.33-95.17-39.33H365.5v269Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'USD'" class="w-5 h-5 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M439.57-116.41v-86.72q-54.92-12.48-93.9-47.79-38.97-35.32-56.74-99.23l80.94-32.15q14.76 48.95 43.9 73.47 29.14 24.53 75.71 24.53 40.28 0 67.23-17.43 26.94-17.42 26.94-54.27 0-33.33-22.24-53.71-22.24-20.38-101.76-45.66-85.04-26.52-117.52-65.1-32.48-38.57-32.48-93.53 0-64.04 41.4-101.24 41.41-37.19 88.52-43.63v-84.72h83.58v84.72q50.96 8.96 84.18 38.41 33.21 29.46 48.69 72.37l-80.45 34.39q-11.77-31.52-33.89-47.76-22.11-16.24-59.4-16.24-42.32 0-63.77 18.07-21.44 18.06-21.44 44.91 0 31.33 28.2 49.61 28.21 18.28 103.16 39.76 69.96 20.48 106.3 64.58 36.34 44.1 36.34 102.77 0 71.96-42.48 109.67-42.48 37.72-105.44 47.2v84.72h-83.58Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'EUR'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M597.37-116.41q-119.44 0-211.91-67.84-92.48-67.84-131.44-173.84H117.37v-83.82h117.7q-2-10.29-2-19.17v-37.49q0-8.52 2-19.52h-117.7v-83.82h136.41q38.96-106.24 131.56-173.96 92.59-67.72 212.03-67.72 69.96 0 132.77 24.96 62.82 24.96 112.49 70.63l-74.78 75.02q-35.72-29.72-79.34-46.57-43.62-16.86-91.14-16.86-73.57 0-133.03 37.3-59.47 37.31-91.47 97.2h224.5v83.82H345.43q-2 10.29-2.76 19.05-.76 8.76-.76 19.04t.76 19.04q.76 8.76 2.76 19.05h251.94v83.82h-224.5q32 59.89 91.47 97.2 59.46 37.3 133.03 37.3 47.52 0 92.02-16.86 44.5-16.85 77.98-46.57L842.63-212q-49.67 45.67-112.49 70.63-62.81 24.96-132.77 24.96Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'CNY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M434.5-111.87V-394.5h-200v-91h184.46L188.52-848.13h108.15L480-558.28l183.33-289.85h108.15L541.04-485.5H725.5v91h-200v282.63h-91Z"/></svg>
                                            <svg v-else-if="currentTransaction.currency == 'TRY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M354.5-111.87v-202.89l-120 75v-108.15l120-75v-82.29l-120 75v-106.91l120-76v-235.02h91v178.33l160-100v106.91l-160 101v82.28l160-100v106.91l-160 101v168.11q78.93-2.15 133.97-60.05 55.03-57.9 55.03-139.23h91q0 121.54-83.05 206.27-83.06 84.73-202.45 84.73h-85.5Z"/></svg>
                                            <p v-else-if="currentTransaction.currency == 'BYN'" class="absolute translate-y-[-50%] top-1/2 right-4">Br</p>
                                            <p v-else-if="currentTransaction.currency == 'KZT'" class="absolute translate-y-[-50%] top-1/2 right-4">₸</p>
                                            <p v-else-if="currentTransaction.currency == 'UZS'" class="absolute translate-y-[-50%] top-1/2 right-4">S</p>
                                            <p v-else-if="currentTransaction.currency == 'AZN'" class="absolute translate-y-[-50%] top-1/2 right-4">₼</p>
                                            <p v-else-if="currentTransaction.currency == 'GEL'" class="absolute translate-y-[-50%] top-1/2 right-4">₾</p>
                                            <p v-else-if="currentTransaction.currency == 'THB'" class="absolute translate-y-[-50%] top-1/2 right-4">฿</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mb-8">
                        <label for="add-sale-note" class="mb-1 block text-sm text-gray-500">Комментарий</label>
                        <p class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                            <span>{{ currentTransaction.note || "-" }}</span>
                        </p>
                    </div>
                    <div class="grid grid-cols-6 gap-4 mb-8">
                        <div class="relative col-span-2">
                            <div>
                                <span class="mb-1 block text-sm text-gray-500">Сумма скидки</span>
                                <div class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                    <div class="relative flex items-center w-full">
                                        <span v-if="currentTransaction.totalWithoutDiscount" class="mr-8">{{ $globalMethods.formatNumberWithSpaces(Number((currentTransaction.totalWithoutDiscount - currentTransaction.total).toFixed(2))) }}</span>
                                        <span v-else class="mr-8">-</span>
                                        <svg v-if="currentTransaction.currency == 'RUB'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M274.5-111.87v-119.04h-80v-91h80v-75.22h-80v-91h80v-360H540q94.15 0 159.83 65.67 65.67 65.68 65.67 159.83T699.83-462.8Q634.15-397.13 540-397.13H365.5v75.22h160v91h-160v119.04h-91Zm91-376.26H540q55.85 0 95.17-39.33 39.33-39.32 39.33-95.17t-39.33-95.17q-39.32-39.33-95.17-39.33H365.5v269Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'USD'" class="w-5 h-5 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M439.57-116.41v-86.72q-54.92-12.48-93.9-47.79-38.97-35.32-56.74-99.23l80.94-32.15q14.76 48.95 43.9 73.47 29.14 24.53 75.71 24.53 40.28 0 67.23-17.43 26.94-17.42 26.94-54.27 0-33.33-22.24-53.71-22.24-20.38-101.76-45.66-85.04-26.52-117.52-65.1-32.48-38.57-32.48-93.53 0-64.04 41.4-101.24 41.41-37.19 88.52-43.63v-84.72h83.58v84.72q50.96 8.96 84.18 38.41 33.21 29.46 48.69 72.37l-80.45 34.39q-11.77-31.52-33.89-47.76-22.11-16.24-59.4-16.24-42.32 0-63.77 18.07-21.44 18.06-21.44 44.91 0 31.33 28.2 49.61 28.21 18.28 103.16 39.76 69.96 20.48 106.3 64.58 36.34 44.1 36.34 102.77 0 71.96-42.48 109.67-42.48 37.72-105.44 47.2v84.72h-83.58Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'EUR'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M597.37-116.41q-119.44 0-211.91-67.84-92.48-67.84-131.44-173.84H117.37v-83.82h117.7q-2-10.29-2-19.17v-37.49q0-8.52 2-19.52h-117.7v-83.82h136.41q38.96-106.24 131.56-173.96 92.59-67.72 212.03-67.72 69.96 0 132.77 24.96 62.82 24.96 112.49 70.63l-74.78 75.02q-35.72-29.72-79.34-46.57-43.62-16.86-91.14-16.86-73.57 0-133.03 37.3-59.47 37.31-91.47 97.2h224.5v83.82H345.43q-2 10.29-2.76 19.05-.76 8.76-.76 19.04t.76 19.04q.76 8.76 2.76 19.05h251.94v83.82h-224.5q32 59.89 91.47 97.2 59.46 37.3 133.03 37.3 47.52 0 92.02-16.86 44.5-16.85 77.98-46.57L842.63-212q-49.67 45.67-112.49 70.63-62.81 24.96-132.77 24.96Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'CNY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M434.5-111.87V-394.5h-200v-91h184.46L188.52-848.13h108.15L480-558.28l183.33-289.85h108.15L541.04-485.5H725.5v91h-200v282.63h-91Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'TRY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M354.5-111.87v-202.89l-120 75v-108.15l120-75v-82.29l-120 75v-106.91l120-76v-235.02h91v178.33l160-100v106.91l-160 101v82.28l160-100v106.91l-160 101v168.11q78.93-2.15 133.97-60.05 55.03-57.9 55.03-139.23h91q0 121.54-83.05 206.27-83.06 84.73-202.45 84.73h-85.5Z"/></svg>
                                        <p v-else-if="currentTransaction.currency == 'BYN'" class="absolute translate-y-[-50%] top-1/2 right-4">Br</p>
                                        <p v-else-if="currentTransaction.currency == 'KZT'" class="absolute translate-y-[-50%] top-1/2 right-4">₸</p>
                                        <p v-else-if="currentTransaction.currency == 'UZS'" class="absolute translate-y-[-50%] top-1/2 right-4">S</p>
                                        <p v-else-if="currentTransaction.currency == 'AZN'" class="absolute translate-y-[-50%] top-1/2 right-4">₼</p>
                                        <p v-else-if="currentTransaction.currency == 'GEL'" class="absolute translate-y-[-50%] top-1/2 right-4">₾</p>
                                        <p v-else-if="currentTransaction.currency == 'THB'" class="absolute translate-y-[-50%] top-1/2 right-4">฿</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="relative col-span-2">
                            <div>
                                <span class="mb-1 block text-sm text-gray-500">Сумма без скидки</span>
                                <div class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                    <div class="relative flex items-center w-full">
                                        <span v-if="currentTransaction.totalWithoutDiscount" class="mr-8">{{ $globalMethods.formatNumberWithSpaces(currentTransaction.totalWithoutDiscount) }}</span>
                                        <span v-else class="mr-8">-</span>
                                        <svg v-if="currentTransaction.currency == 'RUB'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M274.5-111.87v-119.04h-80v-91h80v-75.22h-80v-91h80v-360H540q94.15 0 159.83 65.67 65.67 65.68 65.67 159.83T699.83-462.8Q634.15-397.13 540-397.13H365.5v75.22h160v91h-160v119.04h-91Zm91-376.26H540q55.85 0 95.17-39.33 39.33-39.32 39.33-95.17t-39.33-95.17q-39.32-39.33-95.17-39.33H365.5v269Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'USD'" class="w-5 h-5 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M439.57-116.41v-86.72q-54.92-12.48-93.9-47.79-38.97-35.32-56.74-99.23l80.94-32.15q14.76 48.95 43.9 73.47 29.14 24.53 75.71 24.53 40.28 0 67.23-17.43 26.94-17.42 26.94-54.27 0-33.33-22.24-53.71-22.24-20.38-101.76-45.66-85.04-26.52-117.52-65.1-32.48-38.57-32.48-93.53 0-64.04 41.4-101.24 41.41-37.19 88.52-43.63v-84.72h83.58v84.72q50.96 8.96 84.18 38.41 33.21 29.46 48.69 72.37l-80.45 34.39q-11.77-31.52-33.89-47.76-22.11-16.24-59.4-16.24-42.32 0-63.77 18.07-21.44 18.06-21.44 44.91 0 31.33 28.2 49.61 28.21 18.28 103.16 39.76 69.96 20.48 106.3 64.58 36.34 44.1 36.34 102.77 0 71.96-42.48 109.67-42.48 37.72-105.44 47.2v84.72h-83.58Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'EUR'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M597.37-116.41q-119.44 0-211.91-67.84-92.48-67.84-131.44-173.84H117.37v-83.82h117.7q-2-10.29-2-19.17v-37.49q0-8.52 2-19.52h-117.7v-83.82h136.41q38.96-106.24 131.56-173.96 92.59-67.72 212.03-67.72 69.96 0 132.77 24.96 62.82 24.96 112.49 70.63l-74.78 75.02q-35.72-29.72-79.34-46.57-43.62-16.86-91.14-16.86-73.57 0-133.03 37.3-59.47 37.31-91.47 97.2h224.5v83.82H345.43q-2 10.29-2.76 19.05-.76 8.76-.76 19.04t.76 19.04q.76 8.76 2.76 19.05h251.94v83.82h-224.5q32 59.89 91.47 97.2 59.46 37.3 133.03 37.3 47.52 0 92.02-16.86 44.5-16.85 77.98-46.57L842.63-212q-49.67 45.67-112.49 70.63-62.81 24.96-132.77 24.96Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'CNY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M434.5-111.87V-394.5h-200v-91h184.46L188.52-848.13h108.15L480-558.28l183.33-289.85h108.15L541.04-485.5H725.5v91h-200v282.63h-91Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'TRY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M354.5-111.87v-202.89l-120 75v-108.15l120-75v-82.29l-120 75v-106.91l120-76v-235.02h91v178.33l160-100v106.91l-160 101v82.28l160-100v106.91l-160 101v168.11q78.93-2.15 133.97-60.05 55.03-57.9 55.03-139.23h91q0 121.54-83.05 206.27-83.06 84.73-202.45 84.73h-85.5Z"/></svg>
                                        <p v-else-if="currentTransaction.currency == 'BYN'" class="absolute translate-y-[-50%] top-1/2 right-4">Br</p>
                                        <p v-else-if="currentTransaction.currency == 'KZT'" class="absolute translate-y-[-50%] top-1/2 right-4">₸</p>
                                        <p v-else-if="currentTransaction.currency == 'UZS'" class="absolute translate-y-[-50%] top-1/2 right-4">S</p>
                                        <p v-else-if="currentTransaction.currency == 'AZN'" class="absolute translate-y-[-50%] top-1/2 right-4">₼</p>
                                        <p v-else-if="currentTransaction.currency == 'GEL'" class="absolute translate-y-[-50%] top-1/2 right-4">₾</p>
                                        <p v-else-if="currentTransaction.currency == 'THB'" class="absolute translate-y-[-50%] top-1/2 right-4">฿</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="relative col-span-2">
                            <div>
                                <span class="mb-1 block text-sm text-gray-500">Итог</span>
                                <div class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                    <div class="relative flex items-center w-full">
                                        <span class="mr-8">{{ $globalMethods.formatNumberWithSpaces(currentTransaction.total) }}</span>
                                        <svg v-if="currentTransaction.currency == 'RUB'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M274.5-111.87v-119.04h-80v-91h80v-75.22h-80v-91h80v-360H540q94.15 0 159.83 65.67 65.67 65.68 65.67 159.83T699.83-462.8Q634.15-397.13 540-397.13H365.5v75.22h160v91h-160v119.04h-91Zm91-376.26H540q55.85 0 95.17-39.33 39.33-39.32 39.33-95.17t-39.33-95.17q-39.32-39.33-95.17-39.33H365.5v269Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'USD'" class="w-5 h-5 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M439.57-116.41v-86.72q-54.92-12.48-93.9-47.79-38.97-35.32-56.74-99.23l80.94-32.15q14.76 48.95 43.9 73.47 29.14 24.53 75.71 24.53 40.28 0 67.23-17.43 26.94-17.42 26.94-54.27 0-33.33-22.24-53.71-22.24-20.38-101.76-45.66-85.04-26.52-117.52-65.1-32.48-38.57-32.48-93.53 0-64.04 41.4-101.24 41.41-37.19 88.52-43.63v-84.72h83.58v84.72q50.96 8.96 84.18 38.41 33.21 29.46 48.69 72.37l-80.45 34.39q-11.77-31.52-33.89-47.76-22.11-16.24-59.4-16.24-42.32 0-63.77 18.07-21.44 18.06-21.44 44.91 0 31.33 28.2 49.61 28.21 18.28 103.16 39.76 69.96 20.48 106.3 64.58 36.34 44.1 36.34 102.77 0 71.96-42.48 109.67-42.48 37.72-105.44 47.2v84.72h-83.58Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'EUR'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M597.37-116.41q-119.44 0-211.91-67.84-92.48-67.84-131.44-173.84H117.37v-83.82h117.7q-2-10.29-2-19.17v-37.49q0-8.52 2-19.52h-117.7v-83.82h136.41q38.96-106.24 131.56-173.96 92.59-67.72 212.03-67.72 69.96 0 132.77 24.96 62.82 24.96 112.49 70.63l-74.78 75.02q-35.72-29.72-79.34-46.57-43.62-16.86-91.14-16.86-73.57 0-133.03 37.3-59.47 37.31-91.47 97.2h224.5v83.82H345.43q-2 10.29-2.76 19.05-.76 8.76-.76 19.04t.76 19.04q.76 8.76 2.76 19.05h251.94v83.82h-224.5q32 59.89 91.47 97.2 59.46 37.3 133.03 37.3 47.52 0 92.02-16.86 44.5-16.85 77.98-46.57L842.63-212q-49.67 45.67-112.49 70.63-62.81 24.96-132.77 24.96Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'CNY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M434.5-111.87V-394.5h-200v-91h184.46L188.52-848.13h108.15L480-558.28l183.33-289.85h108.15L541.04-485.5H725.5v91h-200v282.63h-91Z"/></svg>
                                        <svg v-else-if="currentTransaction.currency == 'TRY'" class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M354.5-111.87v-202.89l-120 75v-108.15l120-75v-82.29l-120 75v-106.91l120-76v-235.02h91v178.33l160-100v106.91l-160 101v82.28l160-100v106.91l-160 101v168.11q78.93-2.15 133.97-60.05 55.03-57.9 55.03-139.23h91q0 121.54-83.05 206.27-83.06 84.73-202.45 84.73h-85.5Z"/></svg>
                                        <p v-else-if="currentTransaction.currency == 'BYN'" class="absolute translate-y-[-50%] top-1/2 right-4">Br</p>
                                        <p v-else-if="currentTransaction.currency == 'KZT'" class="absolute translate-y-[-50%] top-1/2 right-4">₸</p>
                                        <p v-else-if="currentTransaction.currency == 'UZS'" class="absolute translate-y-[-50%] top-1/2 right-4">S</p>
                                        <p v-else-if="currentTransaction.currency == 'AZN'" class="absolute translate-y-[-50%] top-1/2 right-4">₼</p>
                                        <p v-else-if="currentTransaction.currency == 'GEL'" class="absolute translate-y-[-50%] top-1/2 right-4">₾</p>
                                        <p v-else-if="currentTransaction.currency == 'THB'" class="absolute translate-y-[-50%] top-1/2 right-4">฿</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentTransaction.isActive" class="flex items-center justify-center">
                        <button @click="openDeleteConfirmationWindow" :disabled="buttonIsDisabled" type="button" class="bg-red-600 hover:bg-red-500 flex items-center text-white font-medium rounded-lg text-sm px-5 py-2">Удалить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import ConfirmationModalWindow from '@/components/modal/ConfirmationModalWindow.vue';

    export default {
        name: "TransactionDetailWindowComponent",
        components: {
            ConfirmationModalWindow,
        },
        props: {
            transactionDetailWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            currentTransaction: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                deleteConfirmationWindowIsOpened: false,
                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["labels"]),
            Math() {
                return Math;
            }
        },
        methods: {
            async deleteTransaction() {
                this.buttonIsDisabled = true;
                const transactionId = this.currentTransaction.id;
                const result = await this.$store.dispatch("deleteTransaction", transactionId);
                if (result.successfully) {
                    this.deleteConfirmationWindowIsOpened = false;
                    this.$notify("Транзакция успешно удалена");
                }
                this.buttonIsDisabled = false;
            },
            openDeleteConfirmationWindow() {
                this.deleteConfirmationWindowIsOpened = true;
            },
            closeTransactionDetailWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit('update:transactionDetailWindowIsOpened', false);
            },
        },
    }
</script>