<template>
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeTransferDetailWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p v-if="transfer.isWriteOff" class="text-2xl mb-8">Детали списания</p>
                    <p v-if="transfer.warehouseTo" class="text-2xl mb-8">Детали перемещения</p>
                    <p v-if="transfer.warehouse" class="text-2xl mb-8">Детали поставки</p>
                    <div v-if="transfer.warehouseFrom" class="grid grid-cols-3 gap-4 mb-8">
                        <TextInput v-model:textParam="warehouseFromVerboseName" :readOnly="true" labelContent="Откуда"/>
                        <TextInput v-model:textParam="warehouseToVerboseName" :readOnly="true" labelContent="Куда"/>
                        <TextInput v-model:textParam="verboseTransferDateOfCreated" :readOnly="true" labelContent="Дата"/>
                    </div>
                    <div v-else class="grid grid-cols-2 gap-4 mb-8">
                        <TextInput v-model:textParam="warehouseVerboseName" :readOnly="true" labelContent="Куда"/>
                        <TextInput v-model:textParam="verboseTransferDateOfCreated" :readOnly="true" labelContent="Дата"/>
                    </div>
                    <div class="w-full mb-8">
                        <div class="relative mb-2">
                            <div class="grid grid-cols-3 gap-4 mb-1">
                                <label class="mb-1 block text-sm text-gray-500">Атрикул</label>
                                <label class="mb-1 block text-sm text-gray-500">Товар</label>
                                <label class="mb-1 block text-sm text-gray-500">Количество</label>
                            </div>
                            <div v-for="(position, index) in transfer.positions" :key="index" class="grid grid-cols-3 gap-4 mb-4">
                                <TextInput v-model:textParam="position.good.verboseId" :readOnly="true"/>
                                <TextInput v-model:textParam="position.good.name" :readOnly="true"/>
                                <TextInput v-model:textParam="position.amount" :readOnly="true"/>
                            </div>
                        </div>
                    </div>
                    <CommentInput :commentParam="transfer.description || '-'" :readOnly="true" labelContent="Комментарий" class="mb-8"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import TextInput from '@/components/InputComponents/TextInput.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';


    export default {
        name: "SupplyingOrMovingDetailWindow",
        components: {
            TextInput,
            CommentInput,
        },
        props: {
            transfer: {
                type: Object,
                required: true,
            },
            transferDetailWindowIsOpened: {
                type: Boolean,
                required: true,
            }
        },
        computed: {
            ...mapState(["kassas"]),
            warehouseVerboseName() {
                if (!this.transfer.warehouse) {
                    return;
                }
                if (this.transfer.warehouse == "main") {
                    return "Главный склад";
                }
                const kassa = this.kassas.find(kassa => kassa.id == this.transfer.warehouse)
                return kassa.name
            },
            warehouseFromVerboseName() {
                if (!this.transfer.warehouseFrom) {
                    return;
                }
                if (this.transfer.warehouseFrom == "main") {
                    return "Главный склад";
                }
                const kassa = this.kassas.find(kassa => kassa.id == this.transfer.warehouseFrom)
                return kassa.name
            },
            warehouseToVerboseName() {
                if (this.transfer.isWriteOff) {
                    return "Списание"
                }
                if (!this.transfer.warehouseTo) {
                    return;
                }
                if (this.transfer.warehouseTo == "main") {
                    return "Главный склад";
                }
                const kassa = this.kassas.find(kassa => kassa.id == this.transfer.warehouseTo)
                return kassa.name
            },
            verboseTransferDateOfCreated() {
                return this.$globalMethods.formatDateTime(this.transfer.dateOfCreated)
            },
        },
        methods: {
            closeTransferDetailWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:transferDetailWindowIsOpened", false);
            }
        }
    }
</script>