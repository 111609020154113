<template>
    <MainHeaderComponent :activePage="activePage"/>
    <StaffPage/>
</template>


<script>
    import MainHeaderComponent from '@/components/laptop/MainHeaderComponent.vue';
    import StaffPage from '@/components/laptop/Staff/StaffPage.vue';

    export default {
        name: "StaffView",
        components: {
            StaffPage,
            MainHeaderComponent,
        },
        data() {
            return {
                activePage: "staffs",
            }
        },
        computed: {

        },
        methods: {
            async loadData() {

            },
        },
        async mounted() {
            await this.loadData();
        }
    };
</script>