<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-xl mb-8">Обновление сотрудника</p>
                    <div class="flex flex-col items-stretch gap-4">
                        <TextInput ref="nameInput" v-model:textParam="name" labelContent="Имя"/>
                        <PhoneInputComponent ref="phoneInput" v-model:inputParam="phone" labelContent="Телефон"/>
                        <div class="grid grid-cols-2">
                            <RadioInput @change="handleChangeJobTitle" v-model:radioParam="jobtitle" name="job-title" value="cashier" labelContent="Кассир"/>
                            <RadioInput @change="handleChangeJobTitle" v-model:radioParam="jobtitle" name="job-title" value="manager" labelContent="Менеджер"/>
                        </div>

                        <CheckboxInput v-if="jobtitle != 'cashier'" v-model:checkboxParam="isGlobal" labelContent="Все кассы" class="px-2"/>
                        <KassaMultiSelection v-if="!isGlobal" ref="kassaSelection" v-model:multiSelectionParam="selectedKassas"/>
                        <PriceInput v-model:priceParam="salaryPerDayShift" :priceCurrency="companyData.mainCurrency" labelContent="Ставка за смену (день)"/>
                        <PriceInput v-model:priceParam="salaryPerDayHour" :priceCurrency="companyData.mainCurrency" labelContent="Ставка за час (день)"/>
                        <PriceInput v-model:priceParam="salaryPerNightShift" :priceCurrency="companyData.mainCurrency" labelContent="Ставка за смену (ночь)"/>
                        <PriceInput v-model:priceParam="salaryPerNightHour" :priceCurrency="companyData.mainCurrency" labelContent="Ставка за час (ночь)"/>
                        <DiscountInput v-model:discountParam="percentOfSale" labelContent="Процент с продаж" />
                        <button @click.stop="updateStaff" :disabled="buttonIsDisabled" ref="sendRequestToAddGoodButton" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import PhoneInputComponent from '@/components/InputComponents/PhoneInputComponent.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import DiscountInput from '@/components/InputComponents/DiscountInput.vue';
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import KassaMultiSelection from '@/components/InputComponents/KassaMultiSelection.vue';
    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';
    import RadioInput from '@/components/InputComponents/RadioInput.vue';

    export default {
        name: "UpdateStaffWindow",
        components: {
            PhoneInputComponent,
            PriceInput,
            DiscountInput,
            TextInput,
            KassaMultiSelection,
            CheckboxInput,
            RadioInput,
        },
        props: {
            windowIsOpened: {
                type: Boolean,
                required: true,
            },
            staff: {
                type: [String, Number],
                required: true,
            }
        },
        computed: {
            ...mapState(["companyData", "kassas", "staffs"]),
        },
        data() {
            return {
                name: null,
                phone: null,
                jobtitle: "cashier",
                isGlobal: false,
                selectedKassas: [],
                salaryPerDayShift: null,
                salaryPerDayHour: null,
                salaryPerNightShift: null,
                salaryPerNightHour: null,
                percentOfSale: null,
                buttonIsDisabled: false,
            }
        },
        methods: {
            validateData() {
                if (!this.name) {
                    const nameInput = this.$refs.nameInput.$refs.textInput;
                    nameInput.setCustomValidity("Поле имени обязательное");
                    nameInput.reportValidity();
                    return false;
                }
                if (!this.phone) {
                    const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                    phoneInput.setCustomValidity("Поле теелфона обязатеьное");
                    phoneInput.reportValidity();
                    return false;
                }
                if (!this.$globalMethods.validatePhone(this.phone)) {
                    const phoneInput = this.$refs.phoneInput.$refs.phoneInput;
                    phoneInput.setCustomValidity("Введите корректный номер телефона");
                    phoneInput.reportValidity();
                    return false;
                }
                if (!this.isGlobal && Object.values(this.selectedKassas).every(value => value === false)) {
                    const kassaSelection = this.$refs.kassaSelection;
                    kassaSelection.$refs.kassaSelectionBlock.scrollIntoView({ behavior: "smooth", block: "start" });
                    kassaSelection.$refs.kassaSelectionButton.click();
                    return false;
                }
                return true;
            },
            async updateStaff() {
                if (!this.validateData()) {
                    return;
                }
                this.buttonIsDisabled = true;
                
                const shopIds = [];
                for (const shopId in this.selectedKassas) {
                    if (this.selectedKassas[shopId]) {
                        shopIds.push(Number(shopId));
                    }
                }

                const data = {
                    staffId: this.staff.id,
                    name: this.name, 
                    phone: this.phone,
                    jobTitle: this.jobtitle,
                    salaryPerDayHour: Number(this.salaryPerDayHour),
                    salaryPerDayShift: Number(this.salaryPerDayShift),
                    salaryPerNightHour: Number(this.salaryPerNightHour),
                    salaryPerNightShift: Number(this.salaryPerNightShift),
                    percentOfSale: Number(this.percentOfSale),
                    isGlobal: this.isGlobal,
                    isActive: this.staff.isActive,
                    shopIds: shopIds,
                }
                const result = await this.$store.dispatch("updateStaff", data);
                if (result.successfully) {
                    this.$notify("Сотрудник успешно обновлен");
                    this.closeWindow();
                }
                this.buttonIsDisabled = false;
            },
            handleChangeJobTitle() {
                this.isGlobal = false
            },
            closeWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:windowIsOpened", false);
            },
            initData() {
                this.name = this.staff.name;
                this.phone = this.staff.phone;
                this.jobtitle = this.staff.jobTitle;
                this.isGlobal = this.staff.isGlobal;
                this.salaryPerDayShift = this.staff.salaryPerDayShift;
                this.salaryPerDayHour = this.staff.salaryPerDayHour;
                this.salaryPerNightShift = this.staff.salaryPerNightShift;
                this.salaryPerNightHour = this.staff.salaryPerNightHour;
                this.percentOfSale = this.staff.percentOfSale;
                this.selectedKassas = {};
                for (const kassa of this.kassas) {
                    if (this.staff.shops.includes(kassa.id)) {
                        this.selectedKassas[kassa.id] = true;
                    } else {
                        this.selectedKassas[kassa.id] = false;
                    }
                }
            },
        },
        created() {
            this.initData();
        }
    }
</script>