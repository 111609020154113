<template>
  <div v-if="dataIsLoaded">
    <router-view/>
  </div>
  <div v-else>
    <LoadingComponent />
  </div>
</template>

<script>
  import LoadingComponent from '@/components/LoadingComponent.vue';

  export default {
    name: "App",
    components: {
      LoadingComponent,
    },
    data() {
      return {
        dataIsLoaded: false,
      }
    },
    methods: {
      async loadData() {
        let result = {successfully: true};
        result = await this.$store.dispatch("fetchCSRFToken");
        if (result.successfully) { result = await this.$store.dispatch('fetchSignIn', { username: "new-ui-test-account-test-ui@yandex.ru", password: "A123456789" }); }
        // if (result.successfully) { result = await this.$store.dispatch('fetchSignIn', { username: "abdusamad.dusabaev@yandex.ru", password: "qkassa2024" }); } 
        if (result.successfully) { result = await this.$store.dispatch("fetchCompanyData"); }
        if (result.successfully) { result = await this.$store.dispatch("fetchKassas"); }
        if (result.successfully) { result = await this.$store.dispatch("fetchProfileData"); }
        if (result.successfully) { result = await this.$store.dispatch("fetchClients"); }
        if (result.successfully) { result = await this.$store.dispatch("fetchGoods"); }
        if (result.successfully) { result = await this.$store.dispatch("fetchSupplyingsAndMovings"); }
        if (result.successfully) { result = await this.$store.dispatch("fetchStaffs"); }
        if (result.successfully) { this.dataIsLoaded = true; }
      }
    },
    async mounted() {
      await this.loadData();
    },
  }
</script>
