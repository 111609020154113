<template>
    <div v-if="catalogWindowIsOpened" class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[80vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeCatalogWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl text-center mb-8">Каталог</p>
                    <div class="flex items-center justify-between mb-4">
                        <div class="relative w-[30rem]">
                            <input v-model="valueToSearchingCatalog" id="catalog-search" class="min-w-[25rem] block py-1 pl-4 pr-14 w-full z-20 text-gray-900 rounded-lg border border-custom-blue focus:ring-blue-500 focus:border-blue-500" placeholder="Поиск по названию, артикулу и комментарию" required />
                            <button type="button" class="absolute top-0 end-0 py-1 px-4 font-medium h-full text-white rounded-e-lg border border-custom-blue bg-custom-blue hover:bg-custom-blue-hover">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </div>
                        <button @click="addSelectedGoodsFromCatalog" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Добавить</button>
                    </div>
                    <div>
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-sm text-gray-700 bg-white">
                                <tr class="border-b border-gray-300">
                                    <th scope="col" class="p-2 w-[5%]">
                                        <CheckboxInput @change="handleChangeAllGoodsSelected" ref="selectAllGoodInCatalog" v-model:checkboxParam="allGoodsSelected"/>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-[10%]">
                                        <span class="font-normal">Артикул</span>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-[20%]">
                                        <span class="font-normal">Название</span>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-[15%]">
                                        <span class="font-normal">Количество</span>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-0">
                                        <span class="font-normal">Характеристики</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <td v-if="searchedGoodsInCatalog.length == 0" colspan="5" class="text-base text-center pt-4">Не найдено ни одной позиции в каталоге</td>
                                <tr v-else @click="selectGoodInCatalog(good)" v-for="good in searchedGoodsInCatalog" :key="good.id" class="border-b border-gray-300 font-normal text-black bg-white cursor-pointer">
                                    <td class="p-2">
                                        <CheckboxInput v-model:checkboxParam="good.selected"/>
                                    </td>
                                    <td class="px-4 py-2 whitespace-nowrap">
                                        <span>{{ good.verboseId }}</span>
                                    </td>
                                    <td class="px-4 py-2">
                                        <span>{{ good.name }}</span>
                                    </td>
                                    <td class="px-4 py-2 whitespace-nowrap">
                                        <span v-if="good.format != 'good'">∞</span>
                                        <span v-else>{{ good.amount[currentKassaId] || "0" }}</span>
                                    </td>
                                    <td class="px-4 py-2 whitespace-nowrap overflow-x-scroll no-scrollbar">
                                        <span>{{ $globalMethods.formatCharacteristics(good.characteristics) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Decimal from 'decimal.js';
    import { mapState } from 'vuex';

    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';

    export default {
        name: "CatalogWindowComponent",
        components: {
            CheckboxInput,
        },
        props: {
            catalogWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            operations: {
                type: Array,
                required: true,
            },
            initialCatalogGoods: {
                type: Array,
                required: true,
            },
            currentKassaId: {
                type: [String, Number],
                required: false,
            },
            forWindow: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                catalogGoods: [],
                valueToSearchingCatalog: "",
                allGoodsSelected: false,
            }
        },
        computed: {
            ...mapState([]),
            searchedGoodsInCatalog() {
                return this.catalogGoods.filter(good => (good.name.toLowerCase().includes(this.valueToSearchingCatalog.toLowerCase()) || good.verboseId.toLowerCase().includes(this.valueToSearchingCatalog.toLowerCase())))
            },

        },
        methods: {
            copyInitialCatalogGoods() {
                this.catalogGoods = structuredClone(this.initialCatalogGoods);
            },
            closeCatalogWindow() {
                this.$emit('update:catalogWindowIsOpened', false);
            },
            selectGoodInCatalog(good) {
                good.selected = !good.selected;
            },
            handleChangeAllGoodsSelected() {
                for (const element of this.catalogGoods) {
                    element.selected = this.allGoodsSelected;
                }
            },
            addSelectedGoodsFromCatalog() {
                const selectedGoods = this.catalogGoods.filter(element => element.selected === true);
                if (selectedGoods.length == 0) {
                    const selectAllGoodInCatalog = this.$refs.selectAllGoodInCatalog.$refs.checkboxInput;
                    selectAllGoodInCatalog.setCustomValidity("Выберите минимум одну позицию для добавления");
                    selectAllGoodInCatalog.reportValidity();
                    return
                }

                if (this.forWindow == "supplying") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        operations.push({
                            good: good,
                            amount: 0,
                            resultAmount: good.amount[this.currentKassaId] || 0,
                        })
                    }
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                }

                if (this.forWindow == "sale") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        let amount;
                        if (good.maxAmount == 0 && good.format == 'good') {
                            amount = 0
                        } else {
                            amount = 1
                        }

                        let maxAmount;
                        if (good.amount) {
                            maxAmount = good.amount[this.currentKassaId] || 0;
                        } else {
                            maxAmount = 0;
                        }

                        operations.push({
                            good: good,
                            goodVerboseId: good.verboseId,
                            id: good.id,
                            name: good.name,
                            format: good.format,
                            price: Number(good.price),
                            priceWithoutDiscount: Number(good.price),
                            amount: amount,
                            maxAmount: maxAmount,
                            totalPrice: Number(new Decimal(good.price).mul(amount).toString()),   
                        })
                    }
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                } 

                if (this.forWindow == "operation") {
                    let operations = [];
                    for (const good of selectedGoods) {
                        operations.push({
                            good: good,
                            goodVerboseId: good.verboseId,
                            id: good.id,
                            name: good.name,
                            price: Number(good.price),
                            amount: 1,
                            totalPrice:  Number(new Decimal(good.price).toString()),
                        })
                    }
                    this.$emit("update:operations", operations);
                    this.closeCatalogWindow();
                    return;
                }
            },
        },
        created() {
            this.copyInitialCatalogGoods();
        },
        mounted() {

        },
    }
</script>