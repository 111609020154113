import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './app.css'
import 'quill/dist/quill.snow.css';
import globalMethods from './globalMethods.js';
import notification from './plugins/notification'


const app = createApp(App);
app.config.globalProperties.$globalMethods = globalMethods;
app.use(store).use(router).use(notification, store).mount('#app');
