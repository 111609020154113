<template>
    <ClientDetailInfoWindow v-if="clientDeatilInfoIsOpened" v-model:isOpened="clientDeatilInfoIsOpened" v-model:client="currentClient"/>
    <AddClientWindow v-if="addClientIsOpened" v-model:addClientIsOpened="addClientIsOpened" v-model:uploadClientsCSVIsOpened="uploadClientsCSVIsOpened" :showUploadCSV="true"/>
    <DownloadClientsCSVWindow v-if="downloadClientsCSVIsOpened" v-model:isOpened="downloadClientsCSVIsOpened"/>
    <UploadClientsCSVWindow v-if="uploadClientsCSVIsOpened" v-model:uploadClientsCSVIsOpened="uploadClientsCSVIsOpened" v-model:addClientIsOpened="addClientIsOpened"/>
    <DiscountPolicyWindow v-if="discountPolicyIsOpened" v-model:isOpened="discountPolicyIsOpened"/>

    <ConfirmationModalWindow v-if="deleteClientIsOpened" @confirm="deleteClient" v-model:isOpened="deleteClientIsOpened" title="Вы уверены, что хотите удалить клиента?"/>
    <ConfirmationModalWindow v-if="hideClientIsOpened" @confirm="hideClient" v-model:isOpened="hideClientIsOpened" title="Вы уверены, что хотите скрыть клиента?"/>
    <ConfirmationModalWindow v-if="showClientIsOpened" @confirm="showClient" v-model:isOpened="showClientIsOpened" title="Вы уверены, что хотите показать клиента?"/>
    <ConfirmationModalWindow v-if="deleteClientsIsOpened" @confirm="deleteClients" v-model:isOpened="deleteClientsIsOpened" title="Вы уверены, что хотите удалить выбранных клиентов?"/>
    <ConfirmationModalWindow v-if="hideClientsIsOpened" @confirm="hideClients" v-model:isOpened="hideClientsIsOpened" title="Вы уверены, что хотите скрыть выбранных клиентов?"/>
    <ConfirmationModalWindow v-if="showClientsIsOpened" @confirm="showClients" v-model:isOpened="showClientsIsOpened" title="Вы уверены, что хотите показать выбранных клиентов?"/>
    
    <section v-if="selectedClients.length > 0" class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
        <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
                <button @click="openShowClients" type="button" class="flex items-center rounded-md text-custom-blue border-2 border-custom-blue-dimmed hover:border-custom-blue px-4 py-1 mr-5">
                    <svg class="open-show-button w-5 h-5 cursor-pointer mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                    <span>Показать</span>
                </button>
                <button @click="openHideClients" type="button" class="flex items-center rounded-md text-custom-blue border-2 border-custom-blue-dimmed hover:border-custom-blue px-4 py-1 mr-5">
                    <svg class="open-hide-button w-5 h-5 cursor-pointer mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
                    <span>Скрыть</span>
                </button>
                <button @click="openDeleteClients" type="button" class="flex items-center rounded-md text-red-500 border-2 border-red-300 hover:border-red-500 px-4 py-1">
                    <svg class="open-delete-info-button w-5 h-5 cursor-pointer mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                    <span>Удалить</span>
                </button>
            </div>
        </div>
    </section>

    <section v-else class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
        <div class="flex items-center justify-between w-full">
            <button @click="openDiscountPolicy" type="button" class="hover:bg-gray-100 relative border border-custom-blue text-sm text-custom-blue py-2 rounded-md flex items-center font-medium px-5 mr-4 whitespace-nowrap">
                <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M300-520q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T360-660q0-25-17.5-42.5T300-720q-25 0-42.5 17.5T240-660q0 25 17.5 42.5T300-600Zm360 440q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T720-300q0-25-17.5-42.5T660-360q-25 0-42.5 17.5T600-300q0 25 17.5 42.5T660-240Zm-444 80-56-56 584-584 56 56-584 584Z"/></svg>
                <span>Политика скидок</span>
            </button>
            <div class="flex items-stretch">
                <div class="relative w-full mr-4">
                    <input v-model="searchClientValue" type="search" id="search-dropdown" class="min-w-[25rem] h-full block py-1 px-4 w-full z-20 text-gray-900 rounded-lg border border-custom-blue focus:ring-blue-500 focus:border-blue-500" placeholder="Поиск по имени, email и телефону" />
                    <button type="submit" class="absolute top-0 end-0 py-1 px-4 font-medium h-full text-white rounded-e-lg border border-custom-blue bg-custom-blue hover:bg-custom-blue-hover">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                        <span class="sr-only">Search</span>
                    </button>
                </div>
                <button @click="openDownloadClientsCSV" type="button" class="relative border border-custom-blue hover:bg-gray-100 text-sm text-custom-blue py-2 rounded-md flex items-center font-medium px-5 mr-4 whitespace-nowrap">
                    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-322.87 268.52-534.35l63.89-65.41L434.5-497.44v-310.69h91v310.69l102.09-102.32 63.89 65.41L480-322.87Zm-237.13 171q-37.78 0-64.39-26.61t-26.61-64.39v-120h91v120h474.26v-120h91v120q0 37.78-26.61 64.39t-64.39 26.61H242.87Z"></path></svg>
                    <span>Выгрузка CSV</span>
                </button>
                <button @click="openAddClient" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                    <span>Добавить</span>
                </button>
            </div>
        </div>
    </section>

    <section class="mx-8 mb-8">
        <div class="relative overflow-x-auto shadow-md sm:rounded-md">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-sm text-gray-700 bg-white">
                    <tr class="border-b border-gray-300">
                        <th scope="col" class="px-4 py-3 w-0">
                            <CheckboxInput @change="handleChangeAllClientsSelected" v-model:checkboxParam="allClientsSelected"/>
                        </th>
                        <th @click="sortByName" scope="col" class="px-4 py-3 w-0 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">ФИО</span>
                                <IconSortAscending v-if="sortedBy == 'name-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'name-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Телефон</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Email</span>
                        </th>
                        <th @click="sortByTotal" scope="col" class="px-4 py-3 w-0 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Куплено</span>
                                <IconSortAscending v-if="sortedBy == 'total-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'total-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th @click="sortByDiscount" scope="col" class="px-4 py-3 w-0 cursor-pointer">
                            <div class="flex items-center justify-between">
                                <span class="font-normal mr-4">Скидка</span>
                                <IconSortAscending v-if="sortedBy == 'discount-ascending'" class="w-4 h-4 shrink-0"/>
                                <IconSortDescending v-if="sortedBy == 'discount-descending'" class="w-4 h-4 shrink-0"/>
                            </div>
                        </th>
                        <th scope="col" class="px-4 py-3 w-auto">
                            <span class="font-normal">Комментарий</span>
                        </th>
                        <th scope="col" class="p-3 w-0">
                            <span class="sr-only">Дополнительно</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="text-sm">
                    <tr v-if="clients.length == 0">
                        <td colspan="10" class="text-center text-lg p-2">У вас пока нет клиентов</td>
                    </tr>
                    <tr v-for="client in searchedClients" :key="client.id" @click="toggleSelectClient($event, client)" class="font-normal text-black bg-white border-b cursor-pointer hover:bg-gray-50">
                        <td class="px-4 py-2">
                            <CheckboxInput v-model:checkboxParam="selectionClients[client.id]"/>
                        </td>
                        <td class="px-4 py-2 whitespace-nowrap">{{ client.name }}</td>
                        <td class="px-4 py-2 whitespace-nowrap">{{ client.phone }}</td>
                        <td class="px-4 py-2 whitespace-nowrap">{{ client.email }}</td>
                        <td class="px-4 py-2 whitespace-nowrap">
                            <span class="mr-1">{{ $globalMethods.formatNumberWithSpaces(client.totalPayments[companyData.mainCurrency]) }}</span>
                            <span>{{ labels.currencies[companyData.mainCurrency] }}</span>
                        </td>
                        <td class="px-4 py-2">{{ `${client.discount}%` }}</td>
                        <td class="px-4 py-2">{{ client.note }}</td>
                        <td class="px-4 py-2 flex items-center gap-2">
                            <svg v-if="client.isActive === true" @click="openHideClient(client)" class="open-hide-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>
                            <svg v-else @click="openShowClient(client)" class="open-show-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                            <svg @click="openDetailInfo(client)" class="open-detail-info-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/></svg>
                            <svg @click="openDeleteClient(client)" class="open-delete-info-button w-5 h-5 text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>


<script>
    import { mapState } from 'vuex';

    import AddClientWindow from '@/components/laptop/Client/AddClientWindow.vue';
    import ClientDetailInfoWindow from '@/components/laptop/Client/ClientDetailInfoWindow.vue';
    import DownloadClientsCSVWindow from '@/components/laptop/Client/DownloadClientsCSVWindow.vue';
    import UploadClientsCSVWindow from '@/components/laptop/Client/UploadClientsCSVWindow.vue';
    import DiscountPolicyWindow from '@/components/laptop/Client/DiscountPolicyWindow.vue';

    import ConfirmationModalWindow from '@/components/modal/ConfirmationModalWindow.vue';

    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';

    import IconSortAscending from '@/components/Icon/IconSortAscending.vue';
    import IconSortDescending from '@/components/Icon/IconSortDescending.vue';

    export default {
        name: "ClientsList",
        components: {
            AddClientWindow,
            ClientDetailInfoWindow,
            DownloadClientsCSVWindow,
            UploadClientsCSVWindow,
            DiscountPolicyWindow,

            ConfirmationModalWindow,

            CheckboxInput,

            IconSortAscending,
            IconSortDescending,
        },
        data() {
            return {
                currentClient: null,
                clientDeatilInfoIsOpened: false,
                addClientIsOpened: false,
                deleteClientIsOpened: false,
                hideClientIsOpened: false,
                showClientIsOpened: false,
                downloadClientsCSVIsOpened: false,
                uploadClientsCSVIsOpened: false,
                discountPolicyIsOpened: false,
                sortedBy: false,

                deleteClientsIsOpened: false,
                hideClientsIsOpened: false,
                showClientsIsOpened: false,

                searchClientValue: "",
                selectionClients: {},
                allClientsSelected: false,
            }
        },
        computed: {
            ...mapState(["companyData", "clients", "labels"]),
            searchedClients() {
                let searchedClients = this.clients.filter(client => client.name.toLowerCase().includes(this.searchClientValue.toLowerCase()) ||
                                                     client.phone.replace(/[+\-\s()]/g, "").includes(this.searchClientValue.replace(/[+\-\s()]/g, "")) || 
                                                     client.email.toLowerCase().includes(this.searchClientValue.toLowerCase()));

                if (this.sortedBy == "name-ascending") {
                    searchedClients.sort((element1, element2) => element1.name.localeCompare(element2.name));
                } else if (this.sortedBy == "name-descending") {
                    searchedClients.sort((element1, element2) => element2.name.localeCompare(element1.name));
                } else if (this.sortedBy == "total-ascending") {
                    searchedClients.sort((element1, element2) => element1.totalPayments[this.companyData.mainCurrency] - element2.totalPayments[this.companyData.mainCurrency]);
                } else if (this.sortedBy == "total-descending") {
                    searchedClients.sort((element1, element2) => element2.totalPayments[this.companyData.mainCurrency] - element1.totalPayments[this.companyData.mainCurrency]);
                } else if (this.sortedBy == "discount-ascending") {
                    searchedClients.sort((element1, element2) => element1.discount - element2.discount);
                } else if (this.sortedBy == "discount-descending") {
                    searchedClients.sort((element1, element2) => element2.discount - element1.discount);
                } else {
                    searchedClients.sort((element1, element2) => element2.id - element1.id);
                }

                searchedClients.sort((element1, element2) => element2.isActive - element1.isActive);
                return searchedClients;
            },
            selectedClients() {
                let result = [];
                for (const clientId in this.selectionClients) {
                    if (this.selectionClients[clientId]) {
                        result.push(Number(clientId));
                    }
                }
                return result;
            },  
        },
        methods: {
            async showClients() {
                const result = await this.$store.dispatch("showClients", this.selectedClients);
                if (result.successfully) {
                    this.$notify("Выбранные клиенты успешно удалены из скрытых");
                    this.closeShowClients();
                }
            },
            async hideClients() {
                const result = await this.$store.dispatch("hideClients", this.selectedClients);
                if (result.successfully) {
                    this.$notify("Выбранные клиенты успешно добавлены в скрытые");
                    this.closeHideClients();
                }
            },
            async deleteClients() {
                const result = await this.$store.dispatch("deleteClients", this.selectedClients);
                if (result.successfully) {
                    this.$notify("Выбранные клиенты успешно удалены");
                    this.closeDeleteClients();
                }
            },
            async showClient() {
                const result = await this.$store.dispatch("showClient", this.currentClient.id);
                if (result.successfully) {
                    this.$notify("Клиент успешно удален из скрытых");
                    this.closeShowClient();
                }
            },
            async hideClient() {
                const result = await this.$store.dispatch("hideClient", this.currentClient.id);
                if (result.successfully) {
                    this.$notify("Клиент успешно добавлен в скрытые");
                    this.closeHideClient();
                }
            },
            async deleteClient() {
                const result = await this.$store.dispatch("deleteClient", this.currentClient.id);
                if (result.successfully) {
                    this.$notify("Клиент успешно удален");
                    this.closeDeleteClient();
                }
            },
            openDownloadClientsCSV() {
                document.body.classList.add("overflow-hidden");
                this.downloadClientsCSVIsOpened = true;
            },
            openShowClients() {
                document.body.classList.add("overflow-hidden");
                this.showClientsIsOpened = true;
            },
            closeShowClients() {
                this.autoFillSelectionClients();
                document.body.classList.remove("overflow-hidden");
                this.showClientsIsOpened = false;
            },
            openHideClients() {
                document.body.classList.add("overflow-hidden");
                this.hideClientsIsOpened = true;
            }, 
            closeHideClients() {
                this.autoFillSelectionClients();
                document.body.classList.remove("overflow-hidden");
                this.hideClientsIsOpened = false;
            }, 
            openDeleteClients() {
                document.body.classList.add("overflow-hidden");
                this.deleteClientsIsOpened = true;
            },
            closeDeleteClients() {
                this.autoFillSelectionClients();
                document.body.classList.remove("overflow-hidden");
                this.deleteClientsIsOpened = false;
            },
            openHideClient(client) {
                document.body.classList.add("overflow-hidden");
                this.currentClient = this.clients.find(element => element.id == client.id)
                this.hideClientIsOpened = true;
            },
            closeHideClient() {
                this.currentClient = null;
                document.body.classList.remove("overflow-hidden");
                this.hideClientIsOpened = false;
            },  
            openShowClient(client) {
                document.body.classList.add("overflow-hidden");
                this.currentClient = this.clients.find(element => element.id == client.id)
                this.showClientIsOpened = true;
            },
            closeShowClient() {
                this.currentClient = null;
                document.body.classList.remove("overflow-hidden");
                this.showClientIsOpened = false;
            },
            openDeleteClient(client) {
                document.body.classList.add("overflow-hidden");
                this.currentClient = this.clients.find(element => element.id == client.id)
                this.deleteClientIsOpened = true;
            },
            closeDeleteClient() {
                this.currentClient = null;
                document.body.classList.remove("overflow-hidden");
                this.deleteClientIsOpened = false;
            },
            openDetailInfo(client) {
                document.body.classList.add("overflow-hidden");
                this.currentClient = this.clients.find(element => element.id == client.id)
                this.clientDeatilInfoIsOpened = true;
            },
            openAddClient() {
                document.body.classList.add("overflow-hidden");
                this.addClientIsOpened = true;
            },
            openDiscountPolicy() {
                document.body.classList.add("overflow-hidden");
                this.discountPolicyIsOpened = true;
            },
            handleChangeAllClientsSelected() {
                for (const client of this.searchedClients) {
                    this.selectionClients[client.id] = this.allClientsSelected;
                }
            },
            toggleSelectClient(event, client) {
                if (event.target.closest(".open-hide-button")) {
                    return;
                }
                if (event.target.closest(".open-show-button")) {
                    return;
                }
                if (event.target.closest(".open-detail-info-button")) {
                    return;
                }
                if (event.target.closest(".open-delete-info-button")) {
                    return;
                }
                this.selectionClients[client.id] = !this.selectionClients[client.id];
            },
            sortByName() {
                if (this.sortedBy == "name-ascending") {
                    this.sortedBy = "name-descending";
                } else if (this.sortedBy == "name-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "name-ascending";
                }
            },
            sortByTotal() {
                if (this.sortedBy == "total-ascending") {
                    this.sortedBy = "total-descending";
                } else if (this.sortedBy == "total-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "total-ascending";
                }
            },
            sortByDiscount() {
                if (this.sortedBy == "discount-ascending") {
                    this.sortedBy = "discount-descending";
                } else if (this.sortedBy == "discount-descending") {
                    this.sortedBy = null;
                } else {
                    this.sortedBy = "discount-ascending";
                }
            },
            autoFillSelectionClients() {
                this.selectionClients = {};
                for (const client of this.clients) {
                    this.selectionClients[client.id] = false;
                }
                this.allClientsSelected = false;
            },
        },
        mounted() {
            this.autoFillSelectionClients();
        },
    }
</script>