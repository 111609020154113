<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <div class="relative">
            <input v-model="discountValue" ref="discountInput" :id="uniqueId" type="text" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 pl-4 pr-10">
            <svg class="w-4 h-4 absolute translate-y-[-50%] top-1/2 right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M300-520q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T360-660q0-25-17.5-42.5T300-720q-25 0-42.5 17.5T240-660q0 25 17.5 42.5T300-600Zm360 440q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T720-300q0-25-17.5-42.5T660-360q-25 0-42.5 17.5T600-300q0 25 17.5 42.5T660-240Zm-444 80-56-56 584-584 56 56-584 584Z"/></svg>
        </div>
    </div>
</template>


<script>
    export default {
        name: "DiscountInput",
        props: {
            discountParam: {
                type: [String, Number],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        computed: {
            discountValue: {
                get() {
                    return this.discountParam;
                },
                set(value) {
                    let correctedValue = value.replace(/[^\d.]/g, "");
                    if (correctedValue.split(".").length > 2) {
                        correctedValue = correctedValue.split(".").slice(0, 2).join(".");
                    }
                    if (correctedValue.length > 1 && correctedValue.startsWith("0") && !correctedValue.startsWith("0.")) {
                        correctedValue = correctedValue.slice(1);
                    }
                    if (correctedValue.includes(".")) {
                        const parts = correctedValue.split(".");
                        correctedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                    }
                    if (Number(correctedValue) > 99.99) {
                        correctedValue = "99.99";
                    } else if (Number(correctedValue) < 0) {
                        correctedValue = "0";
                    }

                    this.$refs.discountInput.value = correctedValue;
                    this.$emit("update:discountParam", Number(correctedValue));
                    this.$emit("input");
                }
            }
        },
        data() {
            return {
                uniqueId: `discount-id-${this._uid}`,
            }
        }
    }
</script>