<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <input v-model="copiedInputParam" @input="handleChangeInputParam" :id="uniqueId" ref="phoneInput" type="text" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" placeholder="Введите номер телефона с кодом страны">
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "PhoneInputComponent",
        props: {
            inputParam: {
                type: [String, Number, null],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                copiedInputParam: null,
                currentMask: null,
            }
        },
        methods: {
            handleChangeInputParam() {
                this.$refs.phoneInput.setCustomValidity("");
                this.formatPhoneNumber();
                this.$emit("update:inputParam", this.copiedInputParam);
            },
            formatPhoneNumber() {
                if (this.copiedInputParam.length == 0) {
                    this.currentMask = null
                    return
                }
                const rawNumber = "+" + this.copiedInputParam.replace(/\D/g, "");

                const countryData = this.$globalMethods.getCountryByPrefix(rawNumber);
                if (countryData) {
                    this.currentMask = countryData.mask;
                }

                if (this.currentMask) {
                    this.copiedInputParam = this.$globalMethods.applyMask(rawNumber, this.currentMask);
                } else {
                    this.copiedInputParam = rawNumber;
                }
            },
            copyInputParam() {
                this.copiedInputParam = this.inputParam;
            }
        },
        mounted() {
            this.copyInputParam();
        },
        beforeUnmount() {
            
        }
    }
</script>