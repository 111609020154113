<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeDiscountPolicyModalWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-xl mb-8">Политика скидок</p>
                    <div class="flex flex-col items-stretch gap-4">
                        <div v-for="(discountPolicyStep, index) in currentDiscountPolicy" :key="discountPolicyStep" class="grid grid-cols-12 mb-2">
                            <PriceInput :ref="`discountPolicyStepTotal-${index}`" v-model:priceParam="discountPolicyStep.total" :priceCurrency="companyData.mainCurrency" class="col-span-7"/>
                            <div class="col-span-1 flex items-center justify-center">
                                <span class="text-2xl text-center">=</span>
                            </div>
                            <DiscountInput :ref="`discountPolicyStepValue-${index}`" v-model:discountParam="discountPolicyStep.value" class="col-span-3"/>
                            <div class="col-span-1 flex items-center justify-center">
                                <svg @click="deleteDiscountPolicyStep(index)" class="w-5 h-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                            </div>
                        </div>
                        <ButtonWithPlus @click="addDiscountPolicyStep" buttonContent="Добавить шаг скидки"/>
                        <button @click="sendRequestToUpdateDiscountPolicy" :disabled="buttonIsDisabled" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import DiscountInput from '@/components/InputComponents/DiscountInput.vue';

    import ButtonWithPlus from '@/components/Button/ButtonWithPlus.vue';

    export default {
        name: "DiscountPolicyWindow",
        components: {
            PriceInput,
            DiscountInput,
            ButtonWithPlus,
        },
        props: {
            isOpened: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                currentDiscountPolicy: [],
                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["companyData"]),
        },
        methods: {
            validateCurrentDiscountPolicyData() {
                let discountPolicyStepTotals = [];
                for (const [index, discountPolicyStep] of this.currentDiscountPolicy.entries()) {
                    if (!discountPolicyStep.total) {
                        const discountPolicyStepTotal = this.$refs[`discountPolicyStepTotal-${index}`][0].$refs.priceInput;
                        discountPolicyStepTotal.setCustomValidity("Поле обязательно для заполнения");
                        discountPolicyStepTotal.reportValidity();
                        return false;
                    }
                    if (Number(discountPolicyStep.total) < 0) {
                        const discountPolicyStepTotal = this.$refs[`discountPolicyStepTotal-${index}`][0].$refs.priceInput;
                        discountPolicyStepTotal.setCustomValidity("Значение не может быть отрицательным");
                        discountPolicyStepTotal.reportValidity();
                        return false;
                    }

                    if (!discountPolicyStep.value) {
                        const discountPolicyStepValue = this.$refs[`discountPolicyStepValue-${index}`][0].$refs.discountInput;
                        discountPolicyStepValue.setCustomValidity("Поле обязательно для заполнения");
                        discountPolicyStepValue.reportValidity();
                        return false;
                    }

                    if (discountPolicyStep.value < 0 || discountPolicyStep.value >= 100) {
                        const discountPolicyStepValue = this.$refs[`discountPolicyStepValue-${index}`][0].$refs.discountInput;
                        discountPolicyStepValue.setCustomValidity("Значение скидки должно быть в диапазоне от 0 до 100 не включительно");
                        discountPolicyStepValue.reportValidity();
                        return false;
                    }

                    if (discountPolicyStepTotals.includes(discountPolicyStep.total)) {
                        const discountPolicyStepTotal = this.$refs[`discountPolicyStepTotal-${index}`][0].$refs.priceInput;
                        discountPolicyStepTotal.setCustomValidity("Значение шага не должно повторяться");
                        discountPolicyStepTotal.reportValidity();
                        return false;
                    }

                    discountPolicyStepTotals.push(discountPolicyStep.total);
                }

                return true;
            },
            async sendRequestToUpdateDiscountPolicy() {
                if (!this.validateCurrentDiscountPolicyData()) {
                    return;
                }

                this.buttonIsDisabled = true;

                let newDiscountPolicyData = {};
                for (const discountPolicyStep of this.currentDiscountPolicy) {
                    newDiscountPolicyData[discountPolicyStep.total] = discountPolicyStep.value;
                }

                const result = await this.$store.dispatch("updateDiscountPolicy", newDiscountPolicyData);
                if (result.successfully) {
                    this.$notify("Политика скидок успешно обновлена");
                    this.closeDiscountPolicyModalWindow();
                }

                this.buttonIsDisabled = false;
            },
            deleteDiscountPolicyStep(index) {
                this.currentDiscountPolicy = this.currentDiscountPolicy.filter((_, elementIndex) => elementIndex != index);
            },
            addDiscountPolicyStep() {
                this.currentDiscountPolicy.push({total: null, value: null});
            },
            closeDiscountPolicyModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:isOpened", false);
            },
            copyDiscountPolicy() {
                for (const key in this.companyData.discountPolicy) {
                    this.currentDiscountPolicy.push({total: key, value: this.companyData.discountPolicy[key]});
                }
                if (this.currentDiscountPolicy.length == 0) {
                    this.currentDiscountPolicy.push({total: null, value: null});
                }
            }
        },
        mounted() {
            this.copyDiscountPolicy();
        },
    }
</script>