<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-40"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-50">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeAddClientModalWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <div class="flex items-center justify-between mb-8">
                        <p class="text-xl">Новый клиент</p>
                        <div v-if="showUploadCSV" @click="openUploadClientsCSVWindow" class="flex items-center text-custom-blue text-sm cursor-pointer">
                            <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
                            <p>Загрузить через CSV</p>
                        </div>
                    </div>
                    <div class="flex flex-col items-stretch gap-4">
                        <TextInput ref="addClientNameInput" v-model:textParam="addClientName" labelContent="ФИО"/>
                        <PhoneInputComponent ref="addClientPhoneInput" v-model:inputParam="addClientPhone" labelContent="Телефон"/>
                        <TextInput ref="addClientEmailInput" v-model:textParam="addClientEmail" labelContent="Email"/>
                        <div>
                            <DiscountInput v-model:discountParam="addClientPersonalDiscount" labelContent="Персональная скидка" class="mb-2"/>
                            <CheckboxInput v-model:checkboxParam="addClientAddToGeneralDiscountPolicy" labelContent="Действует общая политика скидок"/>
                        </div>
                        <CommentInput v-model:commentParam="addClientNote" labelContent="Комментарий"/>
                        <div>
                            <label for="client-total-payment" class="mb-1 block text-sm text-gray-500">Сумма покупок</label>
                            <div v-for="(clientTotalPayment, index) in clientTotalPayments" :key="index" class="grid grid-cols-10 mb-2">
                                <PriceInput ref="addClientTotalPaymentsInput" v-model:priceParam="clientTotalPayment.value" :priceCurrency="clientTotalPayment.currency" class="col-span-8 mr-2"/>
                                <select v-model="clientTotalPayment.currency" id="client-total-payment-currency" class="col-span-2 text-sm rounded !pr-7">
                                    <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                                </select>
                            </div>
                            <ButtonWithPlus v-if="clientTotalPayments.length < currencies.length" @click="addClientTotalPayment" buttonContent="Добавить в другой валюте"/>
                        </div>
                        <button @click="sendRequestToAddClient" :disabled="buttonIsDisabled" type="button" class="mt-4 text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import PhoneInputComponent from '@/components/InputComponents/PhoneInputComponent.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';
    import TextInput from '@/components/InputComponents/TextInput.vue';
    import DiscountInput from '@/components/InputComponents/DiscountInput.vue';
    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';

    import ButtonWithPlus from '@/components/Button/ButtonWithPlus.vue';

    export default {
        name: "AddClientModalWindow",
        components: {
            PhoneInputComponent,
            CommentInput,
            TextInput,
            DiscountInput,
            PriceInput,
            CheckboxInput,
            ButtonWithPlus,
        },
        props: {
            addClientIsOpened: {
                type: Boolean,
                required: true,
            },
            uploadClientsCSVIsOpened: {
                type: Boolean,
                required: true,
            },
            showUploadCSV: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                clientTotalPayments: [{ value: "0", currency: "RUB" }],
                addClientName: "",
                addClientPhone: "",
                addClientEmail: "",
                addClientPersonalDiscount: 0,
                addClientAddToGeneralDiscountPolicy: false,
                addClientNote: "",

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["currencies"])
        },
        methods: {
            validateAddClientData() {
                if (this.addClientName.length == 0) {
                    const addClientNameInput = this.$refs.addClientNameInput.$refs.textInput;
                    addClientNameInput.setCustomValidity("Поле имени обязательное");
                    addClientNameInput.reportValidity();
                    return false;
                }
                if (this.addClientPhone.length == 0) {
                    const addClientPhoneInput = this.$refs.addClientPhoneInput.$refs.phoneInput;
                    addClientPhoneInput.setCustomValidity("Поле телефона обязательное");
                    addClientPhoneInput.reportValidity();
                    return false;
                }
                if (!this.$globalMethods.validatePhone(this.addClientPhone)) {
                    const addClientPhoneInput = this.$refs.addClientPhoneInput.$refs.phoneInput;
                    addClientPhoneInput.setCustomValidity("Введите корректный номер телефона");
                    addClientPhoneInput.reportValidity();
                    return false;
                }

                const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                if (!emailRegex.test(this.addClientEmail)) {
                    const addClientEmailInput = this.$refs.addClientEmailInput.$refs.textInput;
                    addClientEmailInput.setCustomValidity("Значение должно быть корректным email");
                    addClientEmailInput.reportValidity();
                    return false;
                }
                const usedCurrencies = this.clientTotalPayments.map(item => item.currency);
                const uniqueCurrencies = new Set(usedCurrencies);
                if (uniqueCurrencies.size != usedCurrencies.length) {
                    const addClientTotalPaymentsInput = this.$refs.addClientTotalPaymentsInput[0].$refs.priceInput;
                    addClientTotalPaymentsInput.setCustomValidity("Валюты не должны повторяться");
                    addClientTotalPaymentsInput.reportValidity();
                    return false;
                }

                if (!this.addClientPersonalDiscount) {
                    this.addClientPersonalDiscount = 0;
                } else {
                    this.addClientPersonalDiscount = Number(this.addClientPersonalDiscount);
                }

                return true;
            },
            async sendRequestToAddClient() {
                if (!this.validateAddClientData()) {
                    return;
                }

                this.buttonIsDisabled = true;

                const cloneClientsTotalPayments = structuredClone(this.clientTotalPayments);
                this.currencies.forEach(currency => {
                    if (!cloneClientsTotalPayments.some(item => item.currency === currency)) {
                        cloneClientsTotalPayments.push({ value: "0", currency: currency });
                    }
                });
                for (const clientTotalPayment of cloneClientsTotalPayments) {
                    clientTotalPayment.value = Number(clientTotalPayment.value);
                }
                
                const result = await this.$store.dispatch("createNewClient", { 
                    clientTotalPayments: cloneClientsTotalPayments,
                    addClientName: this.addClientName,
                    addClientPhone: this.addClientPhone,
                    addClientEmail: this.addClientEmail,
                    addClientPersonalDiscount: Number(this.addClientPersonalDiscount),
                    addClientAddToGeneralDiscountPolicy: this.addClientAddToGeneralDiscountPolicy,
                    addClientNote: this.addClientNote,
                });
                if (result.successfully) {
                    this.$notify("Клиент успешно добавлен");
                    this.selectAddSaleClient(result.clientData);
                    this.closeAddClientModalWindow();
                } else {
                    if (result.error == "client with this email already exists") {
                        const addClientEmailInput = this.$refs.addClientEmailInput.$refs.textInput;
                        addClientEmailInput.setCustomValidity("Клиент с таким email уже существует в системе");
                        addClientEmailInput.reportValidity();
                    } else if (result.error == "client with this phone already exists") {
                        const addClientPhoneInput = this.$refs.addClientPhoneInput.$refs.phoneInput;
                        addClientPhoneInput.setCustomValidity("Клиент с таким номером телефона уже существует в системе");
                        addClientPhoneInput.reportValidity();
                    } else if (result.error == "the 'phone' value must be in correct format") {
                        const addClientPhoneInput = this.$refs.addClientPhoneInput.$refs.phoneInput;
                        addClientPhoneInput.setCustomValidity("Введите корректный номер телефона");
                        addClientPhoneInput.reportValidity();
                    } else if (result.error == "the 'email' value must be in the email format") {
                        const addClientEmailInput = this.$refs.addClientEmailInput.$refs.textInput;
                        addClientEmailInput.setCustomValidity("Введите корректный email");
                        addClientEmailInput.reportValidity();
                    }
                }
                this.buttonIsDisabled = false;
            },
            selectAddSaleClient(client) {
                this.$emit("select-client", client);
            },
            addClientTotalPayment() {
                this.clientTotalPayments.push({ value: "0", currency: this.currencies[this.clientTotalPayments.length] });
            },
            openUploadClientsCSVWindow() {
                this.$emit("update:uploadClientsCSVIsOpened", true);
                this.$emit("update:addClientIsOpened", false);
            },
            closeAddClientModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addClientIsOpened", false);
            },
        },
        mounted() {

        },
    }
</script>