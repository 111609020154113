<template>
    <div ref="kassaSelectionBlock">
        <label for="kassa-selection-button" class="mb-1 block text-sm text-gray-500">Касса</label>
        <button ref="kassaSelectionButton" @click="openCloseMultiSelection" id="kassa-selection-button" class="w-full min-h-[2.375rem] text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
            <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ selectedKassasVerboseName || "" }}</span> 
            <svg class="w-2.5 h-2.5 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
            </svg>
        </button>
        <div v-show="isOpened" class="z-10 bg-white absolute rounded-lg shadow w-[23.5rem] dark:bg-gray-700">
            <ul @click.stop class="max-h-52 px-3 py-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                <li v-for="kassa in kassas" :key="kassa.id">
                    <label class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <input v-model="selectedKassas[kassa.id]" type="checkbox" :value="kassa.id" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <span class="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{ kassa.name }}</span>
                    </label>
                </li>
                <li v-if="kassas.length == 0" class="text-center">У вас пока нет касс</li>
            </ul>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    export default {
        name: "KassaMultiSelection",
        props: {
            multiSelectionParam: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState(["kassas"]),
            selectedKassasVerboseName() {
                const selected = [];
                for (const kassa of this.kassas) {
                    if (this.selectedKassas[kassa.id]) {
                        selected.push(kassa.name);
                    }
                }
                const result = selected.join(" | ");
                return result;
            },
            selectedKassas: {
                get() {
                    return this.multiSelectionParam;
                },
                set(value) {
                    this.$emit("update:multiSelectionParam", value);
                },
            }
        },
        data() {
            return {
                isOpened: false,
            }
        },
        methods: {
            openCloseMultiSelection() {
                this.isOpened = !this.isOpened;
            },
            addAutoCloseMultiSelection() {
                document.body.addEventListener("click", (event) => {
                    const kassaSelectionBlock = this.$refs.kassaSelectionBlock;
                    if (kassaSelectionBlock && kassaSelectionBlock.contains(event.target)) {
                        return;
                    }
                    this.isOpened = false;
                })
            },
            initData() {
                if (!this.$globalMethods.isEmptyObject(this.multiSelectionParam)) {
                    return;
                }
                for (const kassa of this.kassas) {
                    this.selectedKassas[kassa.id] = false;
                }
            },
        },
        created() {
            this.initData();
        },
        mounted() {
            this.addAutoCloseMultiSelection();
        }
    }
</script>