<template>
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[80vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeReferenceWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Справка</p>
                    <div class="overflow-scroll no-scrollbar" v-html="reference"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReferenceWindowComponent",
        props: {
            referenceWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            reference: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            closeReferenceWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit('update:referenceWindowIsOpened', false);
            }
        }
    }
</script>