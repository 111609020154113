<template>
    <div>
        <section class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
            <div class="flex justify-between w-full">
                <div class="flex items-center gap-8">
                    <button type="button" @click="goToGoodList" class="rounded-md flex items-center">Каталог</button>
                    <button type="button" class="rounded-md bg-indigo-100 px-4 py-2 shadow">Характеристики</button>
                    <button type="button" @click="goToSupplyingAndMoving" class="rounded-md flex items-center">Поставки</button>
                </div>
                <div class="flex items-center">
                    <button v-if="characteristicsIsModified" @click="saveNewCharacteristics" :disabled="buttonIsDisabled" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 whitespace-nowrap">
                        <span>Сохранить</span>
                    </button>
                </div>
            </div>
        </section>

        <section class="mx-8 px-8 py-6 mb-[10px] bg-white rounded-md flex justify-between items-start">
            <div class="grid grid-cols-8 w-full">
                <div v-for="(element, indexName) in characteristics" :key="indexName" class="mb-8">
                    <div class="relative">
                        <input v-model="element['name']" @input="handleInputName($event)" type="text" class="w-full text-sm font-medium bg-gray-100 text-gray-900 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 pl-4 pr-8 peer"> 
                        <svg @click="deleteNameOption(indexName)" class="w-5 h-5 cursor-pointer text-gray-400 absolute right-1 top-1/2 -translate-y-1/2 opacity-0 hover:opacity-100 peer-hover:opacity-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                    </div>
                    <div v-if="Array.isArray(element['value'])">
                        <div v-for="(_, indexValue) in element['value']" :key="indexValue" class="relative">
                            <input @input="handleInputValueVariant($event)" v-model="element['value'][indexValue]" type="text" class="w-full text-sm text-gray-900 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 pl-4 pr-8 peer">
                            <svg @click="deleteValueOption(indexName, indexValue)" class="w-5 h-5 cursor-pointer text-gray-400 absolute right-1 top-1/2 -translate-y-1/2 opacity-0 hover:opacity-100 peer-hover:opacity-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                        </div>
                    </div>
                    <div v-else>
                        <input @input="handleInputValueVariant($event)" v-model="element['value']" type="text" class="w-full text-sm text-gray-900 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 pl-4 pr-8 peer">
                    </div>
                    <svg @click="addValueOption(indexName)" class="w-5 h-5 ml-4 mt-2 cursor-pointer text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                </div>
                <svg @click="addNameOption" class="w-6 h-6 ml-2 mt-2 cursor-pointer text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
            </div>
        </section>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    export default {
        name: "CharacteristicSettingsComponent",
        props: {
            mode: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                characteristics: [],
                result: null,
                characteristicsIsModified: false,
                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["companyData"]),
        },
        methods: {
            handleInputName(event) {
                this.characteristicsIsModified = true;
                event.target.value = event.target.value.replace(/['"[\]{}=;]/g, '');
            },
            handleInputValueVariant(event) {
                this.characteristicsIsModified = true;
                event.target.value = event.target.value.replace(/['"[\]{}=;]/g, '');
            },
            deleteNameOption(indexName) {
                this.characteristicsIsModified = true;
                this.characteristics = this.characteristics.filter((_, index) => index !== indexName);
            },
            deleteValueOption(indexName, indexValue) {
                this.characteristicsIsModified = true;
                this.characteristics[indexName]["value"] = this.characteristics[indexName]["value"].filter((_, index) => index !== indexValue);
                if (Array.isArray(this.characteristics[indexName]["value"]) && this.characteristics[indexName]["value"].length == 1) {
                    this.characteristics[indexName]["value"] = this.characteristics[indexName]["value"][0]
                }
            },
            addNameOption() {
                this.characteristicsIsModified = true;
                this.characteristics.push({name: "", value: ""});
            },
            addValueOption(index) {
                this.characteristicsIsModified = true;
                if (Array.isArray(this.characteristics[index]["value"])) {
                    this.characteristics[index]["value"] = [...this.characteristics[index]["value"], ""];
                } else {
                    this.characteristics[index]["value"] = [this.characteristics[index]["value"], ""]
                }
            },
            goToSupplyingAndMoving() {
                this.$emit("update:mode", "supplying-and-moving");
            },
            goToGoodList() {
                this.$emit("update:mode", "goods");
            },
            convertCharactericticsToValidFormat() {
                this.result = {};
                for (const element of this.characteristics) {
                    this.result[element['name']] = element['value'];
                }
            },
            async saveNewCharacteristics() {
                this.buttonIsDisabled = true;
                this.convertCharactericticsToValidFormat();
                const result = await this.$store.dispatch("saveNewCharacteristics", this.result);
                if (result.successfully) {
                    this.$notify("Характеристики успешно обновлены");
                    this.characteristicsIsModified = false;
                }
                this.buttonIsDisabled = false;
            },
            initData() {
                for (const [name, value] of Object.entries(this.companyData.defaultGoodCharacteristics)) {
                    this.characteristics.push({
                        name: name,
                        value: value
                    })
                }
            }
        },
        mounted() {
            this.initData();
        }
    }
</script>