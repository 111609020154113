<template>
    <section class="mx-8 px-8 py-6 mb-[5px] bg-white rounded-md flex justify-between items-start">
        <div class="flex flex-col justify-start">
            <h2 class="text-3xl mb-4">{{  kassaData.name }}</h2>
            <div class="flex items-center">
                <button @click="goToOperationsPage" type="button" class="rounded-md mr-8">Операции</button>
                <button type="button" class="rounded-md bg-indigo-100 px-4 py-2 shadow flex items-center">
                    <span>Задачи</span>
                    <div v-if="amountActiveTasks > 0" class="ml-2 relative flex items-center text-center justify-center rounded-full block min-w-6 w-auto h-auto text-xs text-white bg-red-700 p-1">
                        <span>{{ amountActiveTasks }}</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="flex items-center">
            <button @click="openAddTaskWindow" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2" type="button">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1.5rem" width="1.5rem" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                Задача
            </button>
        </div>
    </section>

    <section class="mx-8 mb-8">
        <div class="relative overflow-x-auto shadow-md sm:rounded-md">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-md text-gray-700 bg-white">
                    <tr class="border-b border-gray-300">
                        <th scope="col" class="px-6 py-3">
                            <span class="font-normal">Дата и время создания</span>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            <span class="font-normal">Описание</span>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            <span class="font-normal">Выполнено</span>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            <span class="font-normal">Исполнитель</span>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            <span class="font-normal">Дата и время выполнения</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="tasks.length == 0">
                        <td colspan="5" class="text-center text-lg p-2">За выбранный период нет текущих задач</td>
                    </tr>
                    <tr v-for="task in tasks" :key="task" :data-task-id="task.id" class="text-black bg-white border-b hover:bg-gray-50">
                        <td v-if="this.$globalMethods.isToday(task.dateOfCreated)" class="px-6 py-4">{{ `Сегодня ${this.$globalMethods.getTime(task.dateOfCreated)}` }}</td>
                        <td v-else-if="this.$globalMethods.isYesterday(task.dateOfCreated)" class="px-6 py-4">{{ `Вчера ${this.$globalMethods.getTime(task.dateOfCreated)}` }}</td>
                        <td v-else class="px-6 py-4">{{ `${this.$globalMethods.formatDate("ru", task.dateOfCreated)}` }} {{ this.$globalMethods.getTime(task.dateOfCreated) }}</td>
                        <td class="px-6 py-4 max-w-[30rem] overflow-x-scroll" style="scrollbar-width: none;">{{ task.content }}</td>
                        <td v-if="task.isCompleted" class="flex items-center justify-start px-6 py-4">
                            <svg class="text-green-700" xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 -960 960 960" fill="currentColor"><path d="m423.23-289.85 290.92-290.92L654-640.92 423.23-410.15l-116-116L247.08-466l176.15 176.15ZM480.07-68q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Zm-.07-86q136.51 0 231.26-94.74Q806-343.49 806-480t-94.74-231.26Q616.51-806 480-806t-231.26 94.74Q154-616.51 154-480t94.74 231.26Q343.49-154 480-154Zm0-326Z"/></svg>
                        </td>
                        <td v-else class="flex items-center justify-start px-6 py-4">
                            <button @click="openCompleteTaskModalWindow" type="button" class="open-complete-task-window-button">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 -960 960 960" fill="currentColor"><path d="M480.07-68q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Zm-.07-86q136.51 0 231.26-94.74Q806-343.49 806-480t-94.74-231.26Q616.51-806 480-806t-231.26 94.74Q154-616.51 154-480t94.74 231.26Q343.49-154 480-154Zm0-326Z"/></svg>
                            </button>
                        </td>
                        <td v-if="task.cleanExecutorID && task.cleanExecutorID == profileData.id " class="px-6 py-4">
                            Вы
                        </td>
                        <td v-else class="px-6 py-4">
                            {{ task.executor && task.executor.name || "-" }}
                        </td>
                        <td v-if="task.dateOfCompleted && this.$globalMethods.isToday(task.dateOfCompleted)" class="px-6 py-4">{{ `Сегодня ${this.$globalMethods.getTime(task.dateOfCompleted)}` }}</td>
                        <td v-else-if="task.dateOfCompleted && this.$globalMethods.isYesterday(task.dateOfCompleted)" class="px-6 py-4">{{ `Вчера ${this.$globalMethods.getTime(task.dateOfCompleted)}` }}</td>
                        <td v-else-if="task.dateOfCompleted" class="px-6 py-4">{{ `${this.$globalMethods.formatDate("ru", task.dateOfCompleted)}` }} {{ this.$globalMethods.getTime(task.dateOfCompleted) }}</td>
                        <td v-else class="px-6 py-4">-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>

    <div v-show="completeTaskModalIsOpened" class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div id="complete-task-modal-content" class="py-4 px-8 bg-white rounded-lg">
                <div class="flex items-center justify-center mb-6">
                    <svg class="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M477.28-240q21.96 0 37.3-15.34 15.33-15.33 15.33-37.32 0-21.99-15.33-37.18-15.34-15.18-37.3-15.18-21.95 0-37.29 15.18-15.34 15.19-15.34 37.18 0 21.99 15.34 37.32Q455.33-240 477.28-240Zm-38.63-154h78.78q0-32.04 7.62-51.28 7.62-19.24 41.43-51.29 25.28-25.04 40.16-48.91 14.88-23.87 14.88-57.39 0-56.89-40.52-87.25t-96.52-30.36q-57.96 0-94.65 31.2-36.7 31.19-51.18 74.63l71.26 27.67q5.48-18.48 22.38-39.24 16.91-20.76 50.99-20.76 29.13 0 43.94 16.31 14.8 16.3 14.8 35.87 0 18.8-11.64 35.94-11.64 17.14-27.97 30.71-43.04 38.52-53.4 59.36-10.36 20.83-10.36 74.79ZM480-71.87q-84.91 0-159.34-32.12-74.44-32.12-129.5-87.17-55.05-55.06-87.17-129.5Q71.87-395.09 71.87-480t32.12-159.34q32.12-74.44 87.17-129.5 55.06-55.05 129.5-87.17 74.43-32.12 159.34-32.12t159.34 32.12q74.44 32.12 129.5 87.17 55.05 55.06 87.17 129.5 32.12 74.43 32.12 159.34t-32.12 159.34q-32.12 74.44-87.17 129.5-55.06 55.05-129.5 87.17Q564.91-71.87 480-71.87Zm0-91q133.04 0 225.09-92.04 92.04-92.05 92.04-225.09 0-133.04-92.04-225.09-92.05-92.04-225.09-92.04-133.04 0-225.09 92.04-92.04 92.05-92.04 225.09 0 133.04 92.04 225.09 92.05 92.04 225.09 92.04ZM480-480Z"/></svg>
                </div>
                <p class="mb-6 text-lg">Вы уверены, что хотите завершить задачу?</p>
                <div class="flex items-center justify-center gap-8">
                    <button @click="sendRequestToCompleteTask" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Заврешить</button>
                    <button @click="closeCompleteTaskModalWindow" type="button" class="text-center bg-red-500 hover:bg-red-600 flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Отмена</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="addTaskModalWindow" class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeAddTaskWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Новая задача</p>
                    <CommentInput ref="newTaskContentInput" v-model:commentParam="newTaskContent" labelContent="Описание" class="mb-8"/>
                </div>
                <button @click="createNewTask" :disabled="buttonIsDisabled" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import CommentInput from '@/components/InputComponents/CommentInput.vue';

    export default {
        name: "KassaOperationsComponent",
        components: {
            CommentInput,
        },
        props: {
            mode: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                completeTaskModalIsOpened: false,
                addTaskModalWindow: false,
                currentTaskToComplete: null,
                dateFrom: this.$globalMethods.getCurrentDate(),
                dateTo: this.$globalMethods.getCurrentDate(),
                newTaskContent: "",
                buttonIsDisabled: false,
            }
        },
        methods: {
            goToOperationsPage() {
                this.$emit('update:mode', 'transactions');
            },
            validateNewTaskData() {
                if (!this.newTaskContent) {
                    const newTaskContentInput = this.$refs.newTaskContentInput.$refs.commentInput;
                    newTaskContentInput.setCustomValidity("Это поле обязательное");
                    newTaskContentInput.reportValidity();
                    return false;
                }
                return true;
            },
            async createNewTask() {
                if (!this.validateNewTaskData()) {
                    return;
                }
                this.buttonIsDisabled = true;
                const result = await this.$store.dispatch("createNewTask", { content: this.newTaskContent });
                if (result.successfully) {
                    this.$notify("Новая задача успешно создана");
                    this.closeAddTaskWindow();
                }
                this.buttonIsDisabled = false;
            },
            closeAddTaskWindow() {
                this.addTaskModalWindow = false;
                this.newTaskContent = "";
            },
            openAddTaskWindow() {
                this.addTaskModalWindow = true;
                this.newTaskContent = "";
            },
            closeCompleteTaskModalWindow() {
                this.completeTaskModalIsOpened = false;
                this.currentTaskToComplete = null;
            },
            openCompleteTaskModalWindow(event) {
                this.completeTaskModalIsOpened = true;
                const taskId = event.target.closest("tr").getAttribute("data-task-id");
                this.currentTaskToComplete = taskId;
            },
            async sendRequestToCompleteTask() {
                const result = await this.$store.dispatch("fetchCompleteTask", { taskID: this.currentTaskToComplete });
                if (result.successfully) {
                    this.$notify("Задача успешно завершена");
                    this.closeCompleteTaskModalWindow();
                }   
            },
            autoCloseCompleteTaskModalWindow() {
                document.addEventListener("click", (event) => {
                    if (!event.target.closest("#complete-task-modal-content") && !event.target.closest(".open-complete-task-window-button")) {
                        this.closeCompleteTaskModalWindow();
                    }
                })
            },
            async getCurrentTasks() {
                await this.$store.dispatch("fetchTasks", { dateFrom: this.dateFrom, dateTo: this.dateTo });
            }
        },
        computed: {
            ...mapState(["tasks", "kassaData", "profileData"]),
            amountActiveTasks() {
                return this.tasks.filter(element => { return !element.isCompleted }).length;
            },
        },
        mounted() {
            this.autoCloseCompleteTaskModalWindow();
            this.getCurrentTasks();
        },
    }
</script>