<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div v-show="firstStepIsOpened" ref="firstStep" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeAddKassaWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Добавление кассы</p>
                    <div class="grid grid-cols-6 gap-4">
                        <TextInput ref="addKassaNameInput" v-model:textParam="addingKassaData.name" labelContent="Название кассы" class="col-span-2"/>
                        <TextInput ref="addKassaAddressInput" v-model:textParam="addingKassaData.address" labelContent="Адрес" class="col-span-2"/>
                        <div ref="selectionCityContainer" class="relative col-span-2">
                            <div @click="openCloseSelectionCity">
                                <label for="city" class="mb-1 block text-sm text-gray-500">Город (определение таймзоны)</label>
                                <button id="city" class="min-h-[38px] w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                                    <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ addingKassaData.city }}</span>
                                    <svg class="w-2.5 h-2.5 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                                    </svg>
                                </button>
                            </div>

                            <div v-show="selectionCityIsOpened" class="z-10 bg-white absolute rounded-lg shadow w-60 dark:bg-gray-700">
                                <div class="p-3">
                                    <label for="search-city" class="sr-only">Search</label>
                                    <div class="relative">
                                        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/></svg>
                                        </div>
                                        <input v-model="searchedCityValue" ref="addKassaCitySearch" id="search-city" type="text" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Поиск">
                                    </div>
                                </div>
                                <div @click.stop class="max-h-52 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                                    <RadioInput v-for="(timezone, city) in searchedCities" :key="city" @change="selectCity(city, timezone)" v-model:radioParam="addingKassaData.city" name="city" :value="city" :labelContent="city"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6">
                            <label class="mb-1 block text-sm text-gray-500">Доступные методы транзакции</label>
                            <div class="flex items-center justify-between gap-2">
                                <CheckboxInput ref="addKassaCashTransactionMethodCheckbox" @change="handleCheckTransactionMethod" v-model:checkboxParam="addingKassaData.cashTransactionMethodIsAvailable" labelContent="Наличные"/>
                                <CheckboxInput @change="handleCheckTransactionMethod" v-model:checkboxParam="addingKassaData.card2cardTransactionMethodIsAvailable" labelContent="Перевод на карту"/>
                                <CheckboxInput @change="handleCheckTransactionMethod" v-model:checkboxParam="addingKassaData.terminalTransactionMethodIsAvailable" labelContent="Терминал"/>
                                <CheckboxInput @change="handleCheckTransactionMethod" v-model:checkboxParam="addingKassaData.accountTransactionMethodIsAvailable" labelContent="На расчетный счет"/>
                            </div>
                        </div>
                        <div class="col-span-3">
                            <label for="edit-kassa-default-transaction-method" class="mb-1 block text-sm text-gray-500">Метод транзакции по умолчанию</label>
                            <select ref="addKassaDefaultTransactionMethodSelect" v-model="addingKassaData.defaultTransactionMethod" id="edit-kassa-default-transaction-method" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                <option v-if="addingKassaData.cashTransactionMethodIsAvailable" value="cash">Наличные</option>
                                <option v-if="addingKassaData.card2cardTransactionMethodIsAvailable" value="card2card">Перевод на карту</option>
                                <option v-if="addingKassaData.terminalTransactionMethodIsAvailable" value="terminal">Терминал</option>
                                <option v-if="addingKassaData.accountTransactionMethodIsAvailable" value="account">На расчетный счет</option>
                            </select>
                        </div>
                        <div class="col-span-3">
                            <label for="edit-kassa-default-currency" class="mb-1 block text-sm text-gray-500">Валюта по умолчанию</label>
                            <select ref="addKassaCurrencySelect" v-model="addingKassaData.defaultCurrency" id="edit-kassa-default-currency" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                            </select>
                        </div>
                        <div class="col-span-6">
                            <label for="edit-kassa-reference" class="mb-1 block text-sm text-gray-500">Справка для сотрудников</label>
                            <div class="mb-3">
                                <div ref="addKassaReference"></div>
                            </div>
                        </div>
                        <button @click.stop="goToSecondStep" type="button" class="mt-2 col-span-6 w-full text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Далее</button>
                    </div>
                </div>
            </div>
            <div v-show="secondStepIsOpened" ref="secondStep" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[80vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <div>
                    <p class="text-2xl text-center mb-8">Добавление товаров, услуг и операций в новую кассу</p>
                    <div class="flex items-center justify-between mb-4">
                        <button @click="goToFirstStep" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Назад</button>
                        <div class="relative w-[30rem]">
                            <input v-model="valueToSearchingCatalog" type="search" id="catalog-search" class="min-w-[25rem] block py-1 pl-4 pr-14 w-full z-20 text-gray-900 rounded-lg border border-custom-blue focus:ring-blue-500 focus:border-blue-500" placeholder="Поиск по названию, артикулу и комментарию" required />
                            <button type="button" class="absolute top-0 end-0 py-1 px-4 font-medium h-full text-white rounded-e-lg border border-custom-blue bg-custom-blue hover:bg-custom-blue-hover">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </div>
                        <button @click="addKassa" :disabled="buttonIsDisabled" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Добавить</button>
                    </div>
                    <div>
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-sm text-gray-700 bg-white">
                                <tr class="border-b border-gray-300">
                                    <th scope="col" class="p-2 w-[5%]">
                                        <CheckboxInput @change="toggleSelectingAllGoodInCatalog" v-model:checkboxParam="allGoodsSelected"/>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-[10%]">
                                        <span class="font-normal">Артикул</span>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-[20%]">
                                        <span class="font-normal">Название</span>
                                    </th>
                                    <th scope="col" class="px-4 py-3 w-0">
                                        <span class="font-normal">Характеристики</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr @click="selectGoodInCatalog(good)" v-for="good in searchedGoodsInCatalog" :key="good.id" class="border-b border-gray-300 font-normal text-black bg-white cursor-pointer">
                                    <td class="p-2">
                                        <CheckboxInput v-model:checkboxParam="good.selected"/>
                                    </td>
                                    <td class="px-4 py-2 whitespace-nowrap">
                                        <span>{{ good.verboseId }}</span>
                                    </td>
                                    <td class="px-4 py-2">
                                        <span>{{ good.name }}</span>
                                    </td>
                                    <td class="px-4 py-2 whitespace-nowrap overflow-x-scroll no-scrollbar">
                                        <span>{{ $globalMethods.formatCharacteristics(good.characteristics) }}</span>
                                    </td>
                                </tr>
                                <tr v-if="searchedGoodsInCatalog.length == 0">
                                    <td colspan="4" class="text-center text-lg p-2">Не найдено ни одной позиции в каталоге</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Quill from 'quill';
    import { mapState } from 'vuex';

    import TextInput from '@/components/InputComponents/TextInput.vue';
    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';
    import RadioInput from '@/components/InputComponents/RadioInput.vue';


    export default {
        name: "UpdateKassaWindow",
        components: {
            TextInput,
            CheckboxInput,
            RadioInput,
        },
        props: {
            addKassaWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            updatingKassaID: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                firstStepIsOpened: true,
                secondStepIsOpened: false,
                selectionCityIsOpened: false,

                addKassaReferenceEditor: null,
                addingKassaData: {
                    name: "",
                    address: "",
                    cashTransactionMethodIsAvailable: true,
                    card2cardTransactionMethodIsAvailable: true,
                    terminalTransactionMethodIsAvailable: true,
                    accountTransactionMethodIsAvailable: true,
                    defaultTransactionMethod: null,
                    defaultCurrency: "",
                    reference: "",
                    city: "",
                    timezone: "",
                },

                searchedCityValue: "",
                valueToSearchingCatalog: "",
                catalogGoods: [],
                allGoodsSelected: false,

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["companyData", "kassas", "currencies", "goods", "citiesTimezone"]),
            searchedGoodsInCatalog() {
                return this.catalogGoods.filter(good => good.verboseId.toLowerCase().includes(this.valueToSearchingCatalog.toLowerCase()) || good.name.toLowerCase().includes(this.valueToSearchingCatalog.toLowerCase()));
            },
            searchedCities() {
                return Object.fromEntries(Object.entries(this.citiesTimezone).filter(([city]) => city.toLowerCase().includes(this.searchedCityValue.toLocaleLowerCase())));
            },
        },
        methods: {
            selectGoodInCatalog(good) {
                good.selected = !good.selected;
            },
            toggleSelectingAllGoodInCatalog() {
                for (const element of this.catalogGoods) {
                    element.selected = this.allGoodsSelected;
                }
            },
            validateDataToAddKassa() {
                if (this.addingKassaData.name.length == 0) {
                    const addKassaNameInput = this.$refs.addKassaNameInput.$refs.textInput;
                    addKassaNameInput.setCustomValidity("Название кассы обязательное");
                    addKassaNameInput.reportValidity();
                    return false;
                }

                if (this.addingKassaData.address.length == 0) {
                    const addKassaAddressInput = this.$refs.addKassaAddressInput.$refs.textInput;
                    addKassaAddressInput.setCustomValidity("Адрес кассы обязательный");
                    addKassaAddressInput.reportValidity();
                    return false;
                }
                if (this.addingKassaData.city.length == 0 || this.addingKassaData.timezone.length == 0) {
                    this.selectionCityIsOpened = true;
                    const addKassaCitySearch = this.$refs.addKassaCitySearch;
                    addKassaCitySearch.setCustomValidity("Выберите город для определения временной зоны кассы");
                    addKassaCitySearch.reportValidity();
                    return false;
                }

                if (!this.addingKassaData.cashTransactionMethodIsAvailable && !this.addingKassaData.card2cardTransactionMethodIsAvailable && !this.addingKassaData.terminalTransactionMethodIsAvailable && !this.addingKassaData.accountTransactionMethodIsAvailable) {
                    const addKassaCashTransactionMethodCheckbox = this.$refs.addKassaCashTransactionMethodCheckbox.$refs.checkboxInput;
                    addKassaCashTransactionMethodCheckbox.setCustomValidity("Выберите как минимум один метод транзакции");
                    addKassaCashTransactionMethodCheckbox.reportValidity();
                    return false;
                }

                if (!this.addingKassaData.defaultTransactionMethod) {
                    const addKassaDefaultTransactionMethodSelect = this.$refs.addKassaDefaultTransactionMethodSelect;
                    addKassaDefaultTransactionMethodSelect.setCustomValidity("Выберите метод транзакции по умолчанию");
                    addKassaDefaultTransactionMethodSelect.reportValidity();
                    return false;
                }
                if (!this.addingKassaData.defaultCurrency) {
                    const addKassaCurrencySelect = this.$refs.addKassaCurrencySelect;
                    addKassaCurrencySelect.setCustomValidity("Выберите валюту по умолчанию");
                    addKassaCurrencySelect.reportValidity();
                    return false;
                }

                return true;
            },
            async addKassa() {
                this.buttonIsDisabled = true;
                const selectedGoods = this.catalogGoods.filter(good => good.selected === true).map(good => good.id);
                const result = await this.$store.dispatch("createNewKassa", {
                    shopId: this.updatingKassaID,
                    name: this.addingKassaData.name,
                    city: this.addingKassaData.city,
                    timezone: this.addingKassaData.timezone,
                    address: this.addingKassaData.address,
                    cashPayment: this.addingKassaData.cashTransactionMethodIsAvailable,
                    card2cardPayment: this.addingKassaData.card2cardTransactionMethodIsAvailable,
                    terminalPayment: this.addingKassaData.terminalTransactionMethodIsAvailable,
                    accountPayment: this.addingKassaData.accountTransactionMethodIsAvailable,
                    defaultCurrency: this.addingKassaData.defaultCurrency,
                    defaultPayment: this.addingKassaData.defaultTransactionMethod,
                    reference: this.addingKassaData.reference,
                    selectedGoods: selectedGoods,
                })
                if (result.successfully) {
                    this.$notify("Касса успешно добавлена");
                    this.closeAddKassaWindow();
                }
                this.buttonIsDisabled = false;
            },
            goToSecondStep() {
                if (!this.validateDataToAddKassa()) {
                    return;
                }
                this.firstStepIsOpened = false;
                this.secondStepIsOpened = true;
            },
            goToFirstStep() {
                this.firstStepIsOpened = true;
                this.secondStepIsOpened = false;
            },
            handleCheckTransactionMethod() {
                this.addingKassaData.defaultTransactionMethod = null;
            },
            selectCity(city, timezone) {
                this.addingKassaData.city = city;
                this.addingKassaData.timezone = timezone;
                this.selectionCityIsOpened = false;
                this.searchedCityValue = "";
            },
            openCloseSelectionCity() {
                this.selectionCityIsOpened = !this.selectionCityIsOpened;
            },  
            initEditKassaReferenceEditor() {
                const toolbarOptions = [
                    [{ 'header': [1, 2, 3, false] }],
                    [{ 'align': [] }],
                    ['blockquote', 'link'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['bold', 'italic', 'underline'],
                    ['image'],
                ];

                this.addKassaReferenceEditor = new Quill(this.$refs.addKassaReference, {
                    theme: 'snow',
                    modules: {
                        toolbar: toolbarOptions,
                    }
                });
                this.addKassaReferenceEditor.on('text-change', () => {
                    this.addingKassaData.reference = this.addKassaReferenceEditor.root.innerHTML;
                });
                this.addKassaReferenceEditor.root.innerHTML = this.addingKassaData.reference;
            },
            addAutoCloseSelectionCity() {
                document.body.addEventListener("click", (event) => {
                    const selectionCityContainer = this.$refs.selectionCityContainer;
                    if (selectionCityContainer && selectionCityContainer.contains(event.target)) {
                        return
                    }
                    this.selectionCityIsOpened = false;
                })
            },
            closeAddKassaWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addKassaWindowIsOpened", false);
            },
            openAddKassaWindow() {
                this.addingKassaData.defaultCurrency = this.companyData.mainCurrency;
                this.catalogGoods = structuredClone(this.goods);
            },
        },
        created() {
            this.openAddKassaWindow();
        },
        mounted() {
            this.initEditKassaReferenceEditor();
            this.addAutoCloseSelectionCity();
        },
        beforeUnmount() {
            if (this.addKassaReferenceEditor) {
                this.addKassaReferenceEditor = null;
            }
        },
    }
</script>