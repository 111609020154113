import Decimal from 'decimal.js'; 

const phoneMasks = {
    "+7": { country: "RU", mask: "## (###) ###-##-##" },
    "+1": { country: "US", mask: "## (###) ###-####" },
    "+375": { country: "BY", mask: "#### (##) ###-##-##" },
    "+998": { country: "UZ", mask: "#### (##) ###-##-##" },
    "+86": { country: "CN", mask: "### (###) #### ####" },
    "+994": { country: "AZ", mask: "#### ## ### ## ##" },
    "+90": { country: "TR", mask: "### (###) ### ## ##" },
    "+995": { country: "GE", mask: "#### (###) ## ## ##" },
    "+66": { country: "TH", mask: "### ## ### ####" }
};
const patternRussian = /\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/;
const patternUsa = /^\+1\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const patternBelorussian = /^\+375\s?\(?\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/;
const patternUzbekistan = /^\+998\s?\(?\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/;
const patternChina = /^\+86\s?\d{3}[\s.-]?\d{4}[\s.-]?\d{4}$/;
const patternAzerbaijan = /^\+994\s?\d{2}[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/;
const patternTurkish = /^\+90\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/;
const patternGeorgia = /^\+995\s?\(?\d{3}\)?[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}$/;
const patternThailand = /^\+66\s?\d{2}[\s.-]?\d{3}[\s.-]?\d{4}$/;

export default {
    getAbsoluteUrl(url) {
        return `${process.env.BASE_URL}${url}`;
    },
    formatDate(languageCode, dateString) {
        const date = new Date(dateString);
        
        if (languageCode == "en") {
            const formattedEn = `${new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)} ${date.getDate()} ${date.getFullYear()} at ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
            return formattedEn;
        } else if (languageCode == "ru") {
            const optionsRu = { year: 'numeric', month: 'long', day: 'numeric' };
            const formattedRu = `${date.toLocaleDateString('ru-RU', optionsRu)}`;
            return formattedRu;
        }
    },
    formatDateTime(dateString) {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate();
        const year = date.getFullYear();
    
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];    
        const month = months[date.getMonth()];    
        return `${hours}:${minutes} ${day} ${month} ${year} г`;
    },
    dropTimezoneFromDateTime(dateString) {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    isDifferenceWithinOneYear(date1, date2) {
        const [earlierDate, laterDate] = date1 < date2 ? [date1, date2] : [date2, date1];        
        const yearDifference = laterDate.getFullYear() - earlierDate.getFullYear();
        const monthDifference = laterDate.getMonth() - earlierDate.getMonth();
        const dayDifference = laterDate.getDate() - earlierDate.getDate();
      
        if (yearDifference < 1) {
          return true;
        }
        
        if (yearDifference === 1) {
          if (monthDifference < 0 || (monthDifference === 0 && dayDifference <= 0)) {
            return true;
          }
        }        
        return false;
    },
    isToday(dateString) {
        const inputDate = new Date(dateString);
        inputDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return inputDate.getTime() === today.getTime();
    },
    isYesterday(dateString) {
        const inputDate = new Date(dateString);
        inputDate.setHours(0, 0, 0, 0);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0); 
        return inputDate.getTime() === yesterday.getTime();
    },
    isStartAndEndOfMonth(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
    
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();
    
        const isStartOfMonth = startDate.getDate() === 1;
    
        const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        const isEndOfMonth = endDate.getDate() === lastDay;
    
        return sameMonth && sameYear && isStartOfMonth && isEndOfMonth;
    },
    isStartAndEndOfYear(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
    
        const sameYear = startDate.getFullYear() === endDate.getFullYear();    
        const isStartOfYear = startDate.getMonth() === 0 && startDate.getDate() === 1;    
        const isEndOfYear = endDate.getMonth() === 11 && endDate.getDate() === 31;
    
        return sameYear && isStartOfYear && isEndOfYear;
    },
    getTime(dateString) {
        const formattedDateStr = dateString.replace('T', ' ').replace('Z', '');
        const date = new Date(formattedDateStr);
        return date.toTimeString().split(' ')[0];
    },
    getCurrentDate() {
        const now = new Date();
        
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    },
    getCurrentMonthPeriod() {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); 
        const formattedStart = `${startOfMonth.getFullYear()}-${String(startOfMonth.getMonth() + 1).padStart(2, '0')}-${String(startOfMonth.getDate()).padStart(2, '0')}`;
        const formattedEnd = `${endOfMonth.getFullYear()}-${String(endOfMonth.getMonth() + 1).padStart(2, '0')}-${String(endOfMonth.getDate()).padStart(2, '0')}`;
    
        return { start: formattedStart, end: formattedEnd };
    },
    getMonthAndYear(dateString) {
        const date = new Date(dateString);
    
        const formatter = new Intl.DateTimeFormat('ru-RU', { 
            year: 'numeric', 
            month: 'long' 
        });
    
        return this.capitalizeFirstLetter(formatter.format(date));
    },
    getCurrentMonthAndYear() {
        const today = new Date();
        const monthName = new Intl.DateTimeFormat('ru-RU', { year: "numeric", month: 'long' }).format(today);
        return this.capitalizeFirstLetter(monthName);
    },
    getCurrentYearPeriod() {
        const today = new Date();    
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const endOfYear = new Date(today.getFullYear(), 11, 31);
        const formattedStart = `${startOfYear.getFullYear()}-${String(startOfYear.getMonth() + 1).padStart(2, '0')}-${String(startOfYear.getDate()).padStart(2, '0')}`;
        const formattedEnd = `${endOfYear.getFullYear()}-${String(endOfYear.getMonth() + 1).padStart(2, '0')}-${String(endOfYear.getDate()).padStart(2, '0')}`;
    
        return { start: formattedStart, end: formattedEnd };
    },
    getYear(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()} год`;
    },
    getCurrentYear() {
        const today = new Date(); 
        return `${today.getFullYear()} год`;
    },
    getCurrentDateTime() {
        const now = new Date();
        
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getPreviousDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 1);
        return date.toISOString().split('T')[0];
    },
    getNextsDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toISOString().split('T')[0];
    },
    getFirstDayOfPreviousMonth(dateString) {
        const date = new Date(dateString);    
        const firstDayPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);    
        const year = firstDayPreviousMonth.getFullYear();
        const month = String(firstDayPreviousMonth.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousMonth.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfPreviousMonth(dateString) {
        const date = new Date(dateString);
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const lastDay = new Date(year, date.getMonth() + 1, 0).getDate();

        return `${year}-${month}-${lastDay}`;
    },
    getFirstDayOfNextMonth(dateString) {
        const date = new Date(dateString);    
        const firstDayPreviousMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);    
        const year = firstDayPreviousMonth.getFullYear();
        const month = String(firstDayPreviousMonth.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousMonth.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfNextMonth(dateString) {
        const date = new Date(dateString);
        date.setDate(1);
        date.setMonth(date.getMonth() + 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const lastDay = new Date(year, date.getMonth() + 1, 0).getDate();

        return `${year}-${month}-${lastDay}`;
    },
    getPreviousYear(dateString) {
        const date = new Date(dateString);
        date.setFullYear(date.getFullYear() - 1);
        return date.toISOString().split('T')[0];
    },
    getNextYear(dateString) {
        const date = new Date(dateString);
        date.setFullYear(date.getFullYear() + 1);
        return date.toISOString().split('T')[0];
    },
    getFirstDayOfPreviousYear(dateString) {
        const date = new Date(dateString);
        const firstDayPreviousYear = new Date(date.getFullYear() - 1, 0, 1);    
        const year = firstDayPreviousYear.getFullYear();
        const month = String(firstDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfPreviousYear(dateString) {
        const date = new Date(dateString);    
        const lastDayPreviousYear = new Date(date.getFullYear() - 1, 11, 31);
    
        const year = lastDayPreviousYear.getFullYear();
        const month = String(lastDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(lastDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getFirstDayOfNextYear(dateString) {
        const date = new Date(dateString);
        const firstDayPreviousYear = new Date(date.getFullYear() + 1, 0, 1);    
        const year = firstDayPreviousYear.getFullYear();
        const month = String(firstDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(firstDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    getLastDayOfNextYear(dateString) {
        const date = new Date(dateString);    
        const lastDayPreviousYear = new Date(date.getFullYear() + 1, 11, 31);
    
        const year = lastDayPreviousYear.getFullYear();
        const month = String(lastDayPreviousYear.getMonth() + 1).padStart(2, '0');
        const day = String(lastDayPreviousYear.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    },
    capitalizeFirstLetter(str) {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getDeviceType() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            return "phone";
        } else if (screenWidth >= 768 && screenWidth < 1024) {
            return "tablet";
        } else {
            return "laptop";
        }
    },
    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
        if (/android/i.test(userAgent)) {
          return "Android";
        }   
        return "unknown";
    },
    formatNumberWithSpaces(number) {
        if (number === null || number == undefined) {
            return "";
        }
        return String(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    isNumeric(value) {
        return /^-?\d+(\.\d+)?$/.test(value);
    },
    isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    },
    formatCharacteristics(characteristics) {
        const result = Object.entries(characteristics)
                             .filter(([key, value]) => key && value !== null && value !== undefined && value !== "")
                             .map(([key, value]) => `${key}: ${value}`).join(" | ");
        return result;
    },
    setDataToLocalStorage(property, data) {
        localStorage.setItem(property, data);
    },
    getDataFromLocalStorage(property) {
        return localStorage.getItem(property);
    },
    deleteDataFromLocalStorage(property) {
        localStorage.removeItem(property);
    },
    discountedPrice(priceWithoutDiscount, discount) {
        if (!priceWithoutDiscount || !discount) {
            return priceWithoutDiscount;
        }
        const price = new Decimal(priceWithoutDiscount);
        const discountValue = new Decimal(discount).div(100);
        const finalPrice = price.minus(price.mul(discountValue));
        return finalPrice.toFixed(2);
    },
    applyMask(phoneNumber, mask) {
        let maskedNumber = "";
        let digitIndex = 0;
  
        for (let i = 0; i < mask.length; i++) {
            const maskChar = mask[i];

            if (maskChar === "#") {
                if (digitIndex < phoneNumber.length) {
                    maskedNumber += phoneNumber[digitIndex];
                    digitIndex++;
                } else {
                    break;
                }
            } else {
                if (digitIndex < phoneNumber.length) {
                    maskedNumber += maskChar;
                } else {
                    break
                }
            }
        }
        return maskedNumber;
    },
    getCountryByPrefix(phoneNumber) {
        const prefixes = Object.keys(phoneMasks).sort((a, b) => b.length - a.length);
      
        for (let prefix of prefixes) {
          if (phoneNumber.startsWith(prefix)) {
            return phoneMasks[prefix];
          }
        }
        return null;
    },
    validatePhone(phoneNumber) {
        const result = patternRussian.test(phoneNumber) ||
                       patternUsa.test(phoneNumber) ||
                       patternBelorussian.test(phoneNumber) ||
                       patternUzbekistan.test(phoneNumber) ||
                       patternChina.test(phoneNumber) ||
                       patternAzerbaijan.test(phoneNumber) ||
                       patternTurkish.test(phoneNumber) ||
                       patternGeorgia.test(phoneNumber) ||
                       patternThailand.test(phoneNumber)
        return result
    },
    parseNumber(value) {
        if (typeof value == "number") {
            return value
        }
        const number = String(value).replace(/\s+/g, '').replace(/^0+/, '');
        return Number(number) || 0;
    },
};