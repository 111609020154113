<template>
    <MainHeaderComponent/>
</template>

<script>
    import MainHeaderComponent from '@/components/laptop/MainHeaderComponent.vue';

    export default {
        name: "PersonalAccountView",
        components: {
            MainHeaderComponent
        },
        data() {
            return {

            }
        },
        mounted() {

        },
    }
</script>