<template>
    <label :for="uniqueId" class="flex items-center gap-2 cursor-pointer">
        <input @change="handleChange" v-model="param" ref="checkboxInput" type="checkbox" :name="uniqueId" :id="uniqueId" class="cursor-pointer rounded">
        <span v-if="labelContent" class="text-sm text-gray-900">{{ labelContent }}</span>
    </label>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "CheckboxInput",
        props: {
            checkboxParam: {
                type: [Boolean, null],
                required: false,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        computed: {
            param: {
                get() {
                    return this.checkboxParam;
                },
                set(value) {
                    this.$emit("update:checkboxParam", value);
                }
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
            }
        },
        methods: {
            handleChange() {
                this.$emit("change");
            },
        }
    }
</script>