<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div ref="referenceWindowContent" class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[60vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeUpdateKassaWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Редактирование кассы</p>
                    <div class="grid grid-cols-2 gap-6">
                        <TextInput ref="editKassaNameInput" v-model:textParam="updatingKassaData.name" labelContent="Название кассы"/>
                        <TextInput ref="editKassaAddressInput" v-model:textParam="updatingKassaData.address" labelContent="Адрес"/>
                        <div class="col-span-2">
                            <label class="mb-1 block text-sm text-gray-500">Доступные методы транзакции</label>
                            <div class="flex items-center justify-between gap-2">
                                <CheckboxInput ref="editKassaCashTransactionMethodCheckbox" v-model:checkboxParam="updatingKassaData.cashTransactionMethodIsAvailable" labelContent="Наличные"/>
                                <CheckboxInput v-model:checkboxParam="updatingKassaData.card2cardTransactionMethodIsAvailable" labelContent="Перевод на карту"/>
                                <CheckboxInput v-model:checkboxParam="updatingKassaData.terminalTransactionMethodIsAvailable" labelContent="Терминал"/>
                                <CheckboxInput v-model:checkboxParam="updatingKassaData.accountTransactionMethodIsAvailable" labelContent="На расчетный счет"/>
                            </div>
                        </div>
                        <div>
                            <label for="edit-kassa-default-transaction-method" class="mb-1 block text-sm text-gray-500">Метод транзакции по умолчанию</label>
                            <select ref="editKassaDefaultTransactionMethodSelect" v-model="updatingKassaData.defaultTransactionMethod" id="edit-kassa-default-transaction-method" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                <option v-if="updatingKassaData.cashTransactionMethodIsAvailable" value="cash">Наличные</option>
                                <option v-if="updatingKassaData.card2cardTransactionMethodIsAvailable" value="card2card">Перевод на карту</option>
                                <option v-if="updatingKassaData.terminalTransactionMethodIsAvailable" value="terminal">Терминал</option>
                                <option v-if="updatingKassaData.accountTransactionMethodIsAvailable" value="account">На расчетный счет</option>
                            </select>
                        </div>
                        <div>
                            <label for="edit-kassa-default-currency" class="mb-1 block text-sm text-gray-500">Валюта по умолчанию</label>
                            <select ref="editKassaCurrencySelect" v-model="updatingKassaData.defaultCurrency" id="edit-kassa-default-currency" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                            </select>
                        </div>
                        <div class="col-span-2">
                            <label for="edit-kassa-reference" class="mb-1 block text-sm text-gray-500">Справка для сотрудников</label>
                            <div class="mb-3">
                                <div ref="editKassaReference"></div>
                            </div>
                        </div>
                        <button @click="updateKassa" :disabled="buttonIsDisabled" type="button" class="mt-2 col-span-2 w-full text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Quill from 'quill';
    import { mapState } from 'vuex';

    import TextInput from '@/components/InputComponents/TextInput.vue';
    import CheckboxInput from '@/components/InputComponents/CheckboxInput.vue';

    export default {
        name: "UpdateKassaWindow",
        components: {
            TextInput,
            CheckboxInput,
        },
        props: {
            updateKassaWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            updatingKassaID: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                editKassaReferenceEditor: null,
                updatingKassaData: {
                    name: "",
                    address: "",
                    cashTransactionMethodIsAvailable: true,
                    card2cardTransactionMethodIsAvailable: true,
                    terminalTransactionMethodIsAvailable: true,
                    accountTransactionMethodIsAvailable: true,
                    defaultTransactionMethod: null,
                    defaultCurrency: "",
                    reference: "",
                    buttonIsDisabled: false,
                }
            }
        },
        computed: {
            ...mapState(["kassas", "currencies"]),
        },
        methods: {
            validateDataToUpdateKassa() {
                if (this.updatingKassaData.name.length == 0) {
                    const editKassaNameInput = this.$refs.editKassaNameInput.$refs.textInput;
                    editKassaNameInput.setCustomValidity("Название кассы обязательное");
                    editKassaNameInput.reportValidity();
                    return false;
                }

                if (this.updatingKassaData.address.length == 0) {
                    const editKassaAddressInput = this.$refs.editKassaAddressInput.$refs.textInput;
                    editKassaAddressInput.setCustomValidity("Адрес кассы обязательный");
                    editKassaAddressInput.reportValidity();
                    return false;
                }
                if (!this.updatingKassaData.cashTransactionMethodIsAvailable && !this.updatingKassaData.card2cardTransactionMethodIsAvailable && !this.updatingKassaData.terminalTransactionMethodIsAvailable && !this.updatingKassaData.accountTransactionMethodIsAvailable) {
                    const editKassaCashTransactionMethodCheckbox = this.$refs.editKassaCashTransactionMethodCheckbox.$refs.checkboxInput;
                    editKassaCashTransactionMethodCheckbox.setCustomValidity("Выберите как минимум один метод транзакции");
                    editKassaCashTransactionMethodCheckbox.reportValidity();
                    return false;
                }

                if (!this.updatingKassaData.defaultTransactionMethod) {
                    const editKassaDefaultTransactionMethodSelect = this.$refs.editKassaDefaultTransactionMethodSelect;
                    editKassaDefaultTransactionMethodSelect.setCustomValidity("Выберите метод транзакции по умолчанию");
                    editKassaDefaultTransactionMethodSelect.reportValidity();
                    return false;
                }
                if (!this.updatingKassaData.defaultCurrency) {
                    const editKassaCurrencySelect = this.$refs.editKassaCurrencySelect;
                    editKassaCurrencySelect.setCustomValidity("Выберите валюту по умолчанию");
                    editKassaCurrencySelect.reportValidity();
                    return false;
                }

                return true;
            },
            async updateKassa() {
                if (!this.validateDataToUpdateKassa()) {
                    return;
                }
                this.buttonIsDisabled = true;
                const result = await this.$store.dispatch("updateKassa", {
                    shopId: this.updatingKassaID,
                    name: this.updatingKassaData.name,
                    address: this.updatingKassaData.address,
                    cashPayment: this.updatingKassaData.cashTransactionMethodIsAvailable,
                    card2cardPayment: this.updatingKassaData.card2cardTransactionMethodIsAvailable,
                    terminalPayment: this.updatingKassaData.terminalTransactionMethodIsAvailable,
                    accountPayment: this.updatingKassaData.accountTransactionMethodIsAvailable,
                    defaultCurrency: this.updatingKassaData.defaultCurrency,
                    defaultPayment: this.updatingKassaData.defaultTransactionMethod,
                    reference: this.updatingKassaData.reference,
                })
                if (result.successfully) {
                    this.$notify("Данные кассы успешно обновлены");
                    this.closeUpdateKassaWindow();
                }
                this.buttonIsDisabled = false;
            },
            handleCheckTransactionMethod() {
                this.updatingKassaData.defaultTransactionMethod = null;
            },
            initEditKassaReferenceEditor() {
                const toolbarOptions = [
                    [{ 'header': [1, 2, 3, false] }],
                    [{ 'align': [] }],
                    ['blockquote', 'link'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['bold', 'italic', 'underline'],
                    ['image'],
                ];

                this.editKassaReferenceEditor = new Quill(this.$refs.editKassaReference, {
                    theme: 'snow',
                    modules: {
                        toolbar: toolbarOptions,
                    }
                });
                this.editKassaReferenceEditor.on('text-change', () => {
                    this.updatingKassaData.reference = this.editKassaReferenceEditor.root.innerHTML;
                });
                this.editKassaReferenceEditor.root.innerHTML = this.updatingKassaData.reference;
            },
            closeUpdateKassaWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:updateKassaWindowIsOpened", false);
            },
            openUpdateKassaWindow() {
                const kassa = this.kassas.find(kassa => kassa.id == this.updatingKassaID);

                this.updatingKassaData.name = kassa.name;
                this.updatingKassaData.address = kassa.address;
                this.updatingKassaData.cashTransactionMethodIsAvailable = kassa.cashPayment;
                this.updatingKassaData.card2cardTransactionMethodIsAvailable = kassa.card2cardPayment;
                this.updatingKassaData.terminalTransactionMethodIsAvailable = kassa.terminalPayment;
                this.updatingKassaData.accountTransactionMethodIsAvailable = kassa.accountPayment;
                this.updatingKassaData.defaultTransactionMethod = kassa.defaultPayment;
                this.updatingKassaData.defaultCurrency = kassa.defaultCurrency;
                this.updatingKassaData.reference = kassa.reference;
            },
        },
        created() {
            this.openUpdateKassaWindow();
        },
        mounted() {
            this.initEditKassaReferenceEditor();
        },
        beforeUnmount() {
            if (this.editKassaReferenceEditor) {
                this.editKassaReferenceEditor = null;
            }
        },
    }
</script>