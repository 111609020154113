<template>
    <MainHeaderComponent :activePage="activePage"/>
    <ClientsList/>
</template>


<script>
    import MainHeaderComponent from '@/components/laptop/MainHeaderComponent.vue';
    import ClientsList from '@/components/laptop/Client/ClientsList.vue';

    export default {
        name: "ClientsAndDiscountsView",
        components: {
            MainHeaderComponent,
            ClientsList,
        },
        data() {
            return {
                activePage: "clients",
            }
        },
        computed: {

        },
        methods: {
            async loadData() {

            },
        },
        async mounted() {
            await this.loadData();
        }
    };
</script>