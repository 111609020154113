import { createApp } from 'vue';

import MainNotification from '@/components/laptop/MainNotification.vue'

export default {
  install(app, store) {
    const notificationApp = createApp(MainNotification);
    const notificationInstance = notificationApp.mount(document.createElement('div'));

    document.body.appendChild(notificationInstance.$el);

    app.config.globalProperties.$notify = (message, color="black", duration=3000) => {
      notificationInstance.show(message, color, duration);
    };

    store.$notify = app.config.globalProperties.$notify;
  },
};