<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-10"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-20">
            <div class="relative flex flex-col justify-between bg-white z-50 max-h-[95vh] w-[35vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeDownloadTransactionsCSVWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div>
                    <p class="text-2xl mb-8">Выгрузка CSV</p>
                    <div class="">
                        <div class="mb-6">
                            <label for="select-period-from" class="mb-1 block text-sm text-gray-500">Период выгрузки</label>
                            <div class="flex items-center justify-between">
                                <input v-model="dateFrom" ref="dateFromInput" id="select-period-from" type="date" class="w-auto text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                                <span>-</span>
                                <input v-model="dateTo" ref="dateToInput" id="select-period-to" type="date" class="w-auto text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
                            </div>
                        </div>
                        <div class="mb-6">
                            <label class="mb-1 block text-sm text-gray-500">Операционная система</label>
                            <div class="grid grid-cols-2 gap-4">
                                <RadioInput v-model:radioParam="os" name="os" value="windows" labelContent="Windows"/>
                                <RadioInput v-model:radioParam="os" name="os" value="macos" labelContent="Linux и MacOS"/>
                            </div>
                        </div>
                        <button @click="downloadCSV" :disabled="buttonIsDisabled" type="button" class="mt-2 col-span-2 w-full text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Выгрузить данные</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import RadioInput from '@/components/InputComponents/RadioInput.vue';


    export default {
        name: "DownloadTransactionsCSVModalWindowComponent",
        components: {
            RadioInput,
        },
        props: {
            downloadTransactionsCSVWindowIsOpened: {
                type: Boolean,
                required: true,
            },
            downloadingKassaID: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                os: "windows",
                dateFrom: this.$globalMethods.getCurrentDate(),
                dateTo: this.$globalMethods.getCurrentDate(),
                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["kassas", "currencies"]),
        },
        methods: {
            validateDatePeriod() {
                const dateFromInput = this.$refs.dateFromInput;
                
                const isPeriodWithinOneYear = this.$globalMethods.isDifferenceWithinOneYear(new Date(this.dateFrom), new Date(this.dateTo));
                if (!isPeriodWithinOneYear) {
                    dateFromInput.setCustomValidity('Период не может превышать 1 год');
                    dateFromInput.reportValidity();
                    return false;
                }

                if (this.dateFrom && this.dateTo && (new Date(this.dateFrom) > new Date(this.dateTo))) {
                    dateFromInput.setCustomValidity('Дата начала не может превышать дату конца');
                    dateFromInput.reportValidity();
                    return false;
                }

                if (!this.dateFrom && !this.dateTo) {
                    dateFromInput.setCustomValidity('Укажите период');
                    dateFromInput.reportValidity();
                    return false;
                }

                return true;
            },
            async downloadCSV() {
                if (!this.validateDatePeriod()) {
                    return;
                }
                this.buttonIsDisabled = true;
                const result = await this.$store.dispatch("fetchCSVTransactions", {
                    shopId: this.downloadingKassaID,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    os: this.os,
                });
                if (result.successfully) {
                    this.closeDownloadTransactionsCSVWindow();
                }
                this.buttonIsDisabled = false;
            },  
            closeDownloadTransactionsCSVWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:downloadTransactionsCSVWindowIsOpened", false);
            },
        },
        created() {

        },
        mounted() {

        },
        beforeUnmount() {

        },
    }
</script>