<template>
    <label class="flex items-center rounded cursor-pointer">
        <input ref="radioInput" v-model="param" @change="handleChange" type="radio" :name="name" :value="value" class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 rounded-full">
        <span v-if="labelContent" class="w-full cursor-pointer py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{ labelContent }}</span>
        <p v-else class="w-full cursor-pointer py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
            <slot></slot>
        </p>
    </label>
</template>


<script>
    export default {
        name: "RadioInput",
        props: {
            radioParam: {
                type: [String, Number, null],
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            value: {
                type: [String, Number],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        computed: {
            param: {
                get() {
                    return this.radioParam;
                },
                set(value) {
                    this.$emit("update:radioParam", value);
                }
            },
        },
        data() {
            return {

            }
        },
        methods: {
            handleChange() {
                this.$emit("change");
            }
        }
    }
</script>