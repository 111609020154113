<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <input v-model="amountValue" ref="amountInput" :readonly="readOnly" :disabled="readOnly" :id="uniqueId" type="text" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4">
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "PriceInput",
        props: {
            amountParam: {
                type: [String, Number],
                required: true,
            },
            maxValue: {
                type: [String, Number],
                required: false,
            },
            labelContent: {
                type: String,
                default: "",
            },
            readOnly: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            amountValue: {
                get() {
                    return this.amountParam;
                },
                set(value) {
                    if (this.readOnly) {
                        return
                    }
                    let correctedValue = value.replace(/\D/g, "");
                    if (correctedValue.startsWith("0")) {
                        correctedValue = "0"
                    }
                    if (correctedValue && this.maxValue != null && Number(correctedValue) > Number(this.maxValue)) {
                        correctedValue = this.maxValue;
                    }
                    this.$refs.amountInput.value = correctedValue;
                    this.$emit("update:amountParam", correctedValue);
                    this.$emit("input")
                },
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
            }
        },
    }
</script>