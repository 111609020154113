import { createStore } from 'vuex'

import globalMethods from '../globalMethods.js';

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;
const domain = `${protocol}//${hostname}${port ? ':' + port : ''}`;
// const domain = "http://localhost:8000";

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}


export default createStore({
  state: {
    domain: domain,
    URLs: {
      api: {
          auth: {
              signInUrl: `${domain}/api/auth/sign-in/`,
              signOutUrl: `${domain}/api/auth/sign-out/`,
              signUpUrl: `${domain}/api/auth/sign-up/`,
              deleteAccountUrl: `${domain}/api/auth/delete/`,
              resetPasswordStartUrl: `${domain}/api/auth/change/password/start/`,
              resetPasswordFinalUrl: `${domain}/api/auth/change/password/final/`,
              changeEmailStartUrl: `${domain}/api/auth/change/email/start/`,
              changeEmailContinueUrl: `${domain}/api/auth/change/email/continue/`,
              changeEmailFinalUrl: `${domain}/api/auth/change/email/final/`,
              changePhoneStartUrl: `${domain}/api/auth/change/phone/start/`,
              changePhoneFinalUrl: `${domain}/api/auth/change/phone/final/`,
              confirmationAccountUrl: `${domain}/api/auth/confirm/`,
              checkAuthenticationUrl: `${domain}/api/auth/check/authentication/`,
              checkAccessUrl: `${domain}/api/auth/check/access/`,
              checkTemporaryTokenUrl: `${domain}/api/auth/check/temporary-token/`,
              checkSuperUserUrl: `${domain}/api/auth/check/superuser/`,
              getCsrfToken: `${domain}/api/auth/get-csrf-token/`,
          },
          log: {
              sendFeedbackUrl: `${domain}/api/log/send-feedback/`,
              sendRequestToIntegrationUrl: `${domain}/api/log/send-request-to-integration/`,
          },
          pay: {
              createPaymentUrl: `${domain}/api/pay/create-payment-url/`,
              createPaymentAddShopsUrl: `${domain}/api/pay/create-payment-url-for-additional-shops/`,
          },
          model: {
              account: {
                  personalDataUrl: `${domain}/api/model/personal/data/`,
                  personalStaffsUrl: `${domain}/api/model/personal/staffs/`,
                  pushSubscriptionUrl: `${domain}/api/model/push-subscription/`,
              },
              company: {
                  companyLogoUrl: `${domain}/api/model/company/logo/`,
                  companyUrl: `${domain}/api/model/company/data/`,
                  companiesUrl: `${domain}/api/model/companies/`,
                  characteristicsUrl: `${domain}/api/model/company/characteristics/`,
                  companyDiscountPolicy: `${domain}/api/model/company/discount-policy/`,
                  companyDisplayedColumnsUrl: `${domain}/api/model/company/displayed-columns/`,
              },
              shops: {
                  shopsUrl: `${domain}/api/model/shops/`,
                  shopUrl: `${domain}/api/model/shop/<ID>/`,
                  shopReferenceUrl: `${domain}/api/model/shop/<ID>/reference/`,
                  pointsDistributionUrl: `${domain}/api/model/shops/points-distribution/`,
              },
              goods: {
                  goodsUrl: `${domain}/api/model/goods/`,
                  goodUrl: `${domain}/api/model/good/<ID>/`,
              },
              clients: {
                  clientsUrl: `${domain}/api/model/clients/`,
                  clientUrl: `${domain}/api/model/client/<ID>/`,
                  clientHistoryUrl: `${domain}/api/model/client/<ID>/history/`,
              },
              csv: {
                  importFromCSVUrl: `${domain}/api/model/csv/`,
              },
              tasks: {
                  tasksUrl: `${domain}/api/model/tasks/`,
                  taskUrl: `${domain}/api/model/task/<ID>/`,
              },
              staffs: {
                  staffsUrl: `${domain}/api/model/staffs/`,
                  staffUrl: `${domain}/api/model/staff/<ID>/`,
              },
              supplyings: {
                  supplyingsUrl: `${domain}/api/model/supplyings/`,
                  supplyingUrl: `${domain}/api/model/supplying/<ID>/`,
              },
              movings: {
                  movingsUrl: `${domain}/api/model/movings/`,
                  movingUrl: `${domain}/api/model/moving/<ID>/`,
              },
              movingsAndSupplyings: {
                  movingsAndSupplyingsUrl: `${domain}/api/model/movings-and-supplyings/`,
              },
              sales: {
                  salesUrl: `${domain}/api/model/sales/`,
                  saleUrl: `${domain}/api/model/sale/<ID>/`,
              },
              metric: {
                  userStatisticUrl: `${domain}/api/model/metric/user-statistics/`,
                  paymentUrl: `${domain}/api/model/metric/payments/`,
              },
              salaryReports: {
                  salaryReportsUrl: `${domain}/api/model/salary-reports/`,
                  salaryReportUrl: `${domain}/api/model/salary-report/<ID>/`,
              },
              payouts: {
                  payoutUrl: `${domain}/api/model/payout/<ID>/`,
                  payoutsUrl: `${domain}/api/model/payouts/`,
                  targetPeriodsUrl: `${domain}/api/model/payouts/target-periods/`,
              },
              schedules: {
                  schedulesUrl: `${domain}/api/model/schedules/`,
                  scheduleUrl: `${domain}/api/model/schedule/<ID>/`,
              },
          }
      },
      ui: {
          auth: {
              signInUrl: `${domain}/auth/sign-in/`,
              signOutUrl: `${domain}/auth/sign-out/`,
          },
          cashRegister: {
              cashRegister: `${domain}/cash-register/`,
          },
          account: {
  
          },
          founder: {
              createCompanyUrl: `${domain}/founder/create-company/`,
              dashboardUrl: `${domain}/founder/dashboard/`,
              staffUrl: `${domain}/founder/staff/`,
              goodsUrl: `${domain}/founder/goods/`,
              clientsUrl: `${domain}/founder/clients/`,
              pricingUrl: `${domain}/founder/pricing/`,
              helpAndSupportUrl: `${domain}/founder/help-and-support/`,
              aboutUrl: `${domain}/founder/about/`,
          },
      }
    },
    citiesTimezone: {
        "Москва": "Europe/Moscow",
        "Санкт-Петербург": "Europe/Moscow",
        "Екатеринбург": "Asia/Yekaterinburg",
        "Казань": "Europe/Moscow",
        "Новосибирск": "Asia/Novosibirsk",
        "Красноярск": "Asia/Krasnoyarsk",
        "Челябинск": "Asia/Yekaterinburg",
        "Омск": "Asia/Omsk",
        "Самара": "Europe/Samara",
        "Ростов-на-Дону": "Europe/Moscow",
        "Уфа": "Asia/Yekaterinburg",
        "Краснодар": "Europe/Moscow",
        "Пермь": "Asia/Yekaterinburg",
        "Владивосток": "Asia/Vladivostok",
        "Иркутск": "Asia/Irkutsk",
        "Ярославль": "Europe/Moscow",
        "Воронеж": "Europe/Moscow",
        "Махачкала": "Europe/Moscow",
        "Томск": "Asia/Novosibirsk",
        "Оренбург": "Europe/Samara",
        "Кемерово": "Asia/Novokuznetsk",
        "Тюмень": "Asia/Yekaterinburg",
        "Волгоград": "Europe/Volgograd",
        "Астрахань": "Europe/Astrakhan",
        "Киров": "Europe/Moscow",
        "Ижевск": "Europe/Samara",
        "Ульяновск": "Europe/Ulyanovsk",
        "Барнаул": "Asia/Novosibirsk",
        "Владимир": "Europe/Moscow",
        "Калининград": "Europe/Kaliningrad",
        "Нальчик": "Europe/Moscow",
        "Хабаровск": "Asia/Vladivostok",
        "Ставрополь": "Europe/Moscow",
        "Белгород": "Europe/Moscow",
        "Ханты-Мансийск": "Asia/Yekaterinburg",
        "Сургут": "Asia/Yekaterinburg",
        "Чебоксары": "Europe/Moscow",
        "Петрозаводск": "Europe/Moscow",
        "Курган": "Asia/Yekaterinburg",
        "Великий Новгород": "Europe/Moscow",
        "Кострома": "Europe/Moscow",
        "Йошкар-Ола": "Europe/Moscow",
        "Саранск": "Europe/Moscow",
        "Якутск": "Asia/Yakutsk",
        "Владикавказ": "Europe/Moscow",
        "Симферополь": "Europe/Simferopol",
        "Курск": "Europe/Moscow",
        "Мурманск": "Europe/Moscow",
        "Тамбов": "Europe/Moscow",
        "Грозный": "Europe/Moscow",
        "Тверь": "Europe/Moscow",
        "Тула": "Europe/Moscow",
        "Благовещенск": "Asia/Yakutsk",
        "Чита": "Asia/Chita",
        "Псков": "Europe/Moscow",
        "Брянск": "Europe/Moscow",
        "Рязань": "Europe/Moscow",
        "Архангельск": "Europe/Moscow",
        "Липецк": "Europe/Moscow",
        "Магадан": "Asia/Magadan",
        "Горно-Алтайск": "Asia/Omsk",
        "Сыктывкар": "Europe/Moscow",
        "Смоленск": "Europe/Moscow",
        "Биробиджан": "Asia/Vladivostok",
        "Петропавловск-Камчатский": "Asia/Kamchatka",
        "Абакан": "Asia/Krasnoyarsk",
        "Севастополь": "Europe/Simferopol",
        "Анадырь": "Asia/Anadyr",
    
        "Салехард": "Asia/Yekaterinburg",
        "Абиджан": "Africa/Abidjan",
        "Аккра": "Africa/Accra",
        "Аддис-Абеба": "Africa/Addis_Ababa",
        "Алжир": "Africa/Algiers",
        "Асмэра": "Africa/Asmara",
        "Бамако": "Africa/Bamako",
        "Банги": "Africa/Bangui",
        "Банджул": "Africa/Banjul",
        "Бисау": "Africa/Bissau",
        "Блантайр": "Africa/Blantyre",
        "Браззавиль": "Africa/Brazzaville",
        "Бужумбура": "Africa/Bujumbura",
        "Каир": "Africa/Cairo",
        "Касабланка": "Africa/Casablanca",
        "Сеута": "Africa/Ceuta",
        "Конакри": "Africa/Conakry",
        "Дакар": "Africa/Dakar",
        "Дар-эс-Салам": "Africa/Dar_es_Salaam",
        "Джибути": "Africa/Djibouti",
        "Дуала": "Africa/Douala",
        "Эль-Аюн": "Africa/El_Aaiun",
        "Фритаун": "Africa/Freetown",
        "Габороне": "Africa/Gaborone",
        "Хараре": "Africa/Harare",
        "Йоханнесбург": "Africa/Johannesburg",
        "Джуба": "Africa/Juba",
        "Кампала": "Africa/Kampala",
        "Хартум": "Africa/Khartoum",
        "Кигали": "Africa/Kigali",
        "Киншаса": "Africa/Kinshasa",
        "Лагос": "Africa/Lagos",
        "Либревиль": "Africa/Libreville",
        "Ломе": "Africa/Lome",
        "Луанда": "Africa/Luanda",
        "Лубумбаши": "Africa/Lubumbashi",
        "Лусака": "Africa/Lusaka",
        "Малабо": "Africa/Malabo",
        "Мапуту": "Africa/Maputo",
        "Масеру": "Africa/Maseru",
        "Мбабане": "Africa/Mbabane",
        "Могадишо": "Africa/Mogadishu",
        "Монровия": "Africa/Monrovia",
        "Найроби": "Africa/Nairobi",
        "Нджамена": "Africa/Ndjamena",
        "Ниамей": "Africa/Niamey",
        "Нуакшот": "Africa/Nouakchott",
        "Уагадугу": "Africa/Ouagadougou",
        "Порто-Ново": "Africa/Porto-Novo",
        "Сан-Томе": "Africa/Sao_Tome",
        "Тимбукту": "Africa/Timbuktu",
        "Триполи": "Africa/Tripoli",
        "Тунис": "Africa/Tunis",
        "Виндхук": "Africa/Windhoek",
        "Адак": "America/Adak",
        "Анкоридж": "America/Anchorage",
        "Ангилья": "America/Anguilla",
        "Антигуа": "America/Antigua",
        "Арагуаина": "America/Araguaina",
        "Буэнос-Айрес": "America/Argentina/Buenos_Aires",
        "Катамарка": "America/Argentina/Catamarca",
        "Комодоро-Ривадавия": "America/Argentina/ComodRivadavia",
        "Кордова": "America/Argentina/Cordoba",
        "Жужуй": "America/Argentina/Jujuy",
        "Ла-Риоха": "America/Argentina/La_Rioja",
        "Мендоса": "America/Argentina/Mendoza",
        "Рио-Гальегос": "America/Argentina/Rio_Gallegos",
        "Сальта": "America/Argentina/Salta",
        "Сан-Хуан": "America/Argentina/San_Juan",
        "Сан-Луис": "America/Argentina/San_Luis",
        "Тукуман": "America/Argentina/Tucuman",
        "Ушуайя": "America/Argentina/Ushuaia",
        "Аруба": "America/Aruba",
        "Асунсьон": "America/Asuncion",
        "Атикокан": "America/Atikokan",
        "Баия": "America/Bahia",
        "Баия-де-Бандерас": "America/Bahia_Banderas",
        "Барбадос": "America/Barbados",
        "Белен": "America/Belem",
        "Белиз": "America/Belize",
        "Бланк-Саблон": "America/Blanc-Sablon",
        "Боа-Виста": "America/Boa_Vista",
        "Богота": "America/Bogota",
        "Бойсе": "America/Boise",
        "Кембридж-Бей": "America/Cambridge_Bay",
        "Кампу-Гранди": "America/Campo_Grande",
        "Канкун": "America/Cancun",
        "Каракас": "America/Caracas",
        "Кайенна": "America/Cayenne",
        "Кайман": "America/Cayman",
        "Чикаго": "America/Chicago",
        "Чиуауа": "America/Chihuahua",
        "Сьюдад-Хуарес": "America/Ciudad_Juarez",
        "Корал-Харбор": "America/Coral_Harbour",
        "Коста-Рика": "America/Costa_Rica",
        "Крестон": "America/Creston",
        "Куяба": "America/Cuiaba",
        "Кюрасао": "America/Curacao",
        "Данмарксхавн": "America/Danmarkshavn",
        "Доусон": "America/Dawson",
        "Доусон-Крик": "America/Dawson_Creek",
        "Денвер": "America/Denver",
        "Детройт": "America/Detroit",
        "Доминика": "America/Dominica",
        "Эдмонтон": "America/Edmonton",
        "Эйрунепе": "America/Eirunepe",
        "Сан-Сальвадор": "America/El_Salvador",
        "Энсенада": "America/Ensenada",
        "Форт-Нельсон": "America/Fort_Nelson",
        "Форт-Уэйн": "America/Fort_Wayne",
        "Форталеза": "America/Fortaleza",
        "Глейс-Бей": "America/Glace_Bay",
        "Нуук": "America/Godthab",
        "Гус-Бей": "America/Goose_Bay",
        "Гранд-Терк": "America/Grand_Turk",
        "Гренада": "America/Grenada",
        "Гваделупа": "America/Guadeloupe",
        "Гватемала": "America/Guatemala",
        "Гуаякиль": "America/Guayaquil",
        "Гайана": "America/Guyana",
        "Галифакс": "America/Halifax",
        "Гавана": "America/Havana",
        "Эрмосильо": "America/Hermosillo",
        "Индианаполис": "America/Indiana/Indianapolis",
        "Нокс, Индиана": "America/Indiana/Knox",
        "Маренго, Индиана": "America/Indiana/Marengo",
        "Питерсберг, Индиана": "America/Indiana/Petersburg",
        "Телл-Сити, Индиана": "America/Indiana/Tell_City",
        "Вивей, Индиана": "America/Indiana/Vevay",
        "Винсеннес, Индиана": "America/Indiana/Vincennes",
        "Винамак, Индиана": "America/Indiana/Winamac",
        "Инувик": "America/Inuvik",
        "Икалуит": "America/Iqaluit",
        "Ямайка": "America/Jamaica",
        "Джуно": "America/Juneau",
        "Луисвилл": "America/Kentucky/Louisville",
        "Монтиселло, Кентукки": "America/Kentucky/Monticello",
        "Кралендейк": "America/Kralendijk",
        "Ла-Пас": "America/La_Paz",
        "Лима": "America/Lima",
        "Лос-Анджелес": "America/Los_Angeles",
        "Лоуэр-Принсес-Квотер": "America/Lower_Princes",
        "Масейо": "America/Maceio",
        "Манагуа": "America/Managua",
        "Манаус": "America/Manaus",
        "Мариго": "America/Marigot",
        "Мартиника": "America/Martinique",
        "Матаморос": "America/Matamoros",
        "Масатлан": "America/Mazatlan",
        "Меномини": "America/Menominee",
        "Мерида": "America/Merida",
        "Метлакатла": "America/Metlakatla",
        "Мехико": "America/Mexico_City",
        "Микелон": "America/Miquelon",
        "Монктон": "America/Moncton",
        "Монтеррей": "America/Monterrey",
        "Монтевидео": "America/Montevideo",
        "Монреаль": "America/Montreal",
        "Монтсеррат": "America/Montserrat",
        "Нассау": "America/Nassau",
        "Нью-Йорк": "America/New_York",
        "Нипигон": "America/Nipigon",
        "Ном": "America/Nome",
        "Норонья": "America/Noronha",
        "Бойла, Северная Дакота": "America/North_Dakota/Beulah",
        "Центр, Северная Дакота": "America/North_Dakota/Center",
        "Нью-Салем, Северная Дакота": "America/North_Dakota/New_Salem",
        "Охинага": "America/Ojinaga",
        "Панама": "America/Panama",
        "Пангниртунг": "America/Pangnirtung",
        "Парамарибо": "America/Paramaribo",
        "Финикс": "America/Phoenix",
        "Порт-о-Пренс": "America/Port-au-Prince",
        "Порт-оф-Спейн": "America/Port_of_Spain",
        "Порту-Акри": "America/Porto_Acre",
        "Порту-Велью": "America/Porto_Velho",
        "Пуэрто-Рико": "America/Puerto_Rico",
        "Пунта-Аренас": "America/Punta_Arenas",
        "Рейни-Ривер": "America/Rainy_River",
        "Ранкин-Инлет": "America/Rankin_Inlet",
        "Ресифи": "America/Recife",
        "Реджайна": "America/Regina",
        "Резольют": "America/Resolute",
        "Риу-Бранку": "America/Rio_Branco",
        "Росарио": "America/Rosario",
        "Санта-Изабел": "America/Santa_Isabel",
        "Сантарен": "America/Santarem",
        "Сантьяго": "America/Santiago",
        "Санто-Доминго": "America/Santo_Domingo",
        "Сан-Паулу": "America/Sao_Paulo",
        "Иттоккортоормиит": "America/Scoresbysund",
        "Шипрок": "America/Shiprock",
        "Ситка": "America/Sitka",
        "Сен-Бартелеми": "America/St_Barthelemy",
        "Сент-Джонс": "America/St_Johns",
        "Сент-Китс": "America/St_Kitts",
        "Сент-Люсия": "America/St_Lucia",
        "Сент-Томас": "America/St_Thomas",
        "Сент-Винсент": "America/St_Vincent",
        "Свифт-Каррент": "America/Swift_Current",
        "Тегусигальпа": "America/Tegucigalpa",
        "Туле": "America/Thule",
        "Тандер-Бей": "America/Thunder_Bay",
        "Тихуана": "America/Tijuana",
        "Торонто": "America/Toronto",
        "Тортола": "America/Tortola",
        "Ванкувер": "America/Vancouver",
        "Вирджин": "America/Virgin",
        "Уайтхорс": "America/Whitehorse",
        "Виннипег": "America/Winnipeg",
        "Якутат": "America/Yakutat",
        "Йеллоунайф": "America/Yellowknife",
        "Кейси": "Antarctica/Casey",
        "Дэвис": "Antarctica/Davis",
        "Дюмон-д’Юрвиль": "Antarctica/DumontDUrville",
        "Маккуори": "Antarctica/Macquarie",
        "Моусон": "Antarctica/Mawson",
        "Мак-Мердо": "Antarctica/McMurdo",
        "Палмер": "Antarctica/Palmer",
        "Ротера": "Antarctica/Rothera",
        "Южный полюс": "Antarctica/South_Pole",
        "Сёва": "Antarctica/Syowa",
        "Тролл": "Antarctica/Troll",
        "Восток": "Antarctica/Vostok",
        "Лонгйир": "Arctic/Longyearbyen",
        "Аден": "Asia/Aden",
        "Алматы": "Asia/Almaty",
        "Амман": "Asia/Amman",
        "Актау": "Asia/Aqtau",
        "Актобе": "Asia/Aqtobe",
        "Ашхабад": "Asia/Ashgabat",
        "Атырау": "Asia/Atyrau",
        "Багдад": "Asia/Baghdad",
        "Бахрейн": "Asia/Bahrain",
        "Баку": "Asia/Baku",
        "Бангкок": "Asia/Bangkok",
        "Бейрут": "Asia/Beirut",
        "Бишкек": "Asia/Bishkek",
        "Бруней": "Asia/Brunei",
        "Калькутта (Колката)": "Asia/Calcutta (Kolkata)",
        "Чойбалсан": "Asia/Choibalsan",
        "Чунцин": "Asia/Chongqing (Chungking)",
        "Коломбо": "Asia/Colombo",
        "Дакка (Дака)": "Asia/Dacca (Dhaka)",
        "Дамаск": "Asia/Damascus",
        "Дака": "Asia/Dhaka",
        "Дили": "Asia/Dili",
        "Дубай": "Asia/Dubai",
        "Душанбе": "Asia/Dushanbe",
        "Фамагуста": "Asia/Famagusta",
        "Газа": "Asia/Gaza",
        "Харбин": "Asia/Harbin",
        "Хеврон": "Asia/Hebron",
        "Хошимин": "Asia/Ho_Chi_Minh",
        "Гонконг": "Asia/Hong_Kong",
        "Ховд": "Asia/Hovd",
        "Стамбул": "Asia/Istanbul",
        "Джакарта": "Asia/Jakarta",
        "Джаяпура": "Asia/Jayapura",
        "Иерусалим": "Asia/Jerusalem",
        "Кабул": "Asia/Kabul",
        "Камчатка": "Asia/Kamchatka",
        "Карачи": "Asia/Karachi",
        "Кашгар": "Asia/Kashgar",
        "Катманду": "Asia/Kathmandu",
        "Хандыга": "Asia/Khandyga",
        "Колката": "Asia/Kolkata",
        "Куала-Лумпур": "Asia/Kuala_Lumpur",
        "Кучинг": "Asia/Kuching",
        "Кувейт": "Asia/Kuwait",
        "Макао": "Asia/Macao (Macau)",
        "Макассар": "Asia/Makassar",
        "Манила": "Asia/Manila",
        "Маскат": "Asia/Muscat",
        "Никосия": "Asia/Nicosia",
        "Новокузнецк": "Asia/Novokuznetsk",
        "Уральск": "Asia/Oral",
        "Пномпень": "Asia/Phnom_Penh",
        "Понтианак": "Asia/Pontianak",
        "Пхеньян": "Asia/Pyongyang",
        "Катар": "Asia/Qatar",
        "Костанай": "Asia/Qostanay (Kostanay)",
        "Кызылорда": "Asia/Qyzylorda",
        "Янгон": "Asia/Rangoon (Yangon)",
        "Эр-Рияд": "Asia/Riyadh",
        "Сахалин": "Asia/Sakhalin",
        "Самарканд": "Asia/Samarkand",
        "Сеул": "Asia/Seoul",
        "Шанхай": "Asia/Shanghai",
        "Сингапур": "Asia/Singapore",
        "Среднеколымск": "Asia/Srednekolymsk",
        "Тайбэй": "Asia/Taipei",
        "Ташкент": "Asia/Tashkent",
        "Тбилиси": "Asia/Tbilisi",
        "Тегеран": "Asia/Tehran",
        "Тель-Авив (Иерусалим)": "Asia/Tel_Aviv (Jerusalem)",
        "Тхимпху": "Asia/Thimbu (Thimphu)",
        "Токио": "Asia/Tokyo",
        "Улан-Батор": "Asia/Ulaanbaatar (Ulan Bator)",
        "Урумчи": "Asia/Urumqi",
        "Усть-Нера": "Asia/Ust-Nera",
        "Вьентьян": "Asia/Vientiane",
        "Ереван": "Asia/Yerevan",
        "Азорские острова": "Atlantic/Azores",
        "Бермудские острова": "Atlantic/Bermuda",
        "Канарские острова": "Atlantic/Canary",
        "Кабо-Верде": "Atlantic/Cape_Verde",
        "Фарерские острова": "Atlantic/Faeroe (Faroe)",
        "Ян-Майен": "Atlantic/Jan_Mayen",
        "Мадейра": "Atlantic/Madeira",
        "Рейкьявик": "Atlantic/Reykjavik",
        "Южная Георгия": "Atlantic/South_Georgia",
        "Святая Елена": "Atlantic/St_Helena",
        "Стэнли": "Atlantic/Stanley",
        "Аделаида": "Australia/Adelaide",
        "Брисбен": "Australia/Brisbane",
        "Брокен-Хилл": "Australia/Broken_Hill",
        "Карри": "Australia/Currie",
        "Дарвин": "Australia/Darwin",
        "Юкла": "Australia/Eucla",
        "Хобарт": "Australia/Hobart",
        "Линдеман": "Australia/Lindeman",
        "Лорд-Хау": "Australia/Lord_Howe",
        "Мельбурн": "Australia/Melbourne",
        "Перт": "Australia/Perth",
        "Сидней": "Australia/Sydney",
        "Янкауинна": "Australia/Yancowinna",
        "Акри": "Brazil/Acre",
        "Фернанду-ди-Норонья": "Brazil/DeNoronha",
        "Бразилия (восточная часть)": "Brazil/East",
        "Бразилия (западная часть)": "Brazil/West",
        "Канада (Атлантический часовой пояс)": "Canada/Atlantic",
        "Канада (Центральный часовой пояс)": "Canada/Central",
        "Канада (Восточный часовой пояс)": "Canada/Eastern",
        "Канада (Горный часовой пояс)": "Canada/Mountain",
        "Ньюфаундленд и Лабрадор": "Canada/Newfoundland",
        "Канада (Тихоокеанский часовой пояс)": "Canada/Pacific",
        "Саскачеван": "Canada/Saskatchewan",
        "Юкон": "Canada/Yukon",
        "Чили (континентальная часть)": "Chile/Continental",
        "Пасхи остров": "Chile/EasterIsland",
        "Куба": "Cuba",
        "Египет": "Egypt",
        "Ирландия": "Eire",
        "Гринвич (GMT)": "GMT",
        "Исландия": "Iceland",
        "Антананариву": "Indian/Antananarivo",
        "Чагос": "Indian/Chagos",
        "Остров Рождества": "Indian/Christmas",
        "Кокосовые острова": "Indian/Cocos",
        "Коморские острова": "Indian/Comoro",
        "Кергелен": "Indian/Kerguelen",
        "Маэ": "Indian/Mahe",
        "Мальдивы": "Indian/Maldives",
        "Маврикий": "Indian/Mauritius",
        "Майотта": "Indian/Mayotte",
        "Реюньон": "Indian/Reunion",
        "Иран": "Iran",
        "Израиль": "Israel",
        "Япония": "Japan",
        "Кваджалейн": "Kwajalein",
        "Ливия": "Libya",
        "Мексика (Северная Нижняя Калифорния)": "Mexico/BajaNorte",
        "Мексика (Южная Нижняя Калифорния)": "Mexico/BajaSur",
        "Мексика (общий часовой пояс)": "Mexico/General",
        "Новая Зеландия": "NZ",
        "Чатем (Новая Зеландия)": "NZ-CHAT",
        "Навахо": "Navajo",
        "Китайская Народная Республика": "PRC",
        "Тихоокеанский стандартный/летний час (США и Канада)": "PST8PDT",
        "Апиа": "Pacific/Apia",
        "Окленд": "Pacific/Auckland",
        "Бугенвиль": "Pacific/Bougainville",
        "Чатем": "Pacific/Chatham",
        "Чуук (Трук)": "Pacific/Chuuk (Truk)",
        "Эфате": "Pacific/Efate",
        "Эндербери": "Pacific/Enderbury",
        "Факаофо": "Pacific/Fakaofo",
        "Фиджи": "Pacific/Fiji",
        "Фунафути": "Pacific/Funafuti",
        "Галапагосские острова": "Pacific/Galapagos",
        "Гамбье": "Pacific/Gambier",
        "Гуадалканал": "Pacific/Guadalcanal",
        "Гуам": "Pacific/Guam",
        "Гонолулу": "Pacific/Honolulu",
        "Джонстон": "Pacific/Johnston",
        "Кантон": "Pacific/Kanton",
        "Киритимати": "Pacific/Kiritimati",
        "Косрае": "Pacific/Kosrae",
        "Маджуро": "Pacific/Majuro",
        "Маркизские острова": "Pacific/Marquesas",
        "Мидуэй": "Pacific/Midway",
        "Науру": "Pacific/Nauru",
        "Ниуэ": "Pacific/Niue",
        "Норфолк": "Pacific/Norfolk",
        "Нумеа": "Pacific/Noumea",
        "Паго-Паго": "Pacific/Pago_Pago",
        "Палау": "Pacific/Palau",
        "Питкэрн": "Pacific/Pitcairn",
        "Понпеи": "Pacific/Pohnpei (Ponape)",
        "Порт-Морсби": "Pacific/Port_Moresby",
        "Раротонга": "Pacific/Rarotonga",
        "Сайпан": "Pacific/Saipan",
        "Самоа": "Pacific/Samoa",
        "Таити": "Pacific/Tahiti",
        "Тарава": "Pacific/Tarawa",
        "Тонгатапу": "Pacific/Tongatapu",
        "Уэйк": "Pacific/Wake",
        "Уоллис": "Pacific/Wallis",
        "Яп": "Pacific/Yap",
        "Польша": "Poland",
        "Португалия": "Portugal",
        "Турция": "Turkey",
        "Аляска (США)": "US/Alaska",
        "Алеутские острова (США)": "US/Aleutian",
        "Аризона (США)": "US/Arizona",
        "Центральный часовой пояс (США)": "US/Central",
        "Восточная Индиана (США)": "US/East-Indiana",
        "Восточный часовой пояс (США)": "US/Eastern",
        "Гавайи (США)": "US/Hawaii",
        "Старк, Индиана (США)": "US/Indiana-Starke",
        "Мичиган (США)": "US/Michigan",
        "Горный часовой пояс (США)": "US/Mountain",
        "Тихоокеанский часовой пояс (США)": "US/Pacific",
        "Самоа (США)": "US/Samoa",
        "Всемирное координированное время (UTC)": "UTC",
        "Западно-Сибирское время (Россия)": "W-SU",
        "Западноевропейское время (WET)": "WET",
    },
    labels: {
      transactionMethods: {
        "cash": "Наличные",
        "card2card": "Перевод на карту",
        "terminal": "Терминал",
        "account": "На расчетный счет",
      },
      currencies: {
        "RUB": "₽", 
        "USD": "$", 
        "EUR": "€", 
        "BYN": "Br", 
        "KZT": "₸", 
        "UZS": "S", 
        "CNY": "¥", 
        "AZN": "₼", 
        "TRY": "₺", 
        "GEL": "₾", 
        "THB": "฿",
      },
      goodFormats: {
        "good": "Товар",
        "service": "Услуга",
        "operation": "Операция",
      },
      jobTitles: {
        "manager": "Менеджер",
        "cashier": "Кассир",
      }
    },
    isAuthorized: false,
    companyID: "27a72766a30fc48cdf2f52b8031f028747b54f056185ee0cca",
    currencies: ["RUB", "USD", "EUR", "BYN", "KZT", "UZS", "CNY", "AZN", "TRY", "GEL", "THB"],
    companyData: {},
    kassas: [],
    kassaData: {},
    profileData: {},
    transactions: [],
    tasks: [],
    kassaGoods: [],
    clients: [],
    goods: [],
    cashiers: [],
    supplyingsAndMovings: [],
  },
  getters: {
  },
  mutations: {
    setCompanyID(state, newCompanyID) {
      state.companyID = newCompanyID;
    },
    setCompanyData(state, newCompanyData) {
      state.companyData = newCompanyData;
    },
    setKassaData(state, newKassaData) {
      state.kassaData = newKassaData;
    },
    setProfileData(state, newProfileData) {
      state.profileData = newProfileData;
    },
    setTransactions(state, newTransactionsData) {
      state.transactions = newTransactionsData;
    },
    addTransaction(state, newTransactionData) {
      state.transactions.unshift(newTransactionData);
      if (newTransactionData.payment == "cash") {
        state.kassaData.cashInCashRegister[newTransactionData.currency] += Number(newTransactionData.total);
        state.kassaData.cashInCashRegister[newTransactionData.currency] = Number(state.kassaData.cashInCashRegister[newTransactionData.currency].toFixed(2))
      }

      if (newTransactionData.isTransfer) {
        return;
      }

      for (const operation of newTransactionData.operations) {
        if (operation.good.format == "good") {
          const kassaGood = state.kassaGoods.find(good => good.id == operation.good.id)
          kassaGood.amount[state.kassaData.id] -= operation.amount;

          const good = state.goods.find(good => good.id == operation.good.id);
          good.amount[state.kassaData.id] -= operation.amount;
        }
      }

      if (newTransactionData.client && newTransactionData.client.id) {
        const client = state.clients.find(client => client.id == newTransactionData.client.id);
        client.totalPayments[newTransactionData.currency] += Number(newTransactionData.total);
      }
    },
    deleteTransaction(state, transactionId) {
      const transaction = state.transactions.find(transaction => transaction.id == transactionId);
      transaction.isActive = false;
      if (transaction.payment == "cash") {
        state.kassaData.cashInCashRegister[transaction.currency] -= transaction.total;
      }

      if (transaction.client && transaction.client.id) {
        const client = state.clients.find(client => client.id == transaction.client.id);
        client.totalPayments[transaction.currency] -= Number(transaction.total);
      }
    },
    setTasks(state, newTasks) {
      state.tasks = newTasks;
    },
    updateTask(state, params) {
      const { taskIndex, newData } = params;
      state.tasks[taskIndex] = newData;
    },
    addTask(state, newTask) {
      state.tasks.unshift(newTask);
    },
    setIsAuthorized(state, newValue) {
      state.isAuthorized = newValue;
    },
    setKassaGoods(state, newData) {
      state.kassaGoods = newData;
    },
    setClients(state, newData) {
      state.clients = newData;
    },
    addClient(state, clientData) {
      state.clients.push(clientData);
    },
    updateClient(state, newClientData) {
      const client = state.clients.find(element => element.id == newClientData.id);
      client.email = newClientData.email;
      client.phone = newClientData.phone;
      client.name = newClientData.name;
      client.note = newClientData.note;
      client.discount = newClientData.discount;
      client.useDiscountPolicy = newClientData.useDiscountPolicy;
      client.totalPayments = newClientData.totalPayments;
      client.isActive = newClientData.isActive;
    },
    archiveClient(state, clientId) {
      const client = state.clients.find(client => client.id == clientId);
      client.isActive = false;
    },
    archiveClients(state, clientIds) {
      for (const clientId of clientIds) {
        const client = state.clients.find(client => client.id == clientId);
        client.isActive = false;
      }
    },
    activateClient(state, clientId) {
      const client = state.clients.find(client => client.id == clientId);
      client.isActive = true;
    },
    activateClients(state, clientIds) {
      for (const clientId of clientIds) {
        const client = state.clients.find(client => client.id == clientId);
        client.isActive = true;
      }
    },
    deleteClient(state, clientId) {
      state.clients = state.clients.filter(client => client.id != clientId);
    },
    deleteClients(state, clientIds) {
      for (const clientId of clientIds) {
        state.clients = state.clients.filter(client => client.id != clientId);
      }
    },
    setGoods(state, newData) {
      state.goods = newData;
    },
    addGood(state, goodData) {
      state.goods.unshift(goodData);
    },
    updateGood(state, newGoodData) {
      const good = state.goods.find(good => good.id == newGoodData.id);
      good.name = newGoodData.name;
      good.price = newGoodData.price;
      good.format = newGoodData.format;
      good.type = newGoodData.type;
      good.verboseId = newGoodData.verboseId;
      good.shopIds = newGoodData.shopIds;
      good.characteristics = newGoodData.characteristics;
      good.isActive = newGoodData.isActive;
      good.amount = newGoodData.amount;
    },
    archiveGood(state, goodId) {
      const good = state.goods.find(good => good.id == goodId);
      good.isActive = false;
    },
    archiveGoods(state, goodIds) {
      for (const goodId of goodIds) {
        const good = state.goods.find(good => good.id == goodId);
        good.isActive = false;
      }
    },
    activateGood(state, goodId) {
      const good = state.goods.find(good => good.id == goodId);
      good.isActive = true;
    },
    activateGoods(state, goodIds) {
      for (const goodId of goodIds) {
        const good = state.goods.find(good => good.id == goodId);
        good.isActive = true;
      }
    },
    deleteGood(state, goodId) {
      state.goods = state.goods.filter(good => good.id != goodId);
    },
    deleteGoods(state, goodIds) {
      for (const goodId of goodIds) {
        state.goods = state.goods.filter(good => good.id != goodId);
      }
    },
    setCashiers(state, newData) {
      state.cashiers = newData;
    },
    setStaffs(state, newData) {
      state.staffs = newData;
    },
    addStaff(state, staffData) {
      state.staffs.unshift(staffData);
    },
    updateStaff(state, staffData) {
      const staff = state.staffs.find(element => element.id == staffData.id);
      staff.name = staffData.name;
      staff.phone = staffData.phone;
      staff.email = staffData.email;
      staff.jobTitle = staffData.jobTitle;
      staff.salaryPerDayHour = staffData.salaryPerDayHour;
      staff.salaryPerDayShift = staffData.salaryPerDayShift;
      staff.salaryPerNightHour = staffData.salaryPerNightHour;
      staff.salaryPerNightShift = staffData.salaryPerNightShift;
      staff.percentOfSale = staffData.percentOfSale;
      staff.augmentedUserId = staffData.augmentedUserId;
      staff.shops = staffData.shops;
      staff.companyId = staffData.companyId;
      staff.isGlobal = staffData.isGlobal;
    },
    updateStaffActivateStatus(state, newData) {
      const staff = state.staffs.find(element => element.id == newData.id);
      staff.isActive = newData.isActive;
    },
    deleteStaff(state, staffId) {
      state.staffs = state.staffs.filter(staff => staff.id != staffId);
    },
    setKassas(state, newData) {
      state.kassas = newData;
    },
    updateKassa(state, params) {
      const { kassaId, newData } = params;
      const kassa = state.kassas.find(kassa => kassa.id == kassaId);
      kassa.name = newData.name;
      kassa.address = newData.address;
      kassa.cashPayment = newData.cashPayment;
      kassa.card2cardPayment = newData.card2cardPayment;
      kassa.terminalPayment = newData.terminalPayment;
      kassa.accountPayment = newData.accountPayment;
      kassa.defaultCurrency = newData.defaultCurrency;
      kassa.defaultPayment = newData.defaultPayment;
      kassa.reference = newData.reference;
    },
    addKassa(state, newData) {
      console.log(newData);
      state.kassas.unshift(newData);
    },
    updateDiscountPolicy(state, newData) {
      state.companyData.discountPolicy = newData;
    },
    updateCharacteristics(state, newData) {
      state.companyData.defaultGoodCharacteristics = newData;
    },
    setSupplyingsAndMovings(state, newData) {
      state.supplyingsAndMovings = newData;
    },
    addSupplying(state, supplyingData) {
      state.supplyingsAndMovings.unshift(supplyingData);

      for (const position of supplyingData.positions) {
        const good = state.goods.find(good => good.id == position.good.id);
        good.amount[supplyingData.warehouse] = good.amount[supplyingData.warehouse] || 0;
        good.amount[supplyingData.warehouse] += position.amount;
      }
    },
    addMoving(state, movingData) {
      state.supplyingsAndMovings.unshift(movingData);

      if (movingData.isWriteOff) {
        for (const position of movingData.positions) {
          const good = state.goods.find(good => good.id == position.good.id);
          good.amount[movingData.warehouseFrom] = good.amount[movingData.warehouseFrom] || 0;
          good.amount[movingData.warehouseFrom] -= position.amount;
        }
      } else {
        for (const position of movingData.positions) {
          const good = state.goods.find(good => good.id == position.good.id);
          good.amount[movingData.warehouseFrom] = good.amount[movingData.warehouseFrom] || 0;
          good.amount[movingData.warehouseFrom] -= position.amount;
          good.amount[movingData.warehouseTo] = good.amount[movingData.warehouseTo] || 0;
          good.amount[movingData.warehouseTo] += position.amount;
        }
      }
    },
  },
  actions: {
    async fetchCSRFToken() {
      const options = {
        method: "GET",
        credentials: "include",
      }
      return fetch(this.state.URLs.api.auth.getCsrfToken, options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.result == "csrf_token set.") {
          return { successfully: true }
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red", Infinity);
        return { successfully: false }
      })
    },
    async fetchSignIn({ commit }, credentials) {
      const { username, password } = credentials;
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': getCookie("csrftoken"),
          },
          credentials: 'include',
          body: JSON.stringify({
              "username": username,
              "password": password
          })
      };
      return fetch(this.state.URLs.api.auth.signInUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result != "the user successfully signed in") {
          this.$notify(" Упс! Что-то пошло не так…", "red", Infinity);
          return { successfully: false };
        }
        commit("setCompanyID", data["company-id"])
        commit("setIsAuthorized", true);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red", Infinity);
        return { successfully: false };
      })
    },
    async fetchKassaTransactions({ commit }, params) {
      const { dateFrom, dateTo } = params;
      const options = {
          method: "GET",
          headers: {
              "companyId": this.state.companyID,
              "shopId": globalMethods.getDataFromLocalStorage("currentKassaID"),
              "timezone": this.state.kassaData.timezone,
          },
          credentials: 'include',
      };
      const currentURL = `${this.state.URLs.api.model.sales.salesUrl}?date-from=${dateFrom}&date-to=${dateTo}`;
      return fetch(currentURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setTransactions", data.data);
        return { successfully: true }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red", Infinity);
        return { successfully: false };
      })
    },
    async fetchTasks({ commit }, params) {
      const { dateFrom, dateTo } = params;
      const options = {
          method: "GET",
          headers: {
              "companyId": this.state.companyID,
              "shopId": globalMethods.getDataFromLocalStorage("currentKassaID"),
          },
          credentials: 'include',
      };
      const currentURL = `${this.state.URLs.api.model.tasks.tasksUrl}?date-from=${dateFrom}&date-to=${dateTo}`
      return fetch(currentURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setTasks", data);
        return { successfully: true }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red", Infinity);
        return { successfully: false };
      })
    },
    async fetchCompleteTask({ commit }, params) {
      const { taskID } = params;
      const options = {
          method: "POST",
          headers: {
              "companyId": this.state.companyID,
              "shopId": globalMethods.getDataFromLocalStorage("currentKassaID"),
              'X-CSRFToken': getCookie("csrftoken"),
          },
          credentials: 'include',
      };
      const currentUrl = this.state.URLs.api.model.tasks.taskUrl.replace("<ID>", taskID);
      return fetch(currentUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the task successfully completed") {
          const taskIndex = this.state.tasks.findIndex(task => task.id === data.task.id);
          commit("updateTask", { taskIndex: taskIndex, newData: data.task });
          return { successfully: true };
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false, error: data.error, detail: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false  };
      })
    },
    async fetchCompanyData({ commit }) {
      const options = {
        method: "GET",
        headers: {
            "companyId": this.state.companyID,
        },
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.company.companyUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setCompanyData", data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchKassas({ commit }) {
      const options = {
        method: "GET",
        headers: {
            "companyId": this.state.companyID,
        },
        credentials: 'include',
      };
      const currentKassaURL = this.state.URLs.api.model.shops.shopsUrl;
      return fetch(currentKassaURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setKassas", data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchKassaData({ commit }) {
      const options = {
        method: "GET",
        headers: {
            "companyId": this.state.companyID,
        },
        credentials: 'include',
      };
      const currentKassaURL = this.state.URLs.api.model.shops.shopUrl.replace("<ID>", globalMethods.getDataFromLocalStorage("currentKassaID"));
      return fetch(currentKassaURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.error) {
          return { successfully: false };
        }
        commit("setKassaData", data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchProfileData({ commit }) {
      const options = {
        method: "GET",
        headers: {
            "companyId": this.state.companyID,
        },
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.account.personalDataUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setProfileData", data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchKassaGoods({ commit }) {
      const options = {
          method: "GET",
          headers: {
              "companyId": this.state.companyID,
              "shopId": globalMethods.getDataFromLocalStorage("currentKassaID"),
              "timezone": this.state.kassaData.timezone,
          },
          credentials: 'include',
      };
      const currentURL = `${this.state.URLs.api.model.goods.goodsUrl}?hide-archived-goods=true&shop-id=${globalMethods.getDataFromLocalStorage("currentKassaID")}&sort=alphabet`;
      return fetch(currentURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setKassaGoods", data.data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchClients({ commit }) {
      const options = {
          method: "GET",
          headers: {
              "companyId": this.state.companyID,
              "shopId": globalMethods.getDataFromLocalStorage("currentKassaID"),
              "timezone": this.state.kassaData.timezone,
          },
          credentials: 'include',
      };
      const currentURL = `${this.state.URLs.api.model.clients.clientsUrl}`;
      return fetch(currentURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setClients", data.data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchCashiers({ commit }) {
      const options = {
          method: "GET",
          headers: {
              companyId: this.state.companyID,
          },
          credentials: 'include',
      };
      const currentURL = `${this.state.URLs.api.model.staffs.staffsUrl}?shop-id=${globalMethods.getDataFromLocalStorage("currentKassaID")}`;
      return fetch(currentURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setCashiers", data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchStaffs({ commit }) {
      const options = {
          method: "GET",
          headers: {
              companyId: this.state.companyID,
          },
          credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.staffs.staffsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setStaffs", data);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async updateStaff({ commit }, params) {
      const { staffId, name, phone, jobTitle, salaryPerDayHour, salaryPerDayShift, salaryPerNightHour, salaryPerNightShift, percentOfSale, isGlobal, isActive, shopIds } = params;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': getCookie("csrftoken"),
          companyId: this.state.companyID,
        },
        credentials: "include",
        body: JSON.stringify({
          name: name,
          phone: phone,
          jobTitle: jobTitle,
          salaryPerDayHour: salaryPerDayHour,
          salaryPerDayShift: salaryPerDayShift,
          salaryPerNightHour: salaryPerNightHour,
          salaryPerNightShift: salaryPerNightShift,
          percentOfSale: percentOfSale,
          isGlobal: isGlobal,
          isActive: isActive,
          shopIds: shopIds,
        })
      }
      return fetch(this.state.URLs.api.model.staffs.staffUrl.replace("<ID>", staffId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the staff successfully updated") {
          commit("updateStaff", data.newData);
          return { successfully: true }
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createStaff({ commit }, params) {
      const { name, phone, token, jobtitle, isGlobal, shopIds, salaryPerDayShift, salaryPerDayHour, salaryPerNightShift, salaryPerNightHour, percentOfSale } = params;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': getCookie("csrftoken"),
          companyId: this.state.companyID,
        },
        credentials: "include",
        body: JSON.stringify({
          personalToken: token,
          name: name,
          phone: phone,
          jobTitle: jobtitle,
          salaryPerDayHour: salaryPerDayHour,
          salaryPerDayShift: salaryPerDayShift,
          salaryPerNightHour: salaryPerNightHour,
          salaryPerNightShift: salaryPerNightShift,
          percentOfSale: percentOfSale,
          isGlobal: isGlobal,
          shopIds: shopIds,
        })
      }
      return fetch(this.state.URLs.api.model.staffs.staffsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the staff successfully created" || data.result == "the staff successfully activated") {
          commit("addStaff", data.newData);
          return { successfully: true }
        }
        return { successfully: false, error: data.error, detail: data.detail }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async updateStaffActivateStatus({ commit }, params) {
      const { staffId, isActive } = params;
      const options = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': getCookie("csrftoken"),
          companyId: this.state.companyID,
        },
        credentials: "include",
        body: JSON.stringify({
          isActive: isActive,
        })
      }
      return fetch(this.state.URLs.api.model.staffs.staffUrl.replace("<ID>", staffId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the staff successfully updated") {
          commit("updateStaffActivateStatus", data.newData);
          return { successfully: true }
        }
        return { successfully: false }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async deleteStaff({ commit }, staffId) {
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': getCookie("csrftoken"),
          companyId: this.state.companyID,
        },
        credentials: "include",
      }
      return fetch(this.state.URLs.api.model.staffs.staffUrl.replace("<ID>", staffId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the staff successfully deleted") {
          commit("deleteStaff", staffId);
          return { successfully: true }
        }
        return { successfully: false }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewSale({ commit }, params) {
      const { transactionMethod, client, operations, discount, note, currency } = params;
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': getCookie("csrftoken"),
              companyId: this.state.companyID,
              shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
              timezone: this.state.kassaData.timezone,
          },
          body: JSON.stringify({
            payment: transactionMethod,
            clientId: client,
            discount: Number(discount),
            operations: operations,
            note: note,
            currency: currency,
          }),
          credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.sales.salesUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the sale was successfully created") {
          commit("addTransaction", data.data);
          return { successfully: true };
        }
        return { successfully: false, error: data.error, deatil: data.detail};
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewTransferSale({ commit }, params) {
      const { transactionMethod, transferTo, price, currency, note  } = params;
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': getCookie("csrftoken"),
              companyId: this.state.companyID,
              shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
              timezone: this.state.kassaData.timezone,
          },
          body: JSON.stringify({
            payment: transactionMethod,
            note: note,
            currency: currency,
            transfer_to: transferTo,
            total: price,
            is_transfer: true,
          }),
          credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.sales.salesUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the sale was successfully created") {
          commit("addTransaction", data.data);
          return { successfully: true };
        }
        return { successfully: false, error: data.error, detail: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewOperation({ commit }, params) {
      const { addOperationTransactionMethod, addOperationOperations, addOperationNote, addOperationCurrency } = params;
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': getCookie("csrftoken"),
              companyId: this.state.companyID,
              shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
              timezone: this.state.kassaData.timezone,
          },
          body: JSON.stringify({
            payment: addOperationTransactionMethod,
            operations: addOperationOperations,
            note: addOperationNote,
            currency: addOperationCurrency,
            discount: 0,
          }),
          credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.sales.salesUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the sale was successfully created") {
          commit("addTransaction", data.data);
          return { successfully: true };
        }
        return { successfully: false, error: data.error, detail: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async deleteTransaction({ commit }, transactionId) {
      const options = {
        method: "DELETE",
        headers: {
          'X-CSRFToken': getCookie("csrftoken"),
          companyId: this.state.companyID,
          shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
          timezone: this.state.kassaData.timezone,
        },
        credentials: "include",
      }
      return fetch(this.state.URLs.api.model.sales.saleUrl.replace("<ID>", transactionId), options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.result == "The transaction successfully deleted.") {
          commit("deleteTransaction", transactionId);
          return { successfully: true }
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false, error: data.error, detail: data.detail };
      })
      .catch(error => {
        this.$notify(" Упс! Что-то пошло не так…", "red");
        console.error(error.stack);
        return { successfully: false };
      })
    },
    async createNewTask({ commit }, params) {
      const { content } = params;
      const options = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': getCookie("csrftoken"),
              companyId: this.state.companyID,
              shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
              timezone: this.state.kassaData.timezone,
          },
          body: JSON.stringify({
            content: content
          }),
          credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.tasks.tasksUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the task successfully created") {
          commit("addTask", data.data);
          return { successfully: true }
        }
        return { successfully: false, error: data.error, detail: data.deatil };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewClient({ commit }, params) {
      const { clientTotalPayments, addClientName, addClientPhone, addClientEmail, addClientPersonalDiscount, addClientAddToGeneralDiscountPolicy, addClientNote } = params;
      const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          email: addClientEmail,
          phone: addClientPhone,
          name: addClientName,
          note: addClientNote,
          discount: addClientPersonalDiscount,
          useDiscountPolicy: addClientAddToGeneralDiscountPolicy,
          totalPayments: clientTotalPayments.reduce((acc, item) => {acc[item.currency] = item.value; return acc;}, {}),
        }),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.clients.clientsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the client successfully created") {
          commit("addClient", data.data);
          return { successfully: true, clientData: data.data };
        }
        return { successfully: false, error: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async updateClient({ commit }, params) {
      const { clientId, email, phone, name, note, discount, useDiscountPolicy, totalPayments } = params;
      const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            shopId: globalMethods.getDataFromLocalStorage("currentKassaID"),
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          email: email,
          phone: phone,
          name: name,
          note: note,
          discount: discount,
          useDiscountPolicy: useDiscountPolicy,
          totalPayments: totalPayments,
        }),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.clients.clientUrl.replace("<ID>", clientId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the client successfully updated") {
          commit("updateClient", data.data);
          return { successfully: true, detail: null };
        } else if (data.error == "validation error") {
          return { successfully: false, error: "validation error", detail: data.detail };
        } else {
          this.$notify(" Упс! Что-то пошло не так…", "red");
          return { successfully: false, error: "unexpected error", detail: "unexpected error" };
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async showClient({ commit }, clientId) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "activate",
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.clients.clientUrl.replace("<ID>", clientId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the client successfully updated") {
          commit("activateClient", clientId);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async showClients({ commit }, clientIds) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "activate",
          clientIds: clientIds,
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.clients.clientsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the clients successfully updated") {
          commit("activateClients", clientIds);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async hideClient({ commit }, clientId) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "archive",
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.clients.clientUrl.replace("<ID>", clientId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the client successfully updated") {
          commit("archiveClient", clientId);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async hideClients({ commit }, clientIds) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "archive",
          clientIds: clientIds,
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.clients.clientsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the clients successfully updated") {
          commit("archiveClients", clientIds);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async deleteClient({ commit }, clientId) {
      const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.clients.clientUrl.replace("<ID>", clientId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the client successfully deleted") {
          commit("deleteClient", clientId);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async deleteClients({ commit }, clientIds) {
      const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          clientIds: clientIds
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.clients.clientsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the clients successfully deleted") {
          commit("deleteClients", clientIds);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewGood({ commit }, params) {
      const { format, type, name, verboseId, price, characteristics, shopData } = params;
      const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
            format: format,
            type: type,
            name: name,
            verboseId: verboseId,
            price: price,
            characteristics: characteristics,
            shopData: shopData,
            amount: {},
        }),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.goods.goodsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the good successfully created") {
          commit("addGood", data.data);
          return { successfully: true, goodData: data.data};
        }
        return { successfully: false, error: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async updateGood({ commit }, params) {
      const { goodId, name, price, format, type, verboseId, shopData, characteristics, isActive, deleteUnlinkedWarehouseGoods, amount } = params;
      const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          name: name,
          price: price,
          format: format,
          type: type,
          verboseId: verboseId,
          shopData: shopData,
          characteristics: characteristics,
          isActive: isActive,
          deleteUnlinkedWarehouseGoods: deleteUnlinkedWarehouseGoods,
          amount: amount,
        }),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.goods.goodUrl.replace("<ID>", goodId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the good successfully updated") {
          commit("updateGood", data.data);
          return { successfully: true, detail: null };
        } else if (data.error == "validation error") {
          return { successfully: false, error: "validation error", detail: data.detail };
        } else {
          this.$notify(" Упс! Что-то пошло не так…", "red");
          return { successfully: false, error: "unexpected error", detail: "unexpected error" };
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async showGood({ commit }, goodId) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "activate",
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.goods.goodUrl.replace("<ID>", goodId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the good successfully updated") {
          commit("activateGood", goodId);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async showGoods({ commit }, goodIds) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "activate",
          goodIds: goodIds,
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.goods.goodsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the goods successfully updated") {
          commit("activateGoods", goodIds);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async hideGood({ commit }, goodId) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "archive",
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.goods.goodUrl.replace("<ID>", goodId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the good successfully updated") {
          commit("archiveGood", goodId);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async hideGoods({ commit }, goodIds) {
      const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          command: "archive",
          goodIds: goodIds,
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.goods.goodsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the goods successfully updated") {
          commit("archiveGoods", goodIds);
          return { successfully: true };
        }
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async deleteGood({ commit }, goodId) {
      const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.goods.goodUrl.replace("<ID>", goodId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the good successfully deleted") {
          commit("deleteGood", goodId);
          return { successfully: true };
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async deleteGoods({ commit }, goodIds) {
      const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          goodIds: goodIds
        }),
        credentials: 'include',
      };

      return fetch(this.state.URLs.api.model.goods.goodsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the goods successfully deleted") {
          commit("deleteGoods", goodIds);
          return { successfully: true };
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async updateKassa({ commit }, params) {
      const { shopId, name, address, cashPayment, card2cardPayment, terminalPayment, accountPayment, defaultCurrency, defaultPayment, reference } = params;
      const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify({
          name: name,
          address: address,
          cashPayment: cashPayment,
          card2cardPayment: card2cardPayment,
          terminalPayment: terminalPayment,
          accountPayment: accountPayment,
          defaultCurrency: defaultCurrency,
          defaultPayment: defaultPayment,
          reference: reference,
        }),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.shops.shopUrl.replace("<ID>", shopId), options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the shop successfully updated") {
          commit("updateKassa", { kassaId: shopId, newData: data.newData });
          return { successfully: true };
        }
        return { successfully: false, error: data.error, detail: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewKassa({ commit }, params) {
      const { name, address, city, timezone, cashPayment, card2cardPayment, terminalPayment, accountPayment, defaultCurrency, defaultPayment, reference, selectedGoods } = params;
      const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
        },
        body: JSON.stringify({
          name: name,
          address: address,
          timezone: timezone,
          cityName: city,
          cashPayment: cashPayment,
          card2cardPayment: card2cardPayment,
          terminalPayment: terminalPayment,
          accountPayment: accountPayment,
          defaultPayment: defaultPayment,
          defaultCurrency: defaultCurrency,
          reference: reference,
          goodIds: selectedGoods,
        }),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.shops.shopsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the shop successfully created") {
          commit("addKassa", data.data);
          return { successfully: true };
        }
        return { successfully: false, error: data.error, detail: data.detail };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchGoods({ commit }) {
      const options = {
          method: "GET",
          headers: {
              "companyId": this.state.companyID,
          },
          credentials: 'include',
      };
      const currentURL = `${this.state.URLs.api.model.goods.goodsUrl}?sort=alphabet`;
      return fetch(currentURL, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        commit("setGoods", data.data);
        return { successfully: true }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchCSVTransactions(context, params) {
      const { shopId, dateFrom, dateTo, os } = params;
      const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?date-from=${dateFrom}&date-to=${dateTo}&os=${os}&shop-id=${shopId}&to=sales`, options)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Продажи в "${this.state.kassas.find(kassa => kassa.id == shopId).name}" за ${dateFrom} - ${dateTo}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return { successfully: true };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchCSVClients(context, os) {
      const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?os=${os}&to=clients`, options)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Выгрузка клиентов.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return { successfully: true }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchCSVClientsTemplate(context, os) {
      const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?os=${os}&to=clients`, options)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Шаблон клиентов.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return {successfully: true};
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchCSVGoods(context, os) {
      const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?os=${os}&to=goods`, options)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Выгрузка позиций каталога.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return {successfully: true};
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchCSVGoodsTemplate(context, os) {
      const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?os=${os}&to=goods`, options)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Шаблон позиций в каталоге.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return {successfully: true};
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async validateCSVGoods(context, file) {
      const formData = new FormData();
      formData.append('csv', file);

      const options = {
        method: "POST",
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        body: formData,
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?command=validate&to=goods`, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the file is valid") {
          return { successfully: true };
        } else {
          if (data.result == "the file is not valid") {
            return { successfully: false, detail: data.detail }
          } else {
            this.$notify(" Упс! Что-то пошло не так…", "red");
            return { successfully: false, detail: null }
          }
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async uploadCSVGoods(context, params) {
      const { file, shopsData } = params;
      const formData = new FormData();
      formData.append('csv', file);
      formData.append("shopsData", JSON.stringify(shopsData));

      const options = {
        method: "POST",
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        body: formData,
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?command=import&to=goods`, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "import successfully completed") {
          return { successfully: true };
        } else {
          if (data.result == "the file is not valid") {
            return { successfully: false, detail: data.detail }
          } else {
            this.$notify(" Упс! Что-то пошло не так…", "red");
            return { successfully: false, detail: null }
          }
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async validateCSVClients(context, file) {
      const formData = new FormData();
      formData.append('csv', file);

      const options = {
        method: "POST",
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        body: formData,
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?command=validate&to=clients`, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the file is valid") {
          return { successfully: true };
        } else {
          if (data.result == "the file is not valid") {
            return { successfully: false, detail: data.detail }
          } else {
            this.$notify(" Упс! Что-то пошло не так…", "red");
            return { successfully: false, detail: null }
          }
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async uploadCSVClients(context, file) {
      const formData = new FormData();
      formData.append('csv', file);

      const options = {
        method: "POST",
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: context.state.companyID,
            timezone: context.state.kassaData.timezone,
        },
        body: formData,
        credentials: 'include',
      };
      return fetch(`${this.state.URLs.api.model.csv.importFromCSVUrl}?command=import&to=clients`, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "import successfully completed") {
          return { successfully: true };
        } else {
          if (data.result == "the file is not valid") {
            return { successfully: false, detail: data.detail }
          } else {
            this.$notify(" Упс! Что-то пошло не так…", "red");
            return { successfully: false, detail: null }
          }
        }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async updateDiscountPolicy({ commit }, newData) {
      const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
            timezone: this.state.kassaData.timezone,
        },
        body: JSON.stringify(newData),
        credentials: 'include',
      };
      return fetch(this.state.URLs.api.model.company.companyDiscountPolicy, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the discount policy successfully updated") {
          commit("updateDiscountPolicy", data.newData);
          return { successfully: true };
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async saveNewCharacteristics({ commit }, newData) {
      const options = {
        method: "POST",
        headers: {
          'X-CSRFToken': getCookie("csrftoken"),
          companyId: this.state.companyID,
        },
        credentials: "include",
        body: JSON.stringify(newData)
      }
      return fetch(this.state.URLs.api.model.company.characteristicsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.result == "the company default goods characteristics successfully updated") {
          commit("updateCharacteristics", data.newData);
          return { successfully: true }
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async fetchSupplyingsAndMovings({ commit }) {
      const options = {
        method: "GET",
        headers: {
          companyId: this.state.companyID,
        },
        credentials: "include",
      }
      return fetch(this.state.URLs.api.model.movingsAndSupplyings.movingsAndSupplyingsUrl, options)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.data) {
          commit("setSupplyingsAndMovings", data.data);
          return { successfully: true }
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewSupplying({ commit }, params) {
      const { description, warehouseId, goods } = params;
      const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
        },
        credentials: "include",
        body: JSON.stringify({
          description: description,
          warehouseId: warehouseId,
          goods: goods,
        })
      }
      return fetch(this.state.URLs.api.model.supplyings.supplyingsUrl, options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.result == "a new supplying successfully created") {
          commit("addSupplying", data.data);
          return { successfully: true }
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    },
    async createNewMoving({ commit }, params) {
      const { description, warehouseFromId, warehouseToId, goods, isWriteOff } = params;
      const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRFToken': getCookie("csrftoken"),
            companyId: this.state.companyID,
        },
        credentials: "include",
        body: JSON.stringify({
          description: description,
          warehouseFromId: warehouseFromId,
          warehouseToId: warehouseToId,
          goods: goods,
          isWriteOff: isWriteOff,
        })
      }
      return fetch(this.state.URLs.api.model.movings.movingsUrl, options)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.result == "a new moving successfully created") {
          commit("addMoving", data.data);
          return { successfully: true }
        }
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false }
      })
      .catch(error => {
        console.error(error.stack);
        this.$notify(" Упс! Что-то пошло не так…", "red");
        return { successfully: false };
      })
    }
  },
  modules: {
  
  }
})
