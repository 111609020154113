<template>
    <div class="">
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-[60]"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-[70]">
            <div id="complete-task-modal-content" class="py-4 px-8 bg-white rounded-lg">
                <div class="flex items-center justify-center mb-6">
                    <svg class="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M477.28-240q21.96 0 37.3-15.34 15.33-15.33 15.33-37.32 0-21.99-15.33-37.18-15.34-15.18-37.3-15.18-21.95 0-37.29 15.18-15.34 15.19-15.34 37.18 0 21.99 15.34 37.32Q455.33-240 477.28-240Zm-38.63-154h78.78q0-32.04 7.62-51.28 7.62-19.24 41.43-51.29 25.28-25.04 40.16-48.91 14.88-23.87 14.88-57.39 0-56.89-40.52-87.25t-96.52-30.36q-57.96 0-94.65 31.2-36.7 31.19-51.18 74.63l71.26 27.67q5.48-18.48 22.38-39.24 16.91-20.76 50.99-20.76 29.13 0 43.94 16.31 14.8 16.3 14.8 35.87 0 18.8-11.64 35.94-11.64 17.14-27.97 30.71-43.04 38.52-53.4 59.36-10.36 20.83-10.36 74.79ZM480-71.87q-84.91 0-159.34-32.12-74.44-32.12-129.5-87.17-55.05-55.06-87.17-129.5Q71.87-395.09 71.87-480t32.12-159.34q32.12-74.44 87.17-129.5 55.06-55.05 129.5-87.17 74.43-32.12 159.34-32.12t159.34 32.12q74.44 32.12 129.5 87.17 55.05 55.06 87.17 129.5 32.12 74.43 32.12 159.34t-32.12 159.34q-32.12 74.44-87.17 129.5-55.06 55.05-129.5 87.17Q564.91-71.87 480-71.87Zm0-91q133.04 0 225.09-92.04 92.04-92.05 92.04-225.09 0-133.04-92.04-225.09-92.05-92.04-225.09-92.04-133.04 0-225.09 92.04-92.04 92.05-92.04 225.09 0 133.04 92.04 225.09 92.05 92.04 225.09 92.04ZM480-480Z"/></svg>
                </div>
                <p class="mb-6 text-lg text-center">Выберите формат обновления остатка товара</p>
                <div class="flex items-center justify-center gap-8">
                    <button @click="moveGoodRemaining" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Переместить на склад</button>
                    <button @click="deleteGoodRemaining" type="button" class="text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Удалить остаток</button>
                    <button @click="closeWindow" type="button" class="text-center bg-red-500 hover:bg-red-600 flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Отмена</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "SelectBehaviorOfRemainingGoodWindow",
        data() {
            return {

            }
        },
        methods: {
            moveGoodRemaining() {
                this.$emit("moveGoodRemaining");
            },
            deleteGoodRemaining() {
                this.$emit("deleteGoodRemaining");
            },
            closeWindow() {
                this.$emit("closeWindow");
            },
        }
    }
</script>