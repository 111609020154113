<template>
    <div ref="goodSelectionBlock" class="relative">  
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>      
        <button @click="openCloseGoodSelection" ref="goodSelectionButton" :id="uniqueId" class="w-full min-h-[2.375rem] text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
            <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ goodParam ? goodParam.name : '' }}</span> 
            <IconSelectArrowDown class="w-2.5 h-2.5 shrink-0"/>
        </button>
        <div v-show="goodSelectionIsOpened" ref="goodSelection" class="z-10 bg-white absolute rounded-lg shadow">
            <div class="px-3 pb-1 pt-3">
                <div class="relative">
                    <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/></svg>
                    </div>
                    <input v-model="searchValue" type="text" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Поиск">
                </div>
            </div>
            <div class="px-3 pt-1 pb-3">
                <button @click="openCatalogWindow" type="button" class="w-full text-center bg-custom-blue hover:bg-custom-blue-hover text-white font-medium rounded-lg text-sm px-5 py-2">Каталог</button>
            </div>
            <div class="max-h-52 px-3 pb-3 overflow-scroll text-sm text-gray-700">
                <div v-if="filteredGoodChoices.length == 0" class="whitespace-nowrap">По вашему запросу ничего не найдено</div>
                <RadioInput v-for="good in filteredGoodChoices" :key="good.id" @change="handleChangeGoodSelection(good)" v-model:radioParam="selectedGood" name="good" :value="good.id">
                    <div class="flex items-center gap-2">
                        <span class="whitespace-nowrap text-xs text-gray-500">{{ good.verboseId }}</span>
                        <span class="whitespace-nowrap">{{ good.name }}</span>
                        <span class="whitespace-nowrap text-xs text-gray-500">{{ good.format != "good" ? "Количество: ∞": currentKassaId ? `Количество: ${good.amount[currentKassaId] || "0"}` : `Количество: ${good.amount[kassaData.id] || "0"}` }}</span>
                        <div class="ml-2 flex flex-col gap-1 text-xs text-gray-500">
                            <span v-for="[name, value] in Object.entries(good.characteristics).filter(([k, v]) => v !== '' && v !== null && v !== undefined)" :key="name" class="whitespace-nowrap">{{ `${name}: ${value}` }}</span>
                        </div>
                    </div>
                </RadioInput>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';

    import IconSelectArrowDown from '@/components/Icon/IconSelectArrowDown.vue';
    import RadioInput from './RadioInput.vue';


    export default {
        name: "GoodSelection",
        components: {
            IconSelectArrowDown,
            RadioInput,
        },
        props: {
            goodParam: {
                type: Object,
                required: false,
            },
            goodChoices: {
                type: Array,
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            currentKassaId: {
                type: [String, Number],
                required: false,
            }
        },
        computed: {
            ...mapState(["kassaData"]),
            filteredGoodChoices() {
                return this.goodChoices.filter(good => good.name.toLowerCase().includes(this.searchValue.toLowerCase()) || good.verboseId.toLowerCase().includes(this.searchValue.toLowerCase()));
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                goodSelectionIsOpened: false,
                searchValue: "",
                catalogWindowIsOpened: false,
                selectedGood: null,
            }
        },
        methods: {
            handleChangeGoodSelection(good) {
                this.$emit("update:goodParam", structuredClone(good));
                this.$emit("change");
                this.goodSelectionIsOpened = false;
            },
            openCatalogWindow() {
                this.$emit("openCatalogWindow");
            },
            openCloseGoodSelection() {
                this.goodSelectionIsOpened = !this.goodSelectionIsOpened;
            },
            autCloseGoodSelectionBlock() {
                const goodSelectionBlock = this.$refs.goodSelectionBlock;
                document.body.addEventListener("click", (event) => {
                    if (goodSelectionBlock.contains(event.target)) {
                        return;
                    }
                    this.goodSelectionIsOpened = false;
                })
            },
        },
        mounted() {
            this.autCloseGoodSelectionBlock();
        }
    }
</script>