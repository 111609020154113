<template>
    <MainHeaderComponent :activePage="activePage"/>
    <GoodsListComponent v-if="mode == 'goods'" v-model:mode="mode"/>
    <CharacteristicSettingsComponent v-else-if="mode == 'characteristics'" v-model:mode="mode"/>
    <SupplyingAndMovingWindow v-else v-model:mode="mode"/>
</template>


<script>
    import MainHeaderComponent from '@/components/laptop/MainHeaderComponent.vue';
    import GoodsListComponent from '@/components/laptop/Good/GoodsListComponent.vue';
    import CharacteristicSettingsComponent from '@/components/laptop/Good/CharacteristicSettingsComponent.vue';
    import SupplyingAndMovingWindow from '@/components/laptop/Good/SupplyingAndMovingWindow.vue';

    export default {
        name: "ClientsAndDiscountsView",
        components: {
            MainHeaderComponent,
            GoodsListComponent,
            CharacteristicSettingsComponent,
            SupplyingAndMovingWindow,
        },
        data() {
            return {
                activePage: "catalog",
                mode: "goods",
            }
        },
        computed: {

        },
        methods: {
            async loadData() {

            },
        },
        async mounted() {
            await this.loadData();
        }
    };
</script>