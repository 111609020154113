<template>
    <CatalogWindowComponent v-if="catalogIsOpened" v-model:catalogWindowIsOpened="catalogIsOpened" v-model:operations="positions" :initialCatalogGoods="onlyGoodsInWarehouses" :currentKassaId="warehouseFrom" forWindow="supplying" />
    <div>
        <div class="fixed bg-gray-500 opacity-50 h-screen w-screen top-0 left-0 right-0 z-20"></div>
        <div class="fixed top-0 left-0 right-0 w-full h-full flex items-center justify-center z-30">
            <div class="relative flex flex-col justify-between bg-white z-30 max-h-[95vh] min-h-[80vh] w-[55vw] rounded-lg py-10 px-16 overflow-y-scroll no-scrollbar">
                <svg @click="closeAddMovingModalWindow" class="w-8 h-8 absolute top-[10px] right-[12px] text-gray-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <div class="flex flex-col items-center justify-between flex-1">
                    <div>
                        <ModalWindowLabel content="Новое перемещение/списание" class="mb-8"/>
                        <div class="grid grid-cols-2 gap-4 mb-4">
                            <KassaSelection @change="handleChangingOfWarehouses" v-model:kassaParam="warehouseFrom" labelContent="Откуда" :withoutKassas="[warehouseTo]" :withMainWarehouse="warehouseTo != 'main'"/>
                            <KassaSelection @change="handleChangingOfWarehouses" v-model:kassaParam="warehouseTo" labelContent="Куда" :withoutKassas="[warehouseFrom]" :withMainWarehouse="warehouseFrom != 'main'" :withWriteOff="true"/>
                        </div>
                        <div>
                            <div class="grid grid-cols-12 gap-2 mb-2">
                                <label class="block col-span-4 text-sm text-gray-500">Товар</label>
                                <label class="block col-span-2 text-sm text-gray-500">Количество</label>
                                <label class="block col-span-3 text-sm text-gray-500">Итог откуда</label>
                                <label class="block col-span-3 text-sm text-gray-500">Итог куда</label>
                            </div>
                            <div v-for="(position, index) in positions" :key="position" class="grid grid-cols-12 gap-2 mb-2">
                                <GoodSelection @change="updateResultAmount(position)" @openCatalogWindow="openCatalogWindow" v-model:goodParam="position.good" :goodChoices="onlyGoodsInWarehouses" :currentKassaId="warehouseFrom" class="col-span-4"/>
                                <AmountInput :ref="`amount-input-${index}`" @input="handleAmountInput(index, position)" v-model:amountParam="position.amount" class="col-span-2"/>
                                <AmountInput v-model:amountParam="position.resultAmountFrom" :readOnly="true" class="col-span-3"/>
                                <AmountInput v-model:amountParam="position.resultAmountTo" :readOnly="true" :class="positions.length > 1 ? 'col-span-2': 'col-span-3'"/>
                                <div v-if="positions.length > 1" class="col-span-1 flex items-center justify-center">
                                    <IconClose @click="deletePosition(index)" class="w-5 h-5 cursor-pointer"/>
                                </div>
                            </div>
                        </div>
                        <ButtonWithPlus @click="addPosition" buttonContent="Добавить товар" class="mb-4"/>
                        <CommentInput v-model:commentParam="note" labelContent="Комментарий" class="w-full mb-8"/>
                    </div>
                    <button @click="createNewMoving" :disabled="buttonIsDisabled" type="button" class="w-full text-center bg-custom-blue hover:bg-custom-blue-hover flex items-center justify-center text-white font-medium rounded-lg text-sm px-5 py-2">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    import ModalWindowLabel from '@/components/Label/ModalWindowLabel.vue';
    import CatalogWindowComponent from '@/components/laptop/Good/CatalogWindowComponent.vue';

    import GoodSelection from '@/components/InputComponents/GoodSelection.vue';
    import AmountInput from '@/components/InputComponents/AmountInput.vue';
    import CommentInput from '@/components/InputComponents/CommentInput.vue';
    import KassaSelection from '@/components/InputComponents/KassaSelection.vue';

    import ButtonWithPlus from '@/components/Button/ButtonWithPlus.vue';

    import IconClose from '@/components/Icon/IconClose.vue';

    export default {
        name: "AddSupplyingModalWindow",
        components: {
            ModalWindowLabel,
            CatalogWindowComponent,
            GoodSelection,
            AmountInput,
            CommentInput,
            KassaSelection,
            ButtonWithPlus,
            IconClose
        },
        props: {
            addMovingModalWindowIsOpened: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                kassaSelectionIsOpened: false,
                catalogIsOpened: false,

                warehouseFrom: null,
                warehouseTo: null,
                positions: [{good: null, amount: null}],
                note: "",

                buttonIsDisabled: false,
            }
        },
        computed: {
            ...mapState(["kassas", "goods"]),
            onlyGoodsInWarehouses() {
                if (this.warehouseFrom == "main" && this.warehouseTo == "write-off") {
                    return this.goods.filter(good => good.format == "good");
                } 
                if (this.warehouseFrom == "main") {
                    return this.goods.filter(good => good.format == "good" && good.shopIds.includes(this.warehouseTo));    
                }
                if (this.warehouseTo == "main") {
                    return this.goods.filter(good => good.format == "good" && good.shopIds.includes(this.warehouseFrom));
                }
                if (this.warehouseTo == "write-off") {
                    return this.goods.filter(good => good.format == "good" && good.shopIds.includes(this.warehouseFrom));
                }
                return this.goods.filter(good => good.format == "good" && good.shopIds.includes(this.warehouseFrom) && good.shopIds.includes(this.warehouseTo));
            },
        },
        methods: {
            validateCreatingData() {
                for (const [index, position] of this.positions.entries()) {
                    const amountInput = this.$refs[`amount-input-${index}`][0].$refs.amountInput;
                    if (position.good == null) {
                        amountInput.setCustomValidity("Вы не можете оставить поле товара пустым");
                        amountInput.reportValidity();
                        return false;
                    }
                    if (!position.amount) {
                        amountInput.setCustomValidity("Количество должно быть больше нуля");
                        amountInput.reportValidity();
                        return false;
                    }
                    const availableAmount = position.good.amount[this.warehouseFrom] || 0;
                    if (position.amount > availableAmount) {
                        amountInput.setCustomValidity("Количество не может быть больше доступного");
                        amountInput.reportValidity();
                        return false;
                    }
                }
                return true;
            },
            async createNewMoving() {
                if (!this.validateCreatingData()) {
                    return
                }

                this.buttonIsDisabled = true;

                let goods = []
                for (const position of this.positions) {
                    goods.push({
                        id: Number(position.good.id),
                        amount: Number(position.amount),
                    })
                }

                let warehouseTo;
                let isWriteOff;
                if (this.warehouseTo == "write-off") {
                    warehouseTo = null;
                    isWriteOff = true;
                } else {
                    warehouseTo = this.warehouseTo;
                    isWriteOff = false;
                }

                const result = await this.$store.dispatch("createNewMoving", {
                    description: this.note,
                    warehouseFromId: this.warehouseFrom,
                    warehouseToId: warehouseTo,
                    goods: goods,
                    isWriteOff: isWriteOff,
                })
                if (result.successfully) {
                    this.$notify("Перемещение успешно выполнено");
                    this.closeAddMovingModalWindow();
                }

                this.buttonIsDisabled = false;
            },
            handleAmountInput(index, position) {
                const amountInput = this.$refs[`amount-input-${index}`][0].$refs.amountInput;
                amountInput.setCustomValidity("");

                if (position.good) {
                    const availableAmount = position.good.amount[this.warehouseFrom] || 0;
                    if (position.amount > availableAmount) {
                        position.amount = position.good.amount[this.warehouseFrom]
                    }   
                }
                this.updateResultAmount(position);
            },
            updateResultAmount(position) {
                if (position.good == null) {
                    return;
                }
                position.resultAmountFrom = Number(position.good.amount[this.warehouseFrom] || 0) - Number(position.amount || 0);
                position.resultAmountTo = Number(position.amount || 0) + Number(position.good.amount[this.warehouseTo] || 0)
            },
            openCatalogWindow() {
                this.catalogIsOpened = true;
            },
            handleChangingOfWarehouses() {
                this.positions = [{good: null, amount: null}];
            },
            deletePosition(index) {
                this.positions.splice(index, 1);
            },
            addPosition() {
                this.positions.push({name: null, amount: null});
            },
            closeAddMovingModalWindow() {
                document.body.classList.remove("overflow-hidden");
                this.$emit("update:addMovingModalWindowIsOpened", false);
            },
        },
        mounted() {

        },
    }
</script>