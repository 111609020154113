<template>
    <div id="notification" class="fixed z-[50]">
        <div @click="hide" ref="notificationBlock" :class="enter ? 'top-4' : 'top-[-100px]'" class="max-w-[35rem] min-w-[20rem] shadow-md shadow-custom-blue border bg-white border-custom-blue rounded duration-1000 z-50 fixed right-[2rem] transition-all px-4">
            <p ref="notificationContent" class="rounded-lg px-4 py-2 text-center dark:text-white dark:bg-gray-600">{{ message }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainNotification",
        data() {
            return {
                enter: false,
                message: "",
                timeoutId: null,
            }
        },
        methods: {
            show(newMessage, color, duration) {
                const notificationContent = this.$refs.notificationContent;
                notificationContent.style.color = color;

                if (this.enter) {
                    this.enter = false;
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;

                    setTimeout(() => {
                        this._openNotification(newMessage, duration);
                    }, 300);
                } else {
                    this._openNotification(newMessage, duration);
                }
            },
            _openNotification(newMessage, duration) {
                this.message = newMessage;
                this.enter = true;
                if (duration != Infinity) {
                    this.timeoutId = setTimeout(this.hide, duration);
                }
            },
            hide() {
                this.enter = false;
            },
        },
    }
</script>