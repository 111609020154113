<template>
    <section class="bg-white py-4 px-8 mb-[10px] flex items-center justify-between">
        <div class="flex items-center">
            <IconLogo class="mr-16"/>
            <div class="flex items-center gap-12">
                <router-link v-if="activePage == 'kassa'" :to="{ name: 'dashboard' }" class="text-black">Кассы</router-link>
                <router-link v-else :to="{ name: kassaUrlName }" class="opacity-40 text-black">{{ kassaUrlName == "dashboard" ? "Кассы" : "Касса" }}</router-link>
                <router-link :to="{ name: 'clients' }" :class="activePage == 'clients' ? '' : 'opacity-40'" class="text-black">Клиенты и скидки</router-link>
                <router-link :to="{ name: 'catalog' }" :class="activePage == 'catalog' ? '' : 'opacity-40'" class="text-black">Каталог и поставки</router-link>
                <router-link :to="{ name: 'staffs'}" :class="activePage == 'staffs' ? '' : 'opacity-40'" class="text-black">Сотрудники</router-link>
            </div>
        </div>
        <div class="flex items-center">
            <div class="flex items-center gap-4 mr-8">
                <svg class="text-gray-500" xmlns="http://www.w3.org/2000/svg" height="2rem" viewBox="0 -960 960 960" width="2rem" fill="currentColor"><path d="M227.92-275.31q56-40.84 119.12-62.77Q410.15-360 480-360q69.85 0 132.96 21.92 63.12 21.93 119.12 62.77 37.3-46 55.61-96.42Q806-422.15 806-480q0-134-96-230t-230-96q-134 0-230 96t-96 230q0 57.85 18.31 108.27 18.31 50.42 55.61 96.42ZM480.09-426q-64.86 0-109.47-44.52Q326-515.05 326-579.91t44.52-109.47Q415.05-734 479.91-734t109.47 44.52Q634-644.95 634-580.09t-44.52 109.47Q544.95-426 480.09-426ZM480-68q-84.15 0-159.53-32.53-75.39-32.54-131.16-88.58-55.77-56.04-88.54-131.39T68-480.5q0-84.65 32.77-159.5t88.54-130.89q55.77-56.04 131.16-88.58Q395.85-892 480.5-892T640-859.23q74.85 32.77 130.65 88.58 55.81 55.8 88.58 130.57Q892-565.32 892-480q0 84.15-32.53 159.53-32.54 75.39-88.58 131.16-56.04 55.77-130.81 88.54Q565.32-68 480-68Zm0-86q49.15 0 98.42-15.92 49.27-15.93 88.27-45.23-39-28.16-87.11-43.5Q531.46-274 480-274t-99.77 15.15q-48.31 15.16-86.92 43.7 39 29.3 88.27 45.23Q430.85-154 480-154Zm0-358q27.85 0 47.92-20.08Q548-552.15 548-580t-20.08-47.92Q507.85-648 480-648t-47.92 20.08Q412-607.85 412-580t20.08 47.92Q452.15-512 480-512Zm0-68Zm0 366Z"/></svg>
                <span>{{  profileData.name }}</span>
            </div>
            <div class="flex items-center">
                <button type="button" class="text-gray-900 bg-white border-2 focus:outline-none hover:bg-gray-100 focus:ring-1 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5">
                    Выйти
                </button>
            </div>
        </div>
    </section>
</template>


<script>
    import { mapState } from 'vuex';

    import IconLogo from '../Icon/IconLogo.vue';

    export default {
        name: "KassaHeaderComponent",
        components: {
            IconLogo,
        },
        props: {
            activePage: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                iconSrc: require('@/assets/icons/logo.svg')
            };
        },
        methods: {

        },
        computed: {
            ...mapState(["profileData"]),
            kassaUrlName() {
                if (this.$globalMethods.getDataFromLocalStorage("currentKassaID")) {
                    return "kassa";
                }
                return "dashboard";
            },
        },
        mounted() {

        },
    }
</script>