<template>
    <div ref="clientSelectionBlock" class="relative">
        <div>
            <div class="flex items-center mb-1">
                <label :for="uniqueId" class="text-sm text-gray-500 mr-2">{{ labelContent }}</label>
                <svg @click="openAddClientModalWindow" class="cursor-pointer w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"></path></svg>
            </div>
            <button @click="openCloseSelection"  :id="uniqueId" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                <span>{{ selectedClient ? selectedClient.name : "-" }}</span>  
                <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>
        </div>

        <div v-show="selectionIsOpened" class="z-10 bg-white absolute rounded-lg shadow w-[17.675rem] dark:bg-gray-700">
            <div class="p-3">
                <div class="relative">
                    <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/></svg>
                    </div>
                    <input v-model="searchValue" type="text" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Поиск по имени, email или телефону">
                </div>
            </div>
            <div @click.stop class="flex items-start flex-col max-h-52 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
                <RadioInput v-for="client in filteredClients" :key="client.id" @change="handleSelecionClient" v-model:radioParam="selectedClientId" name="client" :value="client.id" :labelContent="client.name"/>
                <p v-if="searchValue.length < 5" class="text-center mt-2 w-full">Введите минимум 5 символов</p>
                <p v-else-if="filteredClients.length == 10" class="text-center mt-2 w-full">Если вы не нашли нужного клиента, уточните поисковой запрос</p>
                <p v-else-if="filteredClients.length == 0" class="text-center mt-2 w-full">Не найдено клиентов</p>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';

    import RadioInput from './RadioInput.vue';


    export default {
        name: "ClientSelection",
        components: {
            RadioInput,
        },
        props: {
            clientParam: {
                type: [Number, null],
                required: false,
            },
            labelContent: {
                type: String,
                default: "",
            }
        },
        computed: {
            ...mapState(["clients"]),
            filteredClients() {
                if (this.searchValue.length < 5) {
                    return []
                }
                return this.clients.filter(client => client.name.toLowerCase().includes(this.searchValue.toLowerCase()) || client.phone.replace(/[+\-\s()]/g, "").includes(this.searchValue.replace(/[+\-\s()]/g, "")) || client.email.toLowerCase().includes(this.searchValue.toLowerCase()))
            },
            selectedClient() {
                return this.clients.find(client => client.id == this.clientParam);
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
                selectionIsOpened: false,
                searchValue: "",
                selectedClientId: null,
            }
        },
        methods: {
            handleSelecionClient() {
                this.$emit("update:clientParam", this.selectedClientId);
                this.$emit("change");
                this.selectionIsOpened = false;
                this.searchValue = "";
            },
            openCloseSelection() {
                this.selectionIsOpened = !this.selectionIsOpened;
            },
            openAddClientModalWindow() {
                this.$emit("openAddClientModalWindow");
            },
            autoCloseClientSelectionBlock() {
                const clientSelectionBlock = this.$refs.clientSelectionBlock;
                document.body.addEventListener("click", (event) => {
                    if (clientSelectionBlock.contains(event.target)) {
                        return;
                    }
                    this.selectionIsOpened = false;
                })
            },
        },
        mounted() {
            this.autoCloseClientSelectionBlock();
        },
    }
</script>