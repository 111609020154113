<template>
    <div>
        <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
        <textarea v-model="commentValue" ref="commentInput" :id="uniqueId" :disabled="readOnly" :readonly="readOnly" class="resize-none max-h-[20rem] w-full rounded"></textarea>
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "CommentInput",
        props: {
            commentParam: {
                type: [String, null],
                required: true,
            },
            labelContent: {
                type: String,
                default: "",
            },
            readOnly: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            commentValue: {
                get() {
                    return this.commentParam
                },
                set(value) {
                    if (this.readOnly) {
                        return;
                    }
                    this.$refs.commentInput.value = value;
                    this.$emit("update:commentParam", value);
                    this.$emit("input");
                }
            }
        },
        data() {
            return {
                uniqueId: uuidv4(),
            }
        },
        methods: {
            addAutoResize() {
                const commentInput = this.$refs.commentInput;
                commentInput.style.height = 'auto';
                commentInput.style.height = commentInput.scrollHeight + 'px';

                commentInput.addEventListener('input', () => {
                    commentInput.style.height = 'auto';
                    commentInput.style.height = commentInput.scrollHeight + 'px';
                });
            }
        },
        mounted() {
            this.addAutoResize();
        }
    }
</script>