<template>
    <div ref="paymentMethodSelectionBlock">
        <div>
            <label v-if="labelContent" :for="uniqueId" class="mb-1 block text-sm text-gray-500">{{ labelContent }}</label>
            <button @click="openCloseSelection" :disabled="readOnly" :id="uniqueId" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                <span>{{ labels.transactionMethods[paymentMethodParam] }}</span>
                <svg v-if="!readOnly" class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>
        </div>
        <div v-show="selectionIsOpened" class="z-10 bg-white absolute rounded-lg shadow w-[17.675rem] dark:bg-gray-700">
            <div @click.stop class="flex items-start flex-col max-h-52 p-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
                <RadioInput v-if="kassaData.cashPayment" @change="selectPaymentMethod('cash')" v-model:radioParam="selectedPayment" name="payment-method" value="cash" labelContent="Наличные"/>
                <RadioInput v-if="kassaData.card2cardPayment" @change="selectPaymentMethod('card2card')" v-model:radioParam="selectedPayment" name="payment-method" value="card2card" labelContent="Перевод на карту"/>
                <RadioInput v-if="kassaData.terminalPayment" @change="selectPaymentMethod('terminal')" v-model:radioParam="selectedPayment" name="payment-method" value="terminal" labelContent="Терминал"/>
                <RadioInput v-if="kassaData.accountPayment" @change="selectPaymentMethod('account')" v-model:radioParam="selectedPayment" name="payment-method" value="account" labelContent="На расчетный счет"/>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';

    import RadioInput from './RadioInput.vue';


    export default {
        name: "PaymentMethodSelection",
        components: {
            RadioInput,
        },
        props: {
            paymentMethodParam: {
                type: String,
                required: true,
            },
            labelContent: {
                type: String,
                required: true,
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState(["labels", "kassaData"]),
        },
        data() {
            return {
                uniqueId: uuidv4(),
                selectionIsOpened: false,
                selectedPayment: null,
            }
        },
        methods: {
            selectPaymentMethod(value) {
                if (this.readOnly) {
                    return;
                }
                this.$emit("update:paymentMethodParam", value);
                this.selectionIsOpened = false;
            },
            openCloseSelection() {
                if (this.readOnly) {
                    return;
                }
                this.selectionIsOpened = !this.selectionIsOpened;
            },
            autoClosePaymentMethodSelectionBlock() {
                const paymentMethodSelectionBlock = this.$refs.paymentMethodSelectionBlock;
                document.body.addEventListener("click", (event) => {
                    if (paymentMethodSelectionBlock.contains(event.target)) {
                        return;
                    }
                    this.selectionIsOpened = false;
                })
            },
        },
        mounted() {
            this.autoClosePaymentMethodSelectionBlock();
        }
    }
</script>