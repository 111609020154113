<template>
    <div v-if="dataIsLoaded">
        <MainHeaderComponent :activePage="activePage"/>
        <KassaOperationsComponent v-if="mode == 'transactions'" v-model:mode="mode"/>
        <KassaTasksComponent v-if="mode == 'tasks'" v-model:mode="mode"/>
    </div>
    <div v-else>
        <LoadingComponent />
    </div>
</template>


<script>
    import LoadingComponent from '@/components/LoadingComponent.vue';
    import MainHeaderComponent from '@/components/laptop/MainHeaderComponent.vue';
    import KassaOperationsComponent from '@/components/laptop/Kassa/KassaOperationsComponent.vue';
    import KassaTasksComponent from '@/components/laptop/Kassa/KassaTasksComponent.vue';
import { mapState } from 'vuex';

    export default {
        name: "KassaView",
        components: {
            LoadingComponent,
            MainHeaderComponent,
            KassaOperationsComponent,
            KassaTasksComponent,
        },
        data() {
            return {
                activePage: "kassa",
                mode: "transactions",
                dataIsLoaded: false,
            }
        },
        computed: {
            ...mapState(["kassaData"]),
        },
        methods: {
            async loadData() {
                if (this.$globalMethods.getDataFromLocalStorage("currentKassaID")) {
                    let result;
                    result = await this.$store.dispatch("fetchKassaData");
                    if (result.successfully) { 
                        result = await this.$store.dispatch("fetchCashiers"); 
                    } else {
                        this.$router.push({ name: "dashboard"});
                        return
                    }
                    if (result.successfully) { result = await this.$store.dispatch("fetchKassaGoods"); }
                    if (result.successfully) { this.dataIsLoaded = true; }
                } else {
                    this.$router.push({ name: "dashboard"});
                }
            }
        },
        created() {
            
        },
        async mounted() {
            await this.loadData();
            document.title = this.kassaData.name;
        }
    };
</script>