<template>
    <KassaReferenceWindowComponent v-show="referenceWindowIsOpened" v-model:referenceWindowIsOpened="referenceWindowIsOpened" :reference="kassaData.reference" />
    <TransactionDetailWindowComponent v-if="transactionDetailWindowIsOpened" v-model:transactionDetailWindowIsOpened="transactionDetailWindowIsOpened" :currentTransaction="currentTransaction" />
    <AddSaleWindowComponent v-if="addSaleWindowIsOpened" v-model:addSaleWindowIsOpened="addSaleWindowIsOpened" />
    <AddOperationWindowComponent v-if="addOperationWindowIsOpened" v-model:addOperationWindowIsOpened="addOperationWindowIsOpened" />
    <AddTransferWindowComponent v-if="addTransferWindowIsOpened" v-model:addTransferWindowIsOpened="addTransferWindowIsOpened" />

    <section class="mx-8 px-8 py-6 mb-[5px] bg-white rounded-md flex justify-between items-start">
        <div class="flex flex-col justify-start">
            <h2 class="text-3xl mb-4 flex items-center">
                <span class="mr-2">{{  kassaData.name }}</span>
                <button type="button" ref="openReferenceWindowButton" @click="openReferenceWindow">
                    <svg class="text-gray-500" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 -960 960 960" width="1.5rem" fill="currentColor"><path d="M658.46-165h55.38v-110h-55.38v110Zm27.69-145.54q12.23.39 19.96-8.04 7.73-8.42 7.73-20.65 0-12.23-8.34-19.96t-19.46-7.73q-12.12 0-19.85 8.26-7.73 8.25-7.73 19.26 0 12.02 7.81 20.44 7.81 8.42 19.88 8.42Zm0 252.38q-85.92 0-146.96-61.03-61.04-61.04-61.04-146.96 0-85.93 61.04-146.96 61.04-61.04 146.96-61.04 85.93 0 146.96 61.04 61.04 61.03 61.04 146.96 0 85.92-61.04 146.96-61.03 61.03-146.96 61.03ZM476-598h208L476-806l208 208-208-208v208ZM208.31-68q-41.83 0-70.07-28.24Q110-124.48 110-166.31v-627.38q0-41.83 28.24-70.07Q166.48-892 208.31-892H518l252 251v91.62q-21.46-7.62-43.23-10.04-21.77-2.43-43.62-2.43-56.38 0-106.5 20.47-50.11 20.46-89.57 57.38H275v80h149.31q-11.08 20.85-18.12 43.04-7.04 22.19-9.88 46.96H275v80h116.99q5.32 46.92 24.51 89.04 19.19 42.11 51.11 76.96h-259.3Z"/></svg>
                </button>  
            </h2>
            <div class="flex items-center">
                <button type="button" class="rounded-md bg-indigo-100 px-4 py-2 shadow mr-8">Операции</button>
                <button @click="goToTasksPage" type="button" class="rounded-md flex items-center">
                    <span>Задачи</span>
                    <div v-if="amountActiveTasks > 0" class="ml-2 flex items-center text-center justify-center rounded-full block min-w-6 w-auto h-auto text-xs text-white bg-red-700 p-1">
                        <span>{{ amountActiveTasks }}</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="flex items-stretch">
            <button @click="openAddSaleWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 mr-4">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1.5rem" width="1.5rem" viewBox="0 -960 960 960" fill="currentColor"><path d="M438-274h86v-162h162v-86H524v-164h-86v164H274v86h164v162Zm42.07 206q-85.48 0-160.69-32.44t-130.84-88.05q-55.63-55.61-88.09-130.79Q68-394.46 68-479.93q0-85.74 32.5-161.17 32.5-75.43 88.21-131.23 55.71-55.8 130.79-87.74Q394.57-892 479.93-892q85.73 0 161.15 31.92 75.43 31.92 131.24 87.71 55.81 55.79 87.75 131.21Q892-565.74 892-479.98q0 85.75-31.92 160.62t-87.7 130.6q-55.78 55.73-131.18 88.25Q565.8-68 480.07-68Z"/></svg>
                Продажа
            </button>
            <button @click="openAddOperationWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2 mr-4">Доход / Расход</button>
            <button @click="openAddTransferWindow" type="button" class="bg-custom-blue hover:bg-custom-blue-hover flex items-center text-white font-medium rounded-lg text-sm px-5 py-2">Перевод</button>
        </div>
    </section>

    <section :class="filterIsOpened ? 'mb-[5px]' : 'mb-[10px]'" class="mx-8 px-8 py-4 bg-white rounded-md flex justify-between items-center relative">
        <div class="flex align-items text-black min-w-48">
            <button @click="goToPreviousPeriod" type="button" :class="previousPeriodIsActive ? 'hover:bg-[#F5F7F9]' : 'cursor-default'" class="border border-gray-500 flex items-center justify-center py-1 px-2 rounded-l">
                <svg :class="previousPeriodIsActive ? '' : 'opacity-30'" xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="-200 -960 960 960" fill="currentColor"><path d="M410-69.85-1.15-481 410-892.15l73.77 73.77L146.38-481l337.39 337.38L410-69.85Z"/></svg>
            </button>
            <button @click="openClosePeriodSelection" id="open-period-selection-button" type="button" class="border border-gray-500 flex items-center justify-center py-1 px-4 hover:bg-[#F5F7F9]">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 -960 960 960" fill="currentColor"><path d="M206.31-70q-41.03 0-69.67-28.64T108-168.31v-547.38q0-41.03 28.64-69.67T206.31-814h23.38v-80.61h87.54V-814h327.08v-80.61h86V-814h23.38q41.03 0 69.67 28.64T852-715.69v547.38q0 41.03-28.64 69.67T753.69-70H206.31Zm0-86h547.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-399.38H194v399.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM194-653.69h572v-62q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H206.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v62Zm0 0V-728v74.31Zm286 262.77q-16.69 0-29.04-12.35-12.34-12.34-12.34-29.04 0-16.69 12.34-29.04 12.35-12.34 29.04-12.34 16.69 0 29.04 12.34 12.34 12.35 12.34 29.04 0 16.7-12.34 29.04-12.35 12.35-29.04 12.35Zm-160 0q-16.69 0-29.04-12.35-12.34-12.34-12.34-29.04 0-16.69 12.34-29.04 12.35-12.34 29.04-12.34 16.69 0 29.04 12.34 12.34 12.35 12.34 29.04 0 16.7-12.34 29.04-12.35 12.35-29.04 12.35Zm320 0q-16.69 0-29.04-12.35-12.34-12.34-12.34-29.04 0-16.69 12.34-29.04 12.35-12.34 29.04-12.34 16.69 0 29.04 12.34 12.34 12.35 12.34 29.04 0 16.7-12.34 29.04-12.35 12.35-29.04 12.35ZM480-234q-16.69 0-29.04-12.35-12.34-12.34-12.34-29.03 0-16.7 12.34-29.04 12.35-12.35 29.04-12.35 16.69 0 29.04 12.35 12.34 12.34 12.34 29.04 0 16.69-12.34 29.03Q496.69-234 480-234Zm-160 0q-16.69 0-29.04-12.35-12.34-12.34-12.34-29.03 0-16.7 12.34-29.04 12.35-12.35 29.04-12.35 16.69 0 29.04 12.35 12.34 12.34 12.34 29.04 0 16.69-12.34 29.03Q336.69-234 320-234Zm320 0q-16.69 0-29.04-12.35-12.34-12.34-12.34-29.03 0-16.7 12.34-29.04 12.35-12.35 29.04-12.35 16.69 0 29.04 12.35 12.34 12.34 12.34 29.04 0 16.69-12.34 29.03Q656.69-234 640-234Z"/></svg>
                <span ref="currentPeriodLabel" data-type-period="day">Сегодня</span>
            </button>
            <button @click="goToNextPeriod" type="button" :class="nextPeriodIsActive ? 'hover:bg-[#F5F7F9]' : 'cursor-default'" class="border border-gray-500 flex items-center justify-center py-1 px-2 rounded-r">
                <svg :class="nextPeriodIsActive ? '' : 'opacity-30'" xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 -960 960 960" fill="currentColor"><path d="m317.62-69.85-73.77-73.77L581.23-481 243.85-818.38l73.77-73.77L728.77-481 317.62-69.85Z"/></svg>
            </button>
        </div>

        <div v-if="periodSelectionIsShowed" id="period-selection" class="absolute z-50 top-16 w-48">
            <div class="bg-white border-[#E6ECF4] border rounded-md shadow-md">
                <button @click="setDateToToday" class="py-2 px-[14px] hover:bg-[#F5F7F9] cursor-pointer w-full text-left">Сегодня</button>
                <button @click="setDateToCurrentMonth" class="py-2 px-[14px] hover:bg-[#F5F7F9] cursor-pointer w-full text-left">{{ $globalMethods.getCurrentMonthAndYear() }}</button>
                <button @click="setDateToCurrentYear" class="py-2 px-[14px] hover:bg-[#F5F7F9] cursor-pointer w-full text-left">{{ $globalMethods.getCurrentYear() }}</button>
                <button @click="openCloseDatepickerToSelectCustomPeriod" id="open-datepicker-to-select-custom-period-button" class="py-2 px-[14px] hover:bg-[#F5F7F9] cursor-pointer w-full text-left border-t border-[#E6ECF4]">Указать период</button>
            </div>
        </div>

        <div :class="datepickerIsShowed ? '' : 'hidden'" id="datepicker-to-select-custom-period-container" class="absolute z-50 top-16 border border-gray-500 bg-white rounded w-[18.5rem] p-2 flex flex-col gap-2">
            <div class="flex justify-between items-start">
                <input ref="dateFrom" type="date" class="border-b-2 border-t-0 border-r-0 border-l-0 border-[#B3CBE9] w-[8.5rem]"/>
                <input ref="dateTo" type="date" class="border-b-2 border-t-0 border-r-0 border-l-0 border-[#B3CBE9] w-[8.5rem]"/>
            </div>
            <button @click="setDateToCustomPeriod" class="bg-custom-blue hover:bg-custom-blue-hover text-center text-white font-medium rounded-lg text-sm px-5 py-2">Готово</button>
        </div>

        <div class="flex items-center">
            <div class="flex mr-4">
                <div class="relative w-full">
                    <input @click="searchTransactionByIdOrNote" v-model="valueToSearchingTransaction" type="search" id="search-dropdown" class="min-w-[25rem] block py-1 px-4 w-full z-20 text-gray-900 rounded-lg border border-custom-blue focus:ring-blue-500 focus:border-blue-500" placeholder="Поиск по ID и комментарию" required />
                    <button type="submit" class="absolute top-0 end-0 py-1 px-4 font-medium h-full text-white rounded-e-lg border border-custom-blue bg-custom-blue hover:bg-custom-blue-hover">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                        <span class="sr-only">Search</span>
                    </button>
                </div>
            </div>
            <button @click="openCloseFilter" type="button" :class="filterIsOpened ? 'bg-custom-blue-dimmed': 'hover:bg-gray-100'" class="relative border border-custom-blue text-sm text-custom-blue py-2 rounded-md flex items-center px-4 mr-4">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1rem" width="1rem" viewBox="0 -960 960 960" fill="currentColor"><path d="M397.15-221v-86h166.31v86H397.15ZM231.16-437v-86h496.3v86h-496.3ZM108-653v-86h744v86H108Z"/></svg>
                <svg v-if="selectedGoodToFilter || selectedClientToFilter || selectedCashierToFilter || selectedTransactionMethodToFilter || totalToFilter" class="text-red-600 absolute top-[-0.5rem] right-[-0.5rem] w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-200q-117 0-198.5-81.5T200-480q0-117 81.5-198.5T480-760q117 0 198.5 81.5T760-480q0 117-81.5 198.5T480-200Z"/></svg>
                Фильтр
            </button>
            <select v-model="currentBalanceCurrency" name="current-balance" id="current-balance" class="rounded-md p-2 text-sm hover:bg-gray-100 cursor-pointer">
                <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
            </select>
        </div>
    </section>

    <section v-if="filterIsOpened" class="mx-8 mb-[10px] px-8 py-6 grid grid-cols-6 gap-8 bg-white rounded-md">
        <div ref="filterSelectionTransactionNameContainer" class="relative">
            <div @click="openCloseSelectionFilterTransactionName">
                <label for="filter-good-name" class="mb-1 block text-sm text-gray-500">Название</label>
                <button id="filter-good-name" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                    <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ selectedGoodVerboseNameToFilter }}</span> 
                    <svg class="w-2.5 h-2.5 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </button>
            </div>

            <div v-show="filterTransactionNameIsOpened" class="z-10 bg-white absolute rounded-lg shadow max-w-[60vw] w-max dark:bg-gray-700">
                <div class="p-3">
                    <label for="search-good-to-filter" class="sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/></svg>
                        </div>
                        <input v-model="searchedGoodToFilter" id="search-good-to-filter" type="text" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Поиск">
                    </div>
                </div>
                <div @click.stop class="max-h-52 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                    <RadioInput @change="selectGoodToFilter(null, 'Все')" v-model:radioParam="selectedGoodToFilter" name="good-to-filter" :value="null" labelContent="Все"/>
                    <RadioInput v-for="good in searchedKassaGoods" :key="good.id" @change="selectGoodToFilter(good.id, good.name)" v-model:radioParam="selectedGoodToFilter" name="good-to-filter" :value="good.id">
                        <span class="text-xs text-gray-500 mr-2">{{ good.verboseId }}</span>
                        <span class="mr-2">{{ good.name }}</span>
                        <span v-if="['service', 'operation'].includes(good.format)" class="text-xs text-gray-500 mr-2">Количество: ∞</span>
                        <span v-else class="text-xs text-gray-500 mr-2">Количество: {{ good.amount[kassaData.id] || "0" }}</span>
                        <span v-if="!$globalMethods.isEmptyObject(good.characteristics)" class="text-xs text-gray-500">{{ $globalMethods.formatCharacteristics(good.characteristics) }}</span>
                    </RadioInput>
                </div>
            </div>
        </div>
        <div ref="filterSelectionClientContainer" class="relative">
            <div @click="openCloseSelectionFilterClient">
                <label for="filter-client" class="mb-1 block text-sm text-gray-500">Клиент</label>
                <button id="filter-client" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                    <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ selectedClientVerboseNameToFilter }}</span>
                    <svg class="w-2.5 h-2.5 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </button>
            </div>

            <div v-show="filterClientIsOpened" class="z-10 bg-white absolute rounded-lg shadow w-60 dark:bg-gray-700">
                <div class="p-3">
                    <label for="search-client-to-filter" class="sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/></svg>
                        </div>
                        <input v-model="searchedClientToFilter" id="search-client-to-filter" type="text" class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Поиск">
                    </div>
                </div>
                <div @click.stop class="flex items-start flex-col max-h-52 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
                    <RadioInput @change="selectClientToFilter(null, 'Все')" v-model:radioParam="selectedClientToFilter" name="filter-client" :value="null" labelContent="Все"/>
                    <RadioInput v-for="client in searchedClients" :key="client.id" @change="selectClientToFilter(client.id, client.name)" v-model:radioParam="selectedClientToFilter" name="filter-client" :value="client.id" :labelContent="client.name"/>
                    <p v-if="searchedClientToFilter.length < 5" class="text-center mt-2 w-full">Введите минимум 5 символов</p>
                    <p v-else-if="searchedClients.length == 10" class="text-center mt-2 w-full">Если вы не нашли нужного клиента, уточните поисковой запрос</p>
                </div>
            </div>
        </div>
        <div ref="filterSelectionCashierContainer" class="relative">
            <div @click="openCloseSelectionFilterCashier">
                <label for="filter-cashier" class="mb-1 block text-sm text-gray-500">Кассир</label>
                <button id="filter-cashier" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                    <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ selectedCashierVerboseNameToFilter }}</span>
                    <svg class="w-2.5 h-2.5 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </button>
            </div>

            <div v-show="filterCashierIsOpened" class="z-10 bg-white absolute rounded-lg shadow w-60 dark:bg-gray-700">
                <div @click.stop class="max-h-52 p-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                    <RadioInput @change="selectCashierToFilter(null, 'Все')" v-model:radioParam="selectedCashierToFilter" name="client-to-filter" :value="null" labelContent="Все"/>
                    <RadioInput v-for="cashier in searchedCashiers" :key="cashier.id" @change="selectCashierToFilter(cashier.id, cashier.name)" v-model:radioParam="selectedCashierToFilter" name="client-to-filter" :value="cashier.id" :labelContent="cashier.name"/>
                </div>
            </div>
        </div>
        <div ref="filterSelectionTransactionMethodContainer" class="relative">
            <div @click="openCloseSelectionFilterTransactionMethod">
                <label for="filter-transaction-method-to-filter" class="mb-1 block text-sm text-gray-500">Метод</label>
                <button id="filter-transaction-method-to-filter" class="w-full text-sm text-gray-900 rounded border border-custom-blue focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center py-2 px-4" type="button">
                    <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%] shrink-0">{{ selectedTransactionMethodToFilterVerboseName }}</span>
                    <svg class="w-2.5 h-2.5 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </button>
            </div>

            <div v-show="filterTransactionMethodIsOpened" class="z-10 bg-white absolute rounded-lg shadow w-60 dark:bg-gray-700">
                <div @click.stop class="max-h-52 p-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                    <RadioInput @change="selectTransactionMethodToFilter(null, 'Все')" v-model:radioParam="selectedTransactionMethodToFilter" name="transaction-method-to-filter" :value="null" labelContent="Все"/>
                    <RadioInput v-if="kassaData.cashPayment" @change="selectTransactionMethodToFilter('cash', 'Наличные')" v-model:radioParam="selectedTransactionMethodToFilter" name="transaction-method-to-filter" value="cash" labelContent="Наличные"/>
                    <RadioInput v-if="kassaData.card2cardPayment" @change="selectTransactionMethodToFilter('card2card', 'Перевод на карту')" v-model:radioParam="selectedTransactionMethodToFilter" name="transaction-method-to-filter" value="card2card" labelContent="Перевод на карту"/>
                    <RadioInput v-if="kassaData.terminalPayment" @change="selectTransactionMethodToFilter('terminal', 'Терминал')" v-model:radioParam="selectedTransactionMethodToFilter" name="transaction-method-to-filter" value="terminal" labelContent="Терминал"/>
                    <RadioInput v-if="kassaData.accountPayment" @change="selectTransactionMethodToFilter('account', 'На расчетный счет')" v-model:radioParam="selectedTransactionMethodToFilter" name="transaction-method-to-filter" value="account" labelContent="На расчетный счет"/>
                </div>
            </div>
        </div>
        <PriceInput v-model:priceParam="totalToFilter" labelContent="Сумма" class="flex-1"/>
        <div class="ml-4 flex items-end justify-end">
            <button @click="resetFilter" type="button" class="border border-custom-blue text-sm text-custom-blue py-2 hover:bg-gray-100 rounded-md flex items-center px-4">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 -960 960 960" width="1rem" fill="currentColor"><path d="M338.63-280 480-421.37 621.37-280 680-338.63 538.63-480 680-621.37 621.37-680 480-538.63 338.63-680 280-621.37 421.37-480 280-338.63 338.63-280ZM480-71.87q-84.91 0-159.34-32.12-74.44-32.12-129.5-87.17-55.05-55.06-87.17-129.5Q71.87-395.09 71.87-480t32.12-159.34q32.12-74.44 87.17-129.5 55.06-55.05 129.5-87.17 74.43-32.12 159.34-32.12t159.34 32.12q74.44 32.12 129.5 87.17 55.05 55.06 87.17 129.5 32.12 74.43 32.12 159.34t-32.12 159.34q-32.12 74.44-87.17 129.5-55.06 55.05-129.5 87.17Q564.91-71.87 480-71.87Zm0-91q133.04 0 225.09-92.04 92.04-92.05 92.04-225.09 0-133.04-92.04-225.09-92.05-92.04-225.09-92.04-133.04 0-225.09 92.04-92.04 92.05-92.04 225.09 0 133.04 92.04 225.09 92.05 92.04 225.09 92.04ZM480-480Z"/></svg>
                Сбросить
            </button>
        </div>
    </section>

    <section class="mx-8 mb-[10px] flex items-center justify-between max-w-full overflow-x-scroll gap-[5px]" style="scrollbar-width: none;">
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="text-gray-500 mr-2" xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 -960 960 960" width="1rem" fill="currentColor"><path d="M481.38-518.62q155.93 0 263.97-48.92 108.03-48.92 108.03-118.07 0-69.16-108.03-118.08-108.04-48.92-263.97-48.92-155.92 0-264.65 48.92Q108-754.77 108-685.61q0 69.15 108.73 118.07 108.73 48.92 264.65 48.92Zm-.38 93.77q68.85 0 128.77-10.61 59.92-10.62 108.58-29.7 48.65-19.07 82.99-46.03 34.35-26.96 52.04-60.66v112.24q-17.69 33.69-52.04 60.65Q767-372 718.35-352.92q-48.66 19.07-108.58 29.69-59.92 10.61-128.77 10.61-68.85 0-129.27-10.61-60.42-10.62-108.38-29.69-47.96-19.08-82.81-46.54T108-459.61v-112.24q17.69 32.7 52.54 60.16 34.85 27.46 82.81 46.53 47.96 19.08 108.38 29.7 60.42 10.61 129.27 10.61Zm0 206q68.85 0 128.77-10.61 59.92-10.62 108.58-29.7 48.65-19.07 82.99-46.03 34.35-26.97 52.04-60.66V-254q-17.69 33.69-52.04 60.65-34.34 26.96-82.99 46.04-48.66 19.08-108.58 29.69Q549.85-107 481-107q-68.85 0-129.27-10.62-60.42-10.61-108.38-29.69-47.96-19.08-82.81-46.54T108-254v-111.85q17.69 32.69 52.54 60.16 34.85 27.46 82.81 46.53 47.96 19.08 108.38 29.7 60.42 10.61 129.27 10.61Z"></path></svg>
                    Сумма в кассе
                </span>
                <span v-if="balancesByCurrency[currentBalanceCurrency].value == '-'" class="text-lg">-</span>
                <span v-else class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].value) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="mr-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 -960 960 960" width="1rem" fill="currentColor"><path d="M129.15-231.08 69-293.23l306.08-306.08 164 164 207.54-206.77H644v-86h248v250h-86v-103.84L539.08-313l-164-164-245.93 245.92Z"></path></svg>
                    Итог доходов
                </span>
                <span class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].income) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="text-gray-500 mr-2" xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 -960 960 960" width="1rem" fill="currentColor"><path d="M644-231.08v-86h102.62L539.08-523.85l-164 164L69-665.92l60.15-62.16 245.93 245.93 164-164L806-377.23v-103.85h86v250H644Z"></path></svg>
                    Итог расходов
                </span>
                <span class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].spending) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="0.75rem" viewBox="0 -960 960 960" width="0.75rem" fill="currentColor"><path d="M480.07-190q-119.61 0-204.84-85.16Q190-360.31 190-479.93q0-119.61 85.16-204.84Q360.31-770 479.93-770q119.61 0 204.84 85.16Q770-599.69 770-480.07q0 119.61-85.16 204.84Q599.69-190 480.07-190Z"></path></svg>
                    Наличные
                </span>
                <span class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].cash) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="0.75rem" viewBox="0 -960 960 960" width="0.75rem" fill="currentColor"><path d="M480.07-190q-119.61 0-204.84-85.16Q190-360.31 190-479.93q0-119.61 85.16-204.84Q360.31-770 479.93-770q119.61 0 204.84 85.16Q770-599.69 770-480.07q0 119.61-85.16 204.84Q599.69-190 480.07-190Z"></path></svg>
                    Карта
                </span>
                <span class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].card2card) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="0.75rem" viewBox="0 -960 960 960" width="0.75rem" fill="currentColor"><path d="M480.07-190q-119.61 0-204.84-85.16Q190-360.31 190-479.93q0-119.61 85.16-204.84Q360.31-770 479.93-770q119.61 0 204.84 85.16Q770-599.69 770-480.07q0 119.61-85.16 204.84Q599.69-190 480.07-190Z"></path></svg>
                    Терминал
                </span>
                <span class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].terminal) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
        <div v-if="balancesByCurrency[currentBalanceCurrency]" class="flex items-center justify-start bg-white rounded-md p-4 w-full shadow">
            <div class="flex flex-col items-start">
                <span class="text-sm mb-2 text-gray-500 flex items-center w-full">
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="0.75rem" viewBox="0 -960 960 960" width="0.75rem" fill="currentColor"><path d="M480.07-190q-119.61 0-204.84-85.16Q190-360.31 190-479.93q0-119.61 85.16-204.84Q360.31-770 479.93-770q119.61 0 204.84 85.16Q770-599.69 770-480.07q0 119.61-85.16 204.84Q599.69-190 480.07-190Z"></path></svg>
                    Расчетный счет
                </span>
                <span class="text-lg">
                    {{ this.$globalMethods.formatNumberWithSpaces(balancesByCurrency[currentBalanceCurrency].account) }}
                    <span>{{ labels.currencies[currentBalanceCurrency] }}</span>
                </span>
            </div>
        </div>
    </section>

    <section class="mx-8 mb-8">
        <div class="relative overflow-x-auto shadow-md sm:rounded-md">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-sm text-gray-700 bg-white">
                    <tr class="border-b border-gray-300">
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">ID</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Время</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Наименование</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Кассир</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span style="font-weight: 400">Скидка</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Сумма</span>
                        </th>
                        <th scope="col" class="px-4 py-3 w-0">
                            <span class="font-normal">Метод</span>
                        </th>
                        <th scope="col" class="px-4 py-3">
                            <span class="font-normal">Клиент</span>
                        </th>
                        <th scope="col" class="px-4 py-3">
                            <span class="font-normal">Комментарий</span>
                        </th>
                        <th scope="col" class="max-w-8 p-3">
                            <span class="sr-only">Дополнительно</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="text-sm">
                    <tr v-if="transactions.length == 0">
                        <td colspan="10" class="text-center text-lg p-2">За выбранный период нет транзакций</td>
                    </tr>
                    <tr v-else-if="filteredTransactions.length == 0">
                        <td colspan="10" class="text-center text-lg p-2">За выбранный период с указанными параметрами фильтра нет транзакций</td>
                    </tr>
                    <template v-else v-for="(transactions, date) in groupedTransactions" :key="date">
                        <tr class="bg-gray-200 border-b">
                            <td class="px-4 py-2" v-if="this.$globalMethods.isToday(date)" colspan="10">Сегодня</td>
                            <td class="px-4 py-2" v-else-if="this.$globalMethods.isYesterday(date)" colspan="10">Вчера</td>
                            <td class="px-4 py-2" v-else colspan="10" v-html="this.$globalMethods.formatDate('ru', date)"></td>
                        </tr>

                        <tr v-for="transaction in transactions" :key="transaction.id" @click="openTransactionDetailWindow(transaction)" :class="transaction.isActive ? 'bg-white hover:bg-gray-50 text-black' : 'bg-[#f2d0db] hover:bg-red-50 text-black/60'" class="font-normal border-b">
                            <th scope="row" class="px-4 py-2 font-normal whitespace-nowrap">
                                {{ transaction.verboseId }}
                            </th>
                            <td class="px-4 py-2 whitespace-nowrap">{{  this.$globalMethods.getTime(transaction.verboseDateOfCreated) }}</td>
                            <td class="px-4 py-2 whitespace-nowrap" v-if="transaction.operations">{{ getNameByOperations(transaction.operations) }}</td>
                            <td class="px-4 py-2 whitespace-nowrap" v-else-if="transaction.total < 0">{{ `Перевод в кассу "${transaction.transferTo.name}"` }}</td>
                            <td class="px-4 py-2 whitespace-nowrap" v-else>{{ `Перевод из кассы "${transaction.transferTo.name}"` }}</td>
                            <td class="px-4 py-2 whitespace-nowrap">{{ transaction.cashier.name }}</td>
                            <td class="px-4 py-2 whitespace-nowrap">{{ transaction.discount ? `${Number(transaction.discount)}%`: "-" }}</td>
                            <td v-if="transaction.total >= 0" class="px-4 py-2 whitespace-nowrap">
                                +{{ this.$globalMethods.formatNumberWithSpaces(transaction.total) }}
                                <span>{{ labels.currencies[transaction.currency] }}</span>
                            </td>
                            <td v-else class="text-red-500 px-4 py-2 whitespace-nowrap">
                                {{ this.$globalMethods.formatNumberWithSpaces(transaction.total) }}
                                <span>{{ labels.currencies[transaction.currency] }}</span>
                            </td>
                            <td v-if="transaction.payment == 'cash'" class="px-4 py-2 whitespace-nowrap">Наличные</td>
                            <td v-if="transaction.payment == 'card2card'" class="px-4 py-2 whitespace-nowrap">Перевод на карту</td>
                            <td v-if="transaction.payment == 'terminal'" class="px-4 py-2 whitespace-nowrap">Терминал</td>
                            <td v-if="transaction.payment == 'account'" class="px-4 py-2 whitespace-nowrap">На расчетный счет</td>
                            <td class="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[10rem]">{{ transaction.client ? transaction.client.name : "-" }}</td>
                            <td class="px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[15rem]">{{ transaction.note || "-" }}</td>
                            <td class="p-3 w-[3%]">
                                <div v-if="transaction.is_operation" class="relative">
                                    <svg :data-tooltip-target="`tooltip-operation-${transaction.id}`" class="text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="m836.69-18.39-53.77-53.77q-10.84 6.16-23 8.16t-24.15 2H235q-51.92 0-88.96-37.04Q109-136.08 109-188v-134h122v-302.08L28.23-826.85 89-887.61 897.46-79.15l-60.77 60.76ZM851-239.62l-86-86V-760H330.62L231-859.61v-22.7l61.39 53.7 62.15-53.7 62.15 53.7 62.16-53.7 62.15 53.7 62.15-53.7 62.16 53.7 62.15-53.7 62.15 53.7 61.39-53.7v642.69ZM317-322h216.08L317-538.08V-322Zm178.85-272-86-86h185.76v86h-99.76Zm94 94-42-42h47.76v42h-5.76Zm85.84 49.38q-19.69 0-34.04-14.34-14.34-14.35-14.34-34.04 0-19.69 14.34-34.04 14.35-14.34 34.04-14.34 19.69 0 34.04 14.34 14.35 14.35 14.35 34.04 0 19.69-14.35 34.04-14.35 14.34-34.04 14.34Zm0-138q-19.69 0-34.04-14.34-14.34-14.35-14.34-34.04 0-19.69 14.34-34.04 14.35-14.34 34.04-14.34 19.69 0 34.04 14.34 14.35 14.35 14.35 34.04 0 19.69-14.35 34.04-14.35 14.34-34.04 14.34Z"/></svg>
                                </div>
                                <div v-else class="relative">
                                    <svg :data-tooltip-target="`tooltip-sale-${transaction.id}`" class="text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M235-62q-51.92 0-88.96-37.04Q109-136.08 109-187.82V-322h122v-560.31l61.39 53.7 62.15-53.7 62.15 53.7 62.16-53.7 62.15 53.7 62.15-53.7 62.16 53.7 62.15-53.7 62.15 53.7 61.39-53.7V-188q0 51.92-37.04 88.96Q776.92-62 725-62H235Zm490-86q17 0 28.5-11.5T765-188v-572H317v438h368v134q0 17 11.5 28.5T725-148ZM356.69-594v-86h238.92v86H356.69Zm0 138v-86h238.92v86H356.69Zm319-132.62q-19.69 0-34.04-14.34-14.34-14.35-14.34-34.04 0-19.69 14.34-34.04 14.35-14.34 34.04-14.34 19.69 0 34.04 14.34 14.35 14.35 14.35 34.04 0 19.69-14.35 34.04-14.35 14.34-34.04 14.34Zm0 138q-19.69 0-34.04-14.34-14.34-14.35-14.34-34.04 0-19.69 14.34-34.04 14.35-14.34 34.04-14.34 19.69 0 34.04 14.34 14.35 14.35 14.35 34.04 0 19.69-14.35 34.04-14.35 14.34-34.04 14.34Z"/></svg>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </section>
</template>


<script>
    import Decimal from 'decimal.js'; 
    import { mapState } from 'vuex';

    import KassaReferenceWindowComponent from '@/components/laptop/Kassa/KassaReferenceWindowComponent.vue';
    import TransactionDetailWindowComponent from '@/components/laptop/Kassa/TransactionDetailWindowComponent.vue';
    import AddSaleWindowComponent from '@/components/laptop/Kassa/AddSaleWindowComponent.vue';
    import AddOperationWindowComponent from "@/components/laptop/Kassa/AddOperationWindowComponent.vue"
    import AddTransferWindowComponent from '@/components/laptop/Kassa/AddTransferWindowComponent.vue';

    import PriceInput from '@/components/InputComponents/PriceInput.vue';
    import RadioInput from '@/components/InputComponents/RadioInput.vue';


    export default {
        name: "KassaOperationsComponent",
        components: {
            KassaReferenceWindowComponent,
            TransactionDetailWindowComponent,
            AddSaleWindowComponent,
            AddOperationWindowComponent,
            AddTransferWindowComponent,

            PriceInput,
            RadioInput,
        },
        props: {
            mode: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                referenceWindowIsOpened: false,
                transactionDetailWindowIsOpened: false,
                addSaleWindowIsOpened: false,
                addOperationWindowIsOpened: false,
                addTransferWindowIsOpened: false,

                dateFrom: this.$globalMethods.getCurrentDate(),
                dateTo: this.$globalMethods.getCurrentDate(),
                periodSelectionIsShowed: false,
                datepickerIsShowed: false,
                previousPeriodIsActive: true,
                nextPeriodIsActive: false,

                currentTransaction: null,
                currentBalanceCurrency: null,

                filterIsOpened: false,
                filterTransactionNameIsOpened: false,
                filterClientIsOpened: false,
                filterCashierIsOpened: false,
                filterTransactionMethodIsOpened: false,
                
                searchedGoodToFilter: "",
                selectedGoodToFilter: null,
                selectedGoodVerboseNameToFilter: "Все",

                searchedClientToFilter: "",
                selectedClientToFilter: null,
                selectedClientVerboseNameToFilter: "Все",

                searchedCashierToFilter: "",
                selectedCashierToFilter: null,
                selectedCashierVerboseNameToFilter: "Все",

                selectedTransactionMethodToFilter: null,
                selectedTransactionMethodToFilterVerboseName: "Все",

                totalToFilter: null,
                valueToSearchingTransaction: null,
            }
        },
        computed: {
            ...mapState(["transactions", "currencies", "tasks", "kassaData", "kassaGoods", "clients", "cashiers", "labels"]),
            filteredTransactions() {
                let result = this.transactions;
                if (this.selectedGoodToFilter) {
                    result = result.filter(transaction => !transaction.isTransfer && transaction.operations.some(operation => operation.good.id === Number(this.selectedGoodToFilter)));
                }
                if (this.selectedClientToFilter) {
                    result = result.filter(transaction => !transaction.isTransfer && transaction.client.id === Number(this.selectedClientToFilter));
                }
                if (this.selectedCashierToFilter) {
                    result = result.filter(transaction => transaction.cashier.id === Number(this.selectedCashierToFilter));
                }  
                if (this.selectedTransactionMethodToFilter) {
                    result = result.filter(transaction => transaction.payment == this.selectedTransactionMethodToFilter);
                }
                if (this.totalToFilter) {
                    result = result.filter(transaction => Number(transaction.total) == Number(this.totalToFilter));
                }
                if (this.valueToSearchingTransaction) {
                    if (this.$globalMethods.isNumeric(this.valueToSearchingTransaction)) {
                        result = result.filter(transaction => String(transaction.verboseId).toLowerCase() === this.valueToSearchingTransaction.toLowerCase());
                    } else {
                        result = result.filter(transaction => String(transaction.note).toLowerCase().includes(this.valueToSearchingTransaction.toLowerCase()));
                    }
                }
                return result;
            },
            filteredBalances() {
                let result = [];
                for (const currency of this.currencies) {
                    let cashInCashRegisterValue;
                    if (this.dateFrom == this.dateTo && this.dateFrom == this.$globalMethods.getCurrentDate()) {
                        if (this.kassaData.cashInCashRegister) {
                            cashInCashRegisterValue = this.kassaData.cashInCashRegister[currency] || 0;
                        } else {
                            cashInCashRegisterValue = 0;
                        }
                    } else {
                        cashInCashRegisterValue = "-";
                    }
                    result.push(
                        {
                            currency: currency,
                            value: cashInCashRegisterValue,
                        }
                    )
                }

                for (const balance of result) {
                    balance.income = new Decimal(0);
                    balance.spending = new Decimal(0);
                    balance.cash = new Decimal(0);
                    balance.card2card = new Decimal(0);
                    balance.terminal = new Decimal(0);
                    balance.account = new Decimal(0);
                    balance.cashPercent = new Decimal(0);
                    balance.terminalPercent = new Decimal(0);
                    balance.accountPercent = new Decimal(0);
                    balance.card2cardPercent = new Decimal(0);
                }

                for (const transaction of this.filteredTransactions) {
                    if (!transaction.isActive) {
                        continue;
                    }
                    const currentBalance = result.find(balance => balance.currency === transaction.currency);
                    const total = new Decimal(transaction.total);
                    currentBalance[transaction.payment] = currentBalance[transaction.payment].plus(total);
                    if (total.isPositive()) {
                        currentBalance.income = currentBalance.income.plus(total);
                    } else {
                        currentBalance.spending = currentBalance.spending.plus(total);
                    }
                }

                for (const balance of result) {
                    balance.income = balance.income.toString();
                    balance.spending = balance.spending.negated().toString();
                    balance.cash = balance.cash.toString();
                    balance.card2card = balance.card2card.toString();
                    balance.terminal = balance.terminal.toString();
                    balance.account = balance.account.toString();
                    balance.cashPercent = balance.cashPercent.toString();
                    balance.terminalPercent = balance.terminalPercent.toString();
                    balance.accountPercent = balance.accountPercent.toString();
                    balance.card2cardPercent = balance.card2cardPercent.toString();
                }
                return result;
            },
            groupedTransactions() {
                const result = this.filteredTransactions.reduce((acc, operation) => {
                    const date = operation.verboseDateOfCreated.split(' ')[0];
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(operation);
                    return acc;
                }, {});
                return result;
            },
            balancesByCurrency() {
                const result = this.filteredBalances.reduce((acc, item) => {
                    acc[item.currency] = item;
                    return acc;
                }, {});
                return result;
            },
            amountActiveTasks() {
                return this.tasks.filter(element => { return !element.isCompleted }).length;
            },
            searchedKassaGoods() {
                const result = this.kassaGoods.filter(good => good.name.toLowerCase().includes(this.searchedGoodToFilter.toLowerCase()) || good.verboseId.toLowerCase().includes(this.searchedGoodToFilter.toLowerCase()))
                return result;
            },
            searchedCashiers() {
                const result = this.cashiers.filter(cashier => cashier.name.toLowerCase().includes(this.searchedCashierToFilter.toLowerCase()));
                return result; 
            },
            searchedClients() {
                let result = [];
                const searchedValue = this.searchedClientToFilter.toLowerCase();
                if (searchedValue.length >= 5) {
                    result = this.clients.filter(client => client.name.toLowerCase().includes(searchedValue) || client.phone.replace(/\D/g, '').includes(searchedValue)).slice(0, 10);
                }
                return result;
            },
        },
        methods: {
            openReferenceWindow() {
                document.body.classList.add("overflow-hidden");
                this.referenceWindowIsOpened = true;
            },
            openAddTransferWindow() {
                document.body.classList.add("overflow-hidden");
                this.addTransferWindowIsOpened = true;
            },
            openAddSaleWindow() {
                this.addSaleWindowIsOpened = true;
            },
            openAddOperationWindow() {
                document.body.classList.add("overflow-hidden");
                this.addOperationWindowIsOpened = true;
            },
            openTransactionDetailWindow(transaction) {
                document.body.classList.add("overflow-hidden");
                this.currentTransaction = transaction;
                if (this.currentTransaction.discount && Number(this.currentTransaction.discount) != 0) {
                    this.currentTransaction.totalWithoutDiscount = this.caluclateTotalByOperations(this.currentTransaction.operations);
                }
                this.transactionDetailWindowIsOpened = true;
            },
            caluclateTotalByOperations(operations) {
                let result = new Decimal(0);
                for (const operation of operations) {
                    const operationTotalPrice = new Decimal(operation.priceWithoutDiscount).mul(operation.amount);
                    result = result.plus(operationTotalPrice);
                }
                return Number(result.toString());
            },
            openCloseFilter() {
                this.filterIsOpened = !this.filterIsOpened;
            },
            resetFilter() {
                this.selectedGoodToFilter = null;
                this.selectedGoodVerboseNameToFilter = "Все";
                this.selectedClientToFilter = null;
                this.selectedClientVerboseNameToFilter = "Все";
                this.selectedCashierToFilter = null;
                this.selectedCashierVerboseNameToFilter = "Все";
                this.selectedTransactionMethodToFilter = null;
                this.selectedTransactionMethodToFilterVerboseName = "Все";
                this.totalToFilter = null;
            },
            selectTransactionMethodToFilter(transactionMethodValue, transactionMethodName) {
                this.selectedTransactionMethodToFilter = transactionMethodValue;
                this.selectedTransactionMethodToFilterVerboseName = transactionMethodName;
                this.filterTransactionMethodIsOpened = false;
            },
            selectCashierToFilter(cashierID, cashierName) {
                this.selectedCashierToFilter = cashierID;
                this.selectedCashierVerboseNameToFilter = cashierName;
                this.filterCashierIsOpened = false;
                this.searchedCashierToFilter = "";
            },
            selectGoodToFilter(goodID, goodName) {
                this.selectedGoodToFilter = goodID;
                this.selectedGoodVerboseNameToFilter = goodName;
                this.filterTransactionNameIsOpened = false;
                this.searchedGoodToFilter = "";
            },
            selectClientToFilter(clientId, clientName) {
                this.selectedClientToFilter = clientId;
                this.selectedClientVerboseNameToFilter = clientName;
                this.filterClientIsOpened = false;
                this.searchedClientToFilter = "";
            },
            addAutoCloseSelectionTransactionMethod() {
                document.body.addEventListener("click", (event) => {
                    const filterSelectionTransactionMethodContainer = this.$refs.filterSelectionTransactionMethodContainer;
                    if (filterSelectionTransactionMethodContainer && filterSelectionTransactionMethodContainer.contains(event.target)) {
                        return
                    }
                    this.filterTransactionMethodIsOpened = false;
                })
            },
            addAutoCloseSelectionCashier() {
                document.body.addEventListener("click", (event) => {
                    const filterSelectionCashierContainer = this.$refs.filterSelectionCashierContainer;
                    if (filterSelectionCashierContainer && filterSelectionCashierContainer.contains(event.target)) {
                        return
                    }
                    this.filterCashierIsOpened = false;
                })
            },
            addAutoCloseSelectionClient() {
                document.body.addEventListener("click", (event) => {
                    const filterSelectionClientContainer = this.$refs.filterSelectionClientContainer;
                    if (filterSelectionClientContainer && filterSelectionClientContainer.contains(event.target)) {
                        return
                    }
                    this.filterClientIsOpened = false;
                })
            },
            addAutoCloseSelectionTransactionName() {
                document.body.addEventListener("click", (event) => {
                    const filterSelectionTransactionNameContainer = this.$refs.filterSelectionTransactionNameContainer;
                    if (filterSelectionTransactionNameContainer && filterSelectionTransactionNameContainer.contains(event.target)) {
                        return
                    }
                    this.filterTransactionNameIsOpened = false;
                })
            },
            openCloseSelectionFilterTransactionMethod() {
                this.filterTransactionMethodIsOpened = !this.filterTransactionMethodIsOpened
            },
            openCloseSelectionFilterCashier() {
                this.filterCashierIsOpened = !this.filterCashierIsOpened;
            },
            openCloseSelectionFilterClient() {
                this.filterClientIsOpened = !this.filterClientIsOpened;
            },
            openCloseSelectionFilterTransactionName() {
                this.filterTransactionNameIsOpened = !this.filterTransactionNameIsOpened;
            },
            goToPreviousPeriod() {
                if (!this.previousPeriodIsActive) {
                    return
                }
                const dateFrom = this.$refs.dateFrom;
                const dateTo = this.$refs.dateTo;

                const currentPeriodLabel = this.$refs.currentPeriodLabel;
                const currentPeriodType = currentPeriodLabel.getAttribute("data-type-period");

                if (currentPeriodType == "day") {
                    dateFrom.value = this.$globalMethods.getPreviousDay(dateFrom.value);
                    dateTo.value = this.$globalMethods.getPreviousDay(dateTo.value);
                    this.setDateToCustomPeriod();
                } else if (currentPeriodType == "month") {
                    dateFrom.value = this.$globalMethods.getFirstDayOfPreviousMonth(dateFrom.value);
                    dateTo.value = this.$globalMethods.getLastDayOfPreviousMonth(dateTo.value);
                    this.setDateToCustomPeriod();
                } else if (currentPeriodType == "year") {
                    dateFrom.value = this.$globalMethods.getFirstDayOfPreviousYear(dateFrom.value);
                    dateTo.value = this.$globalMethods.getLastDayOfPreviousYear(dateTo.value);
                    this.setDateToCustomPeriod();
                }
            },
            goToNextPeriod() {
                if (!this.nextPeriodIsActive) {
                    return
                }
                const dateFrom = this.$refs.dateFrom;
                const dateTo = this.$refs.dateTo;

                const currentPeriodLabel = this.$refs.currentPeriodLabel;
                const currentPeriodType = currentPeriodLabel.getAttribute("data-type-period");

                if (currentPeriodType == "day") {
                    dateFrom.value = this.$globalMethods.getNextsDay(dateFrom.value);
                    dateTo.value = this.$globalMethods.getNextsDay(dateTo.value);
                    this.setDateToCustomPeriod();
                } else if (currentPeriodType == "month") {
                    dateFrom.value = this.$globalMethods.getFirstDayOfNextMonth(dateFrom.value);
                    dateTo.value = this.$globalMethods.getLastDayOfNextMonth(dateTo.value);
                    this.setDateToCustomPeriod();
                } else if (currentPeriodType == "year") {
                    dateFrom.value = this.$globalMethods.getFirstDayOfNextYear(dateFrom.value);
                    dateTo.value = this.$globalMethods.getLastDayOfNextYear(dateTo.value);
                    this.setDateToCustomPeriod();
                }
            },
            setDateToToday() {
                const dateFrom = this.$refs.dateFrom;
                const dateTo = this.$refs.dateTo;
                const today = this.$globalMethods.getCurrentDate();
                dateFrom.value = today;
                dateTo.value = today;
                this.setDateToCustomPeriod();
            },
            setDateToCurrentMonth() {
                const dateFrom = this.$refs.dateFrom;
                const dateTo = this.$refs.dateTo;
                const { start, end } = this.$globalMethods.getCurrentMonthPeriod();
                dateFrom.value = start;
                dateTo.value = end;
                this.setDateToCustomPeriod();
            },
            setDateToCurrentYear() {
                const dateFrom = this.$refs.dateFrom;
                const dateTo = this.$refs.dateTo;
                const { start, end } = this.$globalMethods.getCurrentYearPeriod();
                dateFrom.value = start;
                dateTo.value = end;
                this.setDateToCustomPeriod();
            },
            setDateToCustomPeriod() {
                const currentPeriodLabel = this.$refs.currentPeriodLabel;
                const dateFrom = this.$refs.dateFrom;
                const dateTo = this.$refs.dateTo;

                const dateFromValue = dateFrom.value;
                const dateToValue = dateTo.value;
                
                const isPeriodWithinOneYear = this.$globalMethods.isDifferenceWithinOneYear(new Date(dateFromValue), new Date(dateToValue));
                if (!isPeriodWithinOneYear) {
                    dateFrom.setCustomValidity('Период не может превышать 1 год');
                    dateFrom.reportValidity();
                    return 
                }

                if (dateFromValue && dateToValue && (new Date(dateFromValue) > new Date(dateToValue))) {
                    dateFrom.setCustomValidity('Дата начала не может превышать дату конца');
                    dateFrom.reportValidity();
                    return
                }

                if (!dateFromValue && !dateToValue) {
                    dateFrom.setCustomValidity('Укажите период');
                    dateFrom.reportValidity();
                    return
                }

                let dateStart;
                if (!dateFromValue) {
                    dateStart = "";
                } else if (this.$globalMethods.isToday(dateFromValue)) {
                    dateStart = "Сегодня";
                } else if (this.$globalMethods.isYesterday(dateFromValue)) {
                    dateStart = "Вчера";
                } else {
                    dateStart = this.$globalMethods.formatDate("ru", dateFrom.value);
                }

                let dateEnd;
                if (!dateToValue) {
                    dateEnd = "";
                } else if (this.$globalMethods.isToday(dateToValue)) {
                    dateEnd = "Сегодня";
                } else if (this.$globalMethods.isYesterday(dateToValue)) {
                    dateEnd = "Вчера";
                } else {
                    dateEnd = this.$globalMethods.formatDate("ru", dateTo.value);
                }

                if (dateStart == dateEnd) {
                    if (dateStart == "Сегодня") {
                        currentPeriodLabel.textContent = "Сегодня";
                        this.previousPeriodIsActive = true;
                        this.nextPeriodIsActive = false;
                        currentPeriodLabel.setAttribute("data-type-period", "day");
                    } else if (dateStart == "Вчера") {
                        currentPeriodLabel.textContent = "Вчера";
                        this.previousPeriodIsActive = true;
                        this.nextPeriodIsActive = true;
                        currentPeriodLabel.setAttribute("data-type-period", "day");
                    } else {
                        currentPeriodLabel.textContent = `${dateStart}`;
                        this.previousPeriodIsActive = true;
                        this.nextPeriodIsActive = true;
                        currentPeriodLabel.setAttribute("data-type-period", "day");
                    }
                } else {
                    if (dateStart.length != 0 && dateEnd.length == 0 && dateStart == "Сегодня") {
                        currentPeriodLabel.textContent = `Сегодня`;
                        this.previousPeriodIsActive = true;
                        this.nextPeriodIsActive = false;
                        currentPeriodLabel.setAttribute("data-type-period", "day");
                    } else if (dateStart.length != 0 && dateEnd.length == 0) {
                        currentPeriodLabel.textContent = `${dateStart} - Сегодня`;
                        this.previousPeriodIsActive = false;
                        this.nextPeriodIsActive = false;
                        currentPeriodLabel.setAttribute("data-type-period", "");
                    } else {
                        if (this.$globalMethods.isStartAndEndOfMonth(dateFromValue, dateToValue)) {
                            const selectedMonth = this.$globalMethods.getMonthAndYear(dateFromValue);
                            const currentMonth = this.$globalMethods.getCurrentMonthAndYear();
                            currentPeriodLabel.textContent = `${selectedMonth}`;

                            if (selectedMonth == currentMonth) {
                                this.previousPeriodIsActive = true;
                                this.nextPeriodIsActive = false;
                            } else {
                                this.previousPeriodIsActive = true;
                                this.nextPeriodIsActive = true;
                            }
                            currentPeriodLabel.setAttribute("data-type-period", "month");
                        } else if (this.$globalMethods.isStartAndEndOfYear(dateFromValue, dateToValue)) {
                            const selectedYear = this.$globalMethods.getYear(dateFromValue);
                            const currentYear = this.$globalMethods.getCurrentYear();
                            currentPeriodLabel.textContent = `${selectedYear}`;

                            if (selectedYear == currentYear) {
                                this.previousPeriodIsActive = true;
                                this.nextPeriodIsActive = false;
                            } else {
                                this.previousPeriodIsActive = true;
                                this.nextPeriodIsActive = true;
                            }
                            currentPeriodLabel.setAttribute("data-type-period", "year");
                        } else {
                            currentPeriodLabel.textContent = `${dateStart} - ${dateEnd}`;
                            this.previousPeriodIsActive = false;
                            this.nextPeriodIsActive = false;
                            currentPeriodLabel.setAttribute("data-type-period", "");
                        }
                    }
                }   

                this.periodSelectionIsShowed = false;
                this.datepickerIsShowed = false;
                this.dateFrom = dateFromValue;
                this.dateTo = dateToValue;
                this.getCurrentTransactions();
                this.getCurrentTasks();
            },
            openClosePeriodSelection() {
                this.periodSelectionIsShowed = !this.periodSelectionIsShowed;
            },
            openCloseDatepickerToSelectCustomPeriod() {
                this.datepickerIsShowed = !this.datepickerIsShowed;
                this.periodSelectionIsShowed = false;
            },
            addAutoClosePeriodSelection() {
                document.body.addEventListener("click", (event) => {
                    if (event.target.closest("#period-selection")) {
                        return;
                    }
                    if (event.target.closest("#datepicker-to-select-custom-period-container")) {
                        return;
                    }
                    if (event.target.closest("#open-period-selection-button")) {
                        return;
                    }
                    this.periodSelectionIsShowed = false;
                })
            },
            addAutoCloseDatepickerToSelectCustomPeriod() {
                document.body.addEventListener("click", (event) => {
                    if (event.target.closest("#period-selection")) {
                        return;
                    }
                    if (event.target.closest("#datepicker-to-select-custom-period-container")) {
                        return;
                    }
                    if (event.target.closest("#open-datepicker-to-select-custom-period-button")) {
                        return;
                    }
                    if (event.target.closest(".datepicker")) {
                        return;
                    }
                    this.datepickerIsShowed = false;
                })
            },
            goToTasksPage() {
                this.$emit('update:mode', 'tasks');
            },
            getNameByOperations(operations) {
                if (operations.length == 1) {
                    return operations[0].good.name;
                }
                return `${operations[0].good.name}, ...`;
            },
            async getCurrentTransactions() {
                await this.$store.dispatch("fetchKassaTransactions", { dateFrom: this.dateFrom, dateTo: this.dateTo })
            },
            async getCurrentTasks() {
                await this.$store.dispatch("fetchTasks", { dateFrom: this.dateFrom, dateTo: this.dateTo });
            },
        },
        created() {

        },
        mounted() {
            this.currentBalanceCurrency = this.kassaData.defaultCurrency;
            this.getCurrentTransactions();
            this.getCurrentTasks();
            this.addAutoClosePeriodSelection();
            this.addAutoCloseDatepickerToSelectCustomPeriod();
            this.setDateToToday();
            this.addAutoCloseSelectionTransactionMethod();
            this.addAutoCloseSelectionCashier();
            this.addAutoCloseSelectionClient();
            this.addAutoCloseSelectionTransactionName();
        },
    }
</script>